import React, {useEffect} from 'react';
import apiLink from '../../config.js'
import {useLocation, Navigate} from 'react-router-dom'

import ButtonVidéos from '../MEP/buttonVidéo.js';
import Entete from '../MEP/entete'
import OADMenu from '../MEP/oadMenu'
import GraphRAC from './Graphiques/resteAcharge'
import ButtonQuestions from '../MEP/button question.js';
import RobustesseScale from './Graphiques/robustess_scale';
import MascotteRouge from '../../assets/Mascotte/Effrayé-YC.png'
import MascotteOrange from '../../assets/Mascotte/Peur-YC.png'
import MascotteJaune from '../../assets/Mascotte/Neutre-YC.png'
import MascotteVertPale from '../../assets/Mascotte/Content-YC.png'
import MascotteVertFlash from '../../assets/Mascotte/Très-Heureux-YC.png'

function Resistance() {
  const { state } = useLocation();
  const { idExploit } = state;
  const [goToHome, setGoToHome] = React.useState(false)
  const [goToNewSimul, setGoToNewSimul] = React.useState(false)


  const [Mount, setMount] = React.useState(true);
  const [data, setData]= React.useState(0);
  const [dataScale25, seTDataScale25] = React.useState([{value:0.56, name:'Robustesse MRC 25%'}]);
  const [dataScale20, seTDataScale20] = React.useState([{value:0.51, name:'Robustesse MRC 20%'}]);

  const SansAss = 0

  const [PBTot, setPBTot]= React.useState(0);
  const [PertesCourant, setPCO]= React.useState(0);
  const [PertesExceptionnel, setPE]= React.useState(0);
  const [PertesCatastrophe, setPCA]= React.useState(0); 

  const [Ass20_7, setAss20_7]= React.useState(0);
  const [Ass25_7, setAss25_7]= React.useState(0);
  const [AssFS_7, setAssFS_7]= React.useState(0);

  const [Ass20_20, setAss20_20]= React.useState(0);
  const [Ass25_20, setAss25_20]= React.useState(0);
  const [AssFS_20, setAssFS_20]= React.useState(0);

  const [Ass20_50, setAss20_50]= React.useState(0);
  const [Ass25_50, setAss25_50]= React.useState(0);
  const [AssFS_50, setAssFS_50]= React.useState(0);

  useEffect ( () => {
    fetch(apiLink + 'page/exploitation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        setPBTot(response.PBTotal)
        setPBTot(prev => {
          setEC(Math.round(prev*0.03/ 1000) * 1000)
          setEPro(Math.round(prev*0.02/ 1000) * 1000)
          setCE(Math.round(prev*0.05/ 1000) * 1000)
          return prev;
        })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
    })  

    fetch(apiLink + 'page/resistance/'  + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        setPCO(Math.round(response[0].val.indemnisationmoyenne_7/10)*10)
        setPCO(prev => {
            return prev;
        })
        setPE(Math.round(response[0].val.indemnisationmoyenne_20/10)*10)
        setPE(prev => {
            return prev;
        })
        setPCA(Math.round(response[0].val.indemnisationmoyenne_50/10)*10)
        setPCA(prev => {
            return prev;
        })
        setAss20_7(Math.round(response[20].val.indemnisationmoyenne_7/10)*10)
        setAss20_7(prev => {
            return prev;
        })
        setAss20_20(Math.round(response[20].val.indemnisationmoyenne_20/10)*10)
        setAss20_20(prev => {
            return prev;
        })
        setAss20_50(Math.round(response[20].val.indemnisationmoyenne_50/10)*10)
        setAss20_50(prev => {
            return prev;
        })
        setAss25_7(Math.round(response[25].val.indemnisationmoyenne_7/10)*10)
        setAss25_7(prev => {
            return prev;
        })
        setAss25_20(Math.round(response[25].val.indemnisationmoyenne_20/10)*10)
        setAss25_20(prev => {
            return prev;
        })
        setAss25_50(Math.round(response[25].val.indemnisationmoyenne_50/10)*10)
        setAss25_50(prev => {
            return prev;
        })
        setAssFS_7(Math.round(response[50].val.indemnisationmoyenne_7/10)*10)
        setAssFS_7(prev => {
            return prev;
        })
        setAssFS_20(Math.round(response[50].val.indemnisationmoyenne_20/10)*10)
        setAssFS_20(prev => {
            return prev;
        })
        setAssFS_50(Math.round(response[50].val.indemnisationmoyenne_50/10)*10)
        setAssFS_50(prev => {
            return prev;
        })
        setP20(Math.round(response[20].val.primeAssurance/10)*10)
        setP20(prev => {
            return prev;
        })
        setP25(Math.round(response[25].val.primeAssurance/10)*10)
        setP25(prev => {
            return prev;
        })
        setSFSN(Math.round(response[25].val.subventionFSN/10)*10)
        setSFSN(prev => {
            return prev;
        })
        setSAss20(Math.round(response[20].val.subventionAssureur/10)*10)
        setSAss20(prev => {
            return prev;
        })
        setSAss25(Math.round(response[25].val.subventionTotale/10)*10)
        setSAss25(prev => {
            return prev;
        })
        setSTot20(Math.round(response[20].val.subventionTotale/10)*10)
        setSTot20(prev => {
            return prev;
        })
        setSTot25(Math.round(response[25].val.subventionTotale/10)*10)
        setSTot25(prev => {
            return prev;
        })
        setData(response)
        setData(prev => {
            return prev;
        })
        seTDataScale25(response['robustesse']['25'])
        seTDataScale25(prev => {
            return prev;
        })
        seTDataScale20(response['robustesse']['20'])
        seTDataScale20(prev => {
            return prev;
        })
      } else {
                localStorage.clear()
        setGoToHome(true)
      }
    }) 
  },  [idExploit])

  const [Pertes, setPertes]= React.useState(PertesCourant)
  const Pertes2 = PertesCourant

  const [Ass, setAss]= React.useState(0)
  const [EC, setEC]= React.useState(0)
  const [EPro, setEPro]= React.useState(0)
  const [EPri, setEPri]= React.useState(1000)
  const [CE, setCE]= React.useState(0)

  const [BCou, setBCou]= React.useState('Checked')
  const [BExc, setBExc]= React.useState('UnChecked')
  const [BCat, setBCat]= React.useState('UnChecked')

  const [BSA, setBSA]= React.useState('Checked')
  const [BA20, setBA20]= React.useState('UnChecked')
  const [BA25, setBA25]= React.useState('UnChecked')
  const [BFS, setBFS]= React.useState('UnChecked')
  const [Prime25, setP25]= React.useState(7392)
  const [Prime20, setP20]= React.useState(10080)
  const [SubvFSN, setSFSN]= React.useState(1050)
  const [SubvAss25, setSAss25]= React.useState(5400)
  const [SubvAss20, setSAss20]= React.useState(5600)
  const [SubvTot25, setSTot25]= React.useState(6450)
  const [SubvTot20, setSTot20]= React.useState(6650)
  

  const maximum = PertesCatastrophe

  function CalcRC(){
    var reste = 0
    if (Mount) {
      var table =[Pertes2, EC, EPro, CE, EPri, Ass]
    }
    else {
      var table =[Pertes, EC, EPro, CE, EPri, Ass]
    }
    
    table[0]=table[0]-Ass

    if (table[0]>EC) {
      table[0] = table[0] - EC
    }
    else {
      reste = Math.abs(Pertes-EC)
      table[0] = table[0] - (EC-reste)
      table[1] = EC-reste
    }
    if (table[0] > EPro) {
      table[0] = table[0] - EPro
    }
    else {
      reste = Math.abs(table[0]-EPro)
      table[0] = table[0] - (EPro-reste)
      table[2] = EPro-reste
    }
    if (table[0] > CE) {
      table[0] = table[0] - CE 
    }
    else {
      reste = Math.abs(table[0]-CE)
      table[0] = table[0] - (CE-reste)
      table[3] = CE-reste
    }
    if (table[0] > EPri) {
      table[0] = table[0] - EPri
    }
    else {
      reste = Math.abs(table[0]-EPri)
      table[0] = table[0] - (EPri-reste)
      table[4] = EPri-reste
    }
    return(table)
  }

  const dataGraph = CalcRC()
  const  RC = dataGraph[0]

  const dataPertes= [{x:0,y:0},{x:1,y:Mount?Pertes2: Pertes}]
  const dataRC=[{x:0,y:dataGraph[0]},{x:1,y:0}]
  const dataCE=[{x:0,y:dataGraph[3]},{x:1,y:0}]
  const dataEPri=[{x:0,y:dataGraph[4]},{x:1,y:0}]
  const dataEPro=[{x:0,y:dataGraph[2]},{x:1,y:0}]
  const dataEC=[{x:0,y:dataGraph[1]},{x:1,y:0}]
  const dataAss=[{x:0,y:dataGraph[5]},{x:1,y:0}] 

  function handlePressCourant() {
    setAss(SansAss)
    setBSA('Checked')

    setPertes(PertesCourant)
    setBCou('Checked')

    setBA20('UnChecked')
    setBA25('UnChecked')
    setBFS('UnChecked')

    setBExc('UnChecked')
    setBCat('UnChecked')

  }

  function handlePressExceptionnel() {
    setAss(SansAss)
    setBSA('Checked')

    setPertes(PertesExceptionnel)
    setBExc('Checked')

    setBA20('UnChecked')
    setBA25('UnChecked')
    setBFS('UnChecked')

    setBCou('UnChecked')
    setBCat('UnChecked')

    setMount(false)
  }

  function handlePressCatastrophique() {
    setAss(SansAss)
    setBSA('Checked')

    setPertes(PertesCatastrophe)
    setBCat('Checked')

    setBA20('UnChecked')
    setBA25('UnChecked')
    setBFS('UnChecked')

    setBCou('UnChecked')
    setBExc('UnChecked')
    setMount(false)
  }

  function handlePressSA() {
    setAss(SansAss)
    setBSA('Checked')
    setBA20('UnChecked')
    setBA25('UnChecked')
    setBFS('UnChecked')
  }

  function handlePressFS() {
    if((Pertes===PertesCourant && Mount===false) || (Pertes2===PertesCourant && Mount===true)){
      setAss(AssFS_7)
    }
    else if((Pertes===PertesExceptionnel && Mount===false) || (Pertes2===PertesExceptionnel && Mount===true)){
      setAss(AssFS_20)
    }
    else{
      setAss(AssFS_50)
    }
    setBSA('UnChecked')
    setBA20('UnChecked')
    setBA25('UnChecked')
    setBFS('Checked')
  }

  function handlePressAss25() {
    if((Pertes===PertesCourant && Mount===false) || (Pertes2===PertesCourant && Mount===true)){
      setAss(Ass25_7)
    }
    else if((Pertes===PertesExceptionnel && Mount===false) || (Pertes2===PertesExceptionnel && Mount===true)){
      setAss(Ass25_20)
    }
    else{
      setAss(Ass25_50)
    }
    setBSA('UnChecked')
    setBA20('UnChecked')
    setBA25('Checked')
    setBFS('UnChecked')
  }

  function handlePressAss20() {
    if((Pertes===PertesCourant && Mount===false) || (Pertes2===PertesCourant && Mount===true)){
      setAss(Ass20_7)
    }
    else if((Pertes===PertesExceptionnel && Mount===false) || (Pertes2===PertesExceptionnel && Mount===true)){
      setAss(Ass20_20)
    }
    else{
      setAss(Ass20_50)
    }
    setBSA('UnChecked')
    setBA20('Checked')
    setBA25('UnChecked')
    setBFS('UnChecked')
  }

  function handlePressEC() {
    const testEC=EC-1000
    if(testEC<=0) {
      setEC(0)
    }
    else {
      setEC(testEC)
    }
  }

  function handlePressEPro() {
    const testEPro=EPro-1000
    if(testEPro<=0) {
      setEPro(0)
    }
    else {
      setEPro(testEPro)
    }
  }

  function handlePressEPri() {
    const testEPri=EPri-1000
    if(testEPri<=0) {
      setEPri(0)
    }
    else {
      setEPri(testEPri)
    }
  }
  function handlePressCE() {
    const testCE=CE-1000
    if(testCE<=0) {
      setCE(0)
    }
    else {
      setCE(testCE)
    }
  }

  function handleChangeEC(e) {
    if (e.target.value.length > 0) {
      let newEc = parseInt(e.target.value);
      setEC(newEc);
    } else {
      setEC(0);
    }
  }

  function handleChangeEPro(e) {
    if (e.target.value.length > 0) {
      let newEPro = parseInt(e.target.value);
      setEPro(newEPro);
    } else {
      setEPro(0);
    }
  }

  function handleChangeCE(e) {
    if (e.target.value.length > 0) {
      let newCE = parseInt(e.target.value);
      setCE(newCE);
    } else {
      setCE(0);
    }
  }

  function handleChangeEPri(e) {
    if (e.target.value.length > 0) {
      let newEPri = parseInt(e.target.value);
      setEPri(newEPri);
    } else {
      setEPri(0);
    }
  }
  function handleNewSimul(){
    setGoToNewSimul(true)
  }

  if(goToHome) {
    return <Navigate push to={`/`} />
  }
  if(goToNewSimul) {
    return <Navigate push to={`/nouvellesimulation`} />
  }

  return (
    <div >
      <Entete titre="Résistance"/>
      <div className='container'>
        <div className='titrePlusVideo'>
          <div className='titre' style={{marginTop:('15px')}}> Quelle est la résistance aux chocs de votre exploitation ? </div>
          <ButtonVidéos link={'https://www.youtube.com/watch?v=UtS_bCqZljE&ab_channel=DiagoRisk'}/>
        </div>
        <div className='paragraphe'>Nous calculons votre reste à charge selon vos assurances et vos outils financiers   </div>
        <div className='paragraphe'>Le reste à charge brut correspon à la perte moins l'indemnisation de l'assurance et des outils financiers à votre disposition </div>

        <div className='dispLigne'>
          <div className='dispLeft'>
            <div className='card cardVerte'>
              <div className='titreCard'>Assurances</div>

              <div className='row flexLigne flexButtonAss'>
                <button className={BSA==='UnChecked' ? 'buttonResi buttonResiDefault' : 'buttonResi buttonResiChecked'}  onClick={handlePressSA} >
                  <div className='textbuttonResi'>Sans assurance</div>
                </button>
                <button className={BFS==='UnChecked' ? 'buttonResi buttonResiDefault' : 'buttonResi buttonResiChecked'}  onClick={handlePressFS} >
                  <div className='textbuttonResi'>Fonds de solidarité</div>
                </button>
                <button className={BA25==='UnChecked' ? 'buttonResi buttonResiDefault' : 'buttonResi buttonResiChecked'}onClick={handlePressAss25} >
                  <div className='textbuttonResi'>MRC franchise 25%</div>
                </button>
                <button className={BA20==='UnChecked' ? 'buttonResi buttonResiDefault' : 'buttonResi buttonResiChecked'} onClick={handlePressAss20} >
                  <div className='textbuttonResi'>MRC franchise 20%</div>
                </button>
              </div>         
            </div>

            <div className='card cardTrans'>
              <div className='titreCard'>Outils financiers (€)</div>

              <div className='row flexLigneOF flexLigneV'>
                <div className='textOF'>Economie de charge <span style={{fontSize:'smaller'}}>(~ 3% du produit brut)</span> </div>
                <div className='row flexSaisie'>
                  <button className='buttonPM' onClick={handlePressEC} > 
                    <div className='TextPM'> - </div>
                  </button>
                  <input className='inputResi'value={EC >= 0 ? EC.toString() : ""} onChange={handleChangeEC} />
                  <button className='buttonPM' onClick={() => {setEC(parseInt(EC)+1000)}}> 
                    <div className='TextPM'> + </div>
                  </button>
                </div>
              </div> 

              <div className='row flexLigneOF flexLigneJ'>
                <div className='textOF'>Epargne professionnelle <span style={{fontSize:'smaller'}}>(~ 2% du produit brut)</span></div>
                <div className='row flexSaisie'>
                  <button className='buttonPM'  onClick={handlePressEPro}> 
                    <div className='TextPM'> - </div>
                  </button>
                  <input className='inputResi'value={EPro >= 0 ? EPro.toString() : ""} onChange={handleChangeEPro} />
                  <button className='buttonPM' onClick={() => {setEPro(parseInt(EPro)+1000)}} > 
                    <div className='TextPM'> + </div>
                  </button>
                </div>
              </div> 

              <div className='row flexLigneOF flexLigneB'>
                <div className='textOF'>Capacité d'emprunt <span style={{fontSize:'smaller'}}>(~ 5% du produit brut)</span></div>
                <div className='row flexSaisie'>
                  <button className='buttonPM'  onClick={handlePressCE} >  
                    <div className='TextPM'> - </div>
                  </button>
                  <input className='inputResi' value={CE >= 0 ? CE.toString() : ""} onChange={handleChangeCE} />
                  <button className='buttonPM' onClick={() => {setCE(parseInt(CE)+1000)}} > 
                    <div className='TextPM'> + </div>
                  </button>
                </div>   
              </div>    
              
              <div className='row flexLigneOF flexLigneR'>
                <div className='textOF'>Epargne privée</div>
                <div className='row flexSaisie'>
                  <button className='buttonPM'  onClick={handlePressEPri} >  
                    <div className='TextPM'> - </div>
                  </button>
                  <input className='inputResi' value={EPri >= 0 ? EPri.toString() : ""} onChange={handleChangeEPri} />
                  <button className='buttonPM' onClick={() => {setEPri(parseInt(EPri)+1000)}} > 
                    <div className='TextPM'> + </div>
                  </button>
                </div>
              </div>    
            </div>
          </div>

          <div className='dispRight'>
            <div className='titreMoins'>Risque </div>

            <div className='row flexLigne'>
              <button className={BCou==='UnChecked' ? 'buttonRep buttonRep7Default rep' : 'buttonRep buttonRep7Checked rep'}  onClick={handlePressCourant} >
                Courant
              </button>
              <button className={BExc==='UnChecked' ? 'buttonRep buttonRep20Default rep' : 'buttonRep buttonRep20Checked rep'} onClick={handlePressExceptionnel}>
                Exceptionnel
              </button>
              <button className={BCat==='UnChecked' ? 'buttonRep buttonRep50Default rep' : 'buttonRep buttonRep50Checked rep'} onClick={handlePressCatastrophique}>
                Catastrophique
              </button>
            </div>

            <div className='row dispLigne3 dispLigne3Resi flexLigne'>
              <div className='dispLeftResi' style={{display:'flex', flexDirection:'column' }}>
                <div className='blocResi'>
                  <div className='videBlocResi videBlocP'>
                    <div className='pleinBlocResi pleinBlocP'>
                      <div className='titreBlocResi'>Pertes</div>
                    </div>
                    <div className='paragrapheBlocResi'>{(Mount?Pertes2: Pertes).toLocaleString()} € </div>
                  </div>
                </div>
              </div>

              <div className=' dispRightResi'>
                <div className='rotation'>
                  <GraphRAC dataPertes={dataPertes} dataRC={dataRC} dataCE={dataCE} dataEC={dataEC} dataEPri={dataEPri} dataEPro={dataEPro} dataAss={dataAss} PB={PBTot} maximum={maximum} />
                </div>
              </div>

              <div className='dispLeftResi' style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
              <div className='blocResi'>
                  <div className='videBlocResi videBlocRC'>
                    <div className='pleinBlocResi pleinBlocRC'>
                      <div className='titreBlocResi'>Reste à charge brut </div>
                    </div>
                    <div className='paragrapheBlocResi'>{RC.toLocaleString()} € </div>
                  </div>
                </div>

                <div className='blocResi'>
                  <div className='videBlocResi videBlocA'>
                    <div className='pleinBlocResi pleinBlocA'>
                      <div className='titreBlocResi'>Assurances</div>
                    </div>
                    <div className='paragrapheBlocResi'>{Ass.toLocaleString()} € </div> 
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* <div className='titreCard'> Robutesse des solutions d'assurances  </div>
        <div className='paragraphe' style={{marginBottom:'10px'}}> Contribution de l'assurance à la compensation des pertes de l'exploitation</div>
        <div className='rowResiScalePrincipal'>
          <div className='row rowresiscale RRS25'>
            <img src={dataScale25[0]['value'] < 0.2 ? MascotteRouge : 
              0.2<=dataScale25[0]['value'] && dataScale25[0]['value']<0.4 ? MascotteOrange : 
              0.4<= dataScale25[0]['value'] && dataScale25[0]['value']<0.6 ?  MascotteJaune : 
              0.6 <= dataScale25[0]['value'] && dataScale25[0]['value'] <0.8 ? MascotteVertPale : 
              MascotteVertFlash} 
              alt="Mascotte" style={{width:'20%'}}/>
            <RobustesseScale myData={dataScale25} />
          </div>
          <div className='row rowresiscale RRS20'>
          <img src={dataScale20[0]['value'] < 0.2 ? MascotteRouge : 
              0.2<=dataScale20[0]['value'] && dataScale20[0]['value']<0.4 ? MascotteOrange : 
              0.4<= dataScale20[0]['value'] && dataScale20[0]['value']<0.6 ?  MascotteJaune : 
              0.6 <= dataScale20[0]['value'] && dataScale20[0]['value'] <0.8 ? MascotteVertPale : 
              MascotteVertFlash} 
              alt="Mascotte" style={{width:'20%'}}/>
            <RobustesseScale myData={dataScale20} />
          </div>
        </div> */}

        <div className='titreCard'> Indemnisations d'assurance  </div>
        {data !== 0 ? (
        <div className='tableau_prix_rapport' style={{textAlign:'center'}}> 
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport titretableauresi' style={{fontWeight:'bold', width:'30%'}}> </div>
            <div className='data_tableau_prix_rapport titretableauresi' style={{width:'32%', backgroundColor:'#CADCE0'}}> Sans assurance </div>
            <div className='data_tableau_prix_rapport titretableauresi' style={{width:'32%'}}> Avec une assurance MRC par culture</div>
            {/* <div className='data_tableau_prix_rapport ' style={{width:'16%', borderBottomColor:'#E1DC09', backgroundColor:'#E1DC09'}}>  </div> */}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'30%', height:'90px'}}> Pour un risque : </div>
            <div className='data_tableau_prix_rapport ' style={{width:'16%', height:'90px'}}> La perte pourrait atteindre </div>
            <div className='data_tableau_prix_rapport ' style={{width:'16%', height:'90px'}}> Soit en % du produit brut de référence</div>
            <div className='data_tableau_prix_rapport ' style={{width:'16%', height:'90px', backgroundColor:'#CEEAAF', flexDirection:'column'}}> <div> Franchise 25% </div> <div style={{fontSize:"90%", fontWeight:'normal'}}> (indemnisation moyenne)</div> </div>
            <div className='data_tableau_prix_rapport ' style={{width:'16%', height:'90px', backgroundColor:'#7BC361', flexDirection:'column'}}> <div> Franchise 20% </div> <div style={{fontSize:"90%", fontWeight:'normal'}}> (indemnisation moyenne)</div>  </div>
            {/* <div className='data_tableau_prix_rapport ' style={{width:'16%', height:'90px', borderTopColor:'#E1DC09', backgroundColor:'#E1DC09', flexDirection:'column'}}><div> Fond de solidarité </div> <div style={{fontSize:"90%", fontWeight:'normal'}}> (indemnisation moyenne)</div>  </div> */}
          </div>

          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'30%', textAlign:'start'}}> Très courant (3 ans) </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[0].val.indemnisationmoyenne_3).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {data[0].val.rapportperte_3} % </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[25].val.indemnisationmoyenne_3).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[20].val.indemnisationmoyenne_3).toFixed(0).toLocaleString()} € </div>
            {/* <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[50].val.indemnisationmoyenne_3).toLocaleString()} € </div> */}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'30%', textAlign:'start'}}> Courant (7 ans) </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[0].val.indemnisationmoyenne_7).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {data[0].val.rapportperte_7} % </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[25].val.indemnisationmoyenne_7).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[20].val.indemnisationmoyenne_7).toFixed(0).toLocaleString()} € </div>
            {/* <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[50].val.indemnisationmoyenne_7).toFixed(0).toLocaleString()} € </div> */}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'30%', textAlign:'start'}}> Exceptionnel (20 ans) </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[0].val.indemnisationmoyenne_20).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {data[0].val.rapportperte_20} % </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[25].val.indemnisationmoyenne_20).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[20].val.indemnisationmoyenne_20).toFixed(0).toLocaleString()} € </div>
            {/* <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[50].val.indemnisationmoyenne_20).toFixed(0).toLocaleString()} € </div> */}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'30%', textAlign:'start'}}> Catastrophique (50 ans) </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[0].val.indemnisationmoyenne_50).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {data[0].val.rapportperte_50} % </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[25].val.indemnisationmoyenne_50).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[20].val.indemnisationmoyenne_50).toFixed(0).toLocaleString()} € </div>
            {/* <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[50].val.indemnisationmoyenne_50).toFixed(0).toLocaleString()} € </div> */}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'30%', textAlign:'start'}}> Extrême (100 ans) </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[0].val.indemnisationmoyenne_100).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[0].val.rapportperte_100).toFixed(0).toLocaleString()} % </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[25].val.indemnisationmoyenne_100).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[20].val.indemnisationmoyenne_100).toFixed(0).toLocaleString()} € </div>
            {/* <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[50].val.indemnisationmoyenne_100).toFixed(0).toLocaleString()} € </div> */}
          </div>
          <div className='row_tableau_prix_rapport' >
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'52%', borderRightColor:'white', borderLeftColor:'white'}}>  </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%', borderRightColor:'white', borderLeftColor:'white'}}>  </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%', borderRightColor:'white', borderLeftColor:'white'}}> </div>
            {/* <div className='data_tableau_prix_rapport' style={{width:'16%', borderBottomColor:'white', borderRightColor:'white', borderLeftColor:'white'}}>   </div> */}
          </div>
          <div className='row_tableau_prix_rapport' >
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'62%', textAlign:'start'}}> Prime technique <span style={{display: 'inline',fontWeight:'normal', marginLeft:'5px'}}> = montant des indemnisations reversées aux assurés</span> </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {((data[25].val.primePure)).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {((data[20].val.primePure)).toFixed(0).toLocaleString()} € </div>
            {/*<div className='data_tableau_prix_rapport' style={{width:'16%', borderTopColor:'white', borderBottomColor:'white', borderRightColor:'white'}}>   </div>*/}
          </div>
          <div className='row_tableau_prix_rapport' >
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'62%', textAlign:'start'}}> Prime brute <span style={{fontWeight:'normal', marginLeft:'5px'}}> = indemnisations + Frais de souscription, gestion, expertise et réassurance</span> </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {((data[25].val.primeAssurance)).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {((data[20].val.primeAssurance)).toFixed(0).toLocaleString()} € </div>
            {/*<div className='data_tableau_prix_rapport' style={{width:'16%', borderTopColor:'white', borderBottomColor:'white', borderRightColor:'white'}}>   </div>*/}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'62%', textAlign:'start'}}> Suvention FSN <span style={{fontWeight:'normal', marginLeft:'5px'}}> = envrion 90% de la prime à Franchise 50% pour les garanties subventionnables</span> </div>
            <div className='data_tableau_prix_rapport' style={{width:'32%'}}> {(data[25].val.subventionFSN).toFixed(0).toLocaleString()} € </div>
            {/*<div className='data_tableau_prix_rapport' style={{width:'16%', borderTopColor:'white', borderBottomColor:'white', borderRightColor:'white'}}>   </div>*/}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'62%', textAlign:'start'}}> Subvention Assurance <span style={{fontWeight:'normal', marginLeft:'5px'}}> = envrion 70% de la prime de la franchise à 50%</span> </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[25].val.subventionAssureur).toFixed(0).toLocaleString()}  € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%'}}> {(data[20].val.subventionAssureur).toFixed(0).toLocaleString()} € </div>
            {/*<div className='data_tableau_prix_rapport' style={{width:'16%', borderTopColor:'white', borderBottomColor:'white', borderRightColor:'white'}}>   </div>*/}
          </div>
          <div className='row_tableau_prix_rapport'>
            <div className='ordonnée_tableau_prix_rapport' style={{fontWeight:'bold', width:'62%', textAlign:'start'}}> Prime nette de subvention <span style={{fontWeight:'normal', marginLeft:'5px'}}> = Prime Brute moins les subventions</span> </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%', backgroundColor:'#CEEAAF'}}> {((data[25].val.primeAssurance).toFixed(0) - (data[25].val.subventionTotale)).toFixed(0).toLocaleString()} € </div>
            <div className='data_tableau_prix_rapport' style={{width:'16%', backgroundColor:'#7BC361'}}> {((data[20].val.primeAssurance).toFixed(0) - (data[20].val.subventionTotale)).toFixed(0).toLocaleString()} € </div>
             {/*<div className='data_tableau_prix_rapport' style={{width:'16%', borderTopColor:'white', borderRightColor:'white',borderBottomColor:'white'}}>   </div> */}
          </div>
          <div className='row' style={{marginTop:'20px'}}>
            <div className='paragraphe' style={{width:'22%'}}> Vous souhaitez modifier vos prix et vos rendements :</div>
            <button type='submit' className='validation' onClick={handleNewSimul} style={{marginTop:'0px'}}>  Créer un nouvel assolement </button>
          </div>
        </div>
        ): (
          ""
        )}

        <div className='paragraphe'> 
          <div className='titreMoins'>La prime proposée par mon assureur est TRES différente ? </div>
          Le tarif que nous calculons corresponds aux données de prix ou de rendement que vous avez renseignées et par défaut, aux références de votre région agricole. 
          <div>Vérifiez les options que vous avez souscrites et qui ne sont pas subventionnables : </div>
          <div>•	Rachat Franchise GRELE à la parcelle 	= la prime augmente de +40 à 100% (voire plus). </div>
          <div>•	Rachat de rendement et de prix </div>
          <div>•	Frais de resemis, … de sauvetage 	= + 30% </div>

          <div className='titreMoins'>La prime proposée par mon assureur est MOINS chère ? </div>
          Pour simplifier la gestion et cibler leur portefeuille, un assureur peut lisser le montant des primes. C’est notamment le cas dans des zones commerciales qui ne recouvrent plusieurs Régions, échelle à laquelle DiagoRisk travaille. 
          <div>Ce lissage peut amener à diminuer le tarif dans certaines zones. Vous en bénéficiez. </div>

          <div style={{marginTop:'10px'}}>ATTENTION, vérifiez les valeurs des rendements et les prix utilisés dans le calcul (Onglet « Mon exploitation » ou Bouton « Nouvel assolement ») et comparez-les aux données de votre contrat. Si VOS données de votre contrat sont inférieures, votre capital assuré est plus faible et vous êtes moins bien couverts. Dans tous les cas, validez que ces données correspondent à vos performances et à vos besoins en cas de sinistres. </div>

          <div className='titreMoins'>La prime proposée par mon assureur est PLUS chère ?</div>
          <div className='titreMoins' style={{marginLeft:'30px'}}>1.	Vérifier les options souscrites</div>
          Certaines garanties non subventionnables sont systématiquement proposées, souvent, les frais de sauvetage (+ 10 à 15%), les frais de resemis ou les frais supplémentaires de récoltes. 
          Le rachat de la franchise Grêle à la parcelle coûte entre 40% et 100% de l’assurance MRC à franchise 25% par nature de récolte.

          <div className='titreMoins' style={{marginLeft:'30px'}}>2.	 Les assurés sont généralement plus exposés que les non-assurés</div>
          Dans la théorie de l’assurance, cela s’appelle l’antisélection. Le portefeuille des assurés est déformé avec une proportion plus importante d’agriculteurs plus exposés, d’où un tarif plus élevé que la moyenne Assurés + Non-assurés sur laquelle s’appuie nos calculs. 

          <div className='titreMoins' style={{marginLeft:'30px'}}>3.	Les assurés font jouer leur assurance Récolte : ils « consomment » des indemnisations</div>
          Légitimement, un assuré en MRC mobilise son assurance en cas de sinistre. La proportion d’indemnisation a tendance à augmenter.

          <div className='titreMoins' style={{marginLeft:'30px'}}> 4.	Globalement, l’expertise bénéfice à l’assuré</div>
          L’évaluation des rendements et des pertes est un art difficile. Le doute a tendance à bénéficier à l’agriculteur, ce qui contribue à faire augmenter le tarif. 

        </div>

      </div>
      <ButtonQuestions/>
      <OADMenu idExploit={idExploit}/>
    </div>
  );
}

export default Resistance;
