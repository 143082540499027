import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphSolIrrig({Data2020, Data2030, Data2040, Data2050, max}) { 
    const getOptions = () => {
        let options = {
          legend: {
              data: ['2020', '2030', '2040', '2050'],
              textStyle: {
                fontSize: 16, // Ajustez la taille de la police de la légende selon vos besoins
              },
          },
          xAxis: {
            type: 'category',
            data: ['Courant (7 ans)', 'Exceptionnel (20 ans)', 'Catastrophique (50 ans)'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 15,
            },
            axisLabel: {
              fontSize: 14, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              interval: 0
            },
          },
          yAxis: {
            type: 'value',
            name: 'Besoin en eau en (mm)',
            nameTextStyle: {
              fontSize: 15,
            },
            axisLabel: {
              fontSize: 14,
            },
            max: Math.ceil(max / 10) * 10,
          },
          series: [
            {
              name: '2020',
              data: Data2020.slice(1),
              type: 'bar',
              barGap: 0,
              itemStyle: {
                color: '#9DB6C2',
              },
            },
            {
              name: '2030',
              data: Data2030.slice(1),
              type: 'bar',
              barGap: 0,
              itemStyle: {
                color: '#0CDDF0',
              },
            },
            {
              name: '2040',
              data: Data2040.slice(1),
              type: 'bar',
              barGap: 0,
              itemStyle: {
                color: '#2686D5',
              },
            },
            {
              name: '2050',
              data: Data2050.slice(1),
              type: 'bar',
              barGap: 0,
              itemStyle: {
                color: '#2D24D7',
              },
            },
          ],
        };
      
        return options;
      };
      
  

    return (
      <div style={{marginBottom:"20px", marginTop:'20px', width: '80%', height: '450px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphSolIrrig;
  