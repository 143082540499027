import React from 'react';
import Nav from '../MEP/SWNav';
import FooterSW from '../MEP/footer_SW';
import mail from '../../assets/mail-vert.png'
import tel from '../../assets/tel-vert.png'
import loca from '../../assets/pin-localisation-verte.png'
import map from "../../assets/Conseil_Régional_d'Île-de-France,_à_Saint-Ouen-sur-Seine_2.jpg"


function NousContacter() {
  return (
    <div>
      <Nav/>
      <div className="containerSW" >
      <h1 className="propositionValeur titreSW">Contact</h1>
        <div className='row card' style={{backgroundColor:'#D5F5B2', width:'90%', marginLeft:'2%',padding:'2%', justifyContent:"space-around"}}>
          <div style={{width:'50%'}}>
            <div className='row' style={{justifyContent:'flex-start'}}>
              <img src={tel} alt="icone de téléphone"  style={{width:"20%"}}/>
              <a className='paragrapheAccueil' href='tel:+33(0)6.85.48.01.87' style={{marginLeft:'20px'}}> +33(0)6.85.48.01.87 </a>
            </div>

            <div className='row' style={{justifyContent:'flex-start'}}>
              <img src={mail} alt="Icone mail"  style={{width:"20%"}}/>
              <a className='paragrapheAccueil' href='mailto:contact@diagorisk.com' style={{marginLeft:'20px'}}> contact@diagorisk.com </a>
            </div>
            <div className='row' style={{justifyContent:'flex-start'}}>
              <img src={loca} alt="Icone téléphone"  style={{width:"20%"}}/>
              <div>
                <div className='paragrapheAccueil' style={{fontWeight:'bold', marginLeft:'20px'}}> Le PERQO </div>
                <div className='paragrapheAccueil' style={{marginLeft:'20px'}}> 2 rue Simone Veil </div>
                <div className='paragrapheAccueil' style={{marginLeft:'20px'}}> 93400 Saint-Ouen-sur-Seine</div>
              </div>
            </div>
          </div>
          <div style={{width:'30%'}}>
          <img src={map} alt="Image du batiment de la région Ile-de-France"  style={{width:"100%"}}/>
          </div>
        
        </div>
      </div>
      <FooterSW/>
    </div>
  );
}

export default NousContacter;

