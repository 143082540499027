import React from 'react'
import RobustesseScale from '../../../OAD/Graphiques/robustess_scale'
import MascotteRouge from '../../../../assets/Mascotte/Effrayé-YC.png'
import MascotteOrange from '../../../../assets/Mascotte/Peur-YC.png'
import MascotteJaune from '../../../../assets/Mascotte/Neutre-YC.png'
import MascotteVertPale from '../../../../assets/Mascotte/Content-YC.png'
import MascotteVertFlash from '../../../../assets/Mascotte/Très-Heureux-YC.png'

function BlocCompResi({PertesCourant, PertesExceptionnel, PertesCatastrophe, Ass20_7, Ass20_20, Ass20_50, Ass25_7, Ass25_20, Ass25_50, AssFS_7, AssFS_20, AssFS_50,PBTot, dataScale25,dataScale20}) {
    const outilsFinanciers = Math.round(PBTot* (0.03 + 0.02 +0.05)/ 1000) * 1000 + 1000
  return (
    <div>
        <div className='row_prix_rapport' style={{marginBottom:'20px', justifyContent:'center',width:'100%'}}> 
            <div className='graphique_titre_prix_rapport buttonRep buttonRep7Default rep'>Courant </div>
        </div> 
        <div  className='tableau_prix_rapport'>
            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi' style={{borderTop:'white', borderLeft:'white'}}> </div>
            <div className='titre_ligne_tableau_resi'> Sans assurance </div>
            <div className='titre_ligne_tableau_resi'> Fond de solidarité </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 25% </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 20% </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(255, 57, 57)'}}> Pertes </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCourant).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold', backgroundColor:'rgb(255, 117, 117)'}}> Reste à charge </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers-AssFS_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers-Ass25_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCourant-outilsFinanciers-Ass20_7).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport multicolor' style={{fontWeight:'bold'}}> Outils financiers </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(177, 225, 124)'}}> Assurance </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > 0 € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(AssFS_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass25_7).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass20_7).toLocaleString()} € </div>
            </div>
        </div> 

        <div className='row_prix_rapport' style={{marginBottom:'20px', justifyContent:'center',width:'100%'}}> 
            <div className='graphique_titre_prix_rapport buttonRep buttonRep20Default rep'>Exceptionnel </div>
        </div>

        <div  className='tableau_prix_rapport'>
            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi' style={{borderTop:'white', borderLeft:'white'}}> </div>
            <div className='titre_ligne_tableau_resi'> Sans assurance </div>
            <div className='titre_ligne_tableau_resi'> Fond de solidarité </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 25% </div>
            <div className='titre_ligne_tableau_resi'> MRC franchise 20% </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(255, 57, 57)'}}> Pertes </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesExceptionnel).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold', backgroundColor:'rgb(255, 117, 117)'}}> Reste à charge </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers).toLocaleString()}€ </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers-AssFS_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers-Ass25_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesExceptionnel-outilsFinanciers-Ass20_20).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport multicolor' style={{fontWeight:'bold'}}> Outils financiers </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(177, 225, 124)'}}> Assurance </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > 0 € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(AssFS_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass25_20).toLocaleString()} € </div>
            <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass20_20).toLocaleString()} € </div>
            </div>
        </div> 

        <div className='row_prix_rapport' style={{marginBottom:'20px', justifyContent:'center',width:'100%'}}> 
            <div className='graphique_titre_prix_rapport buttonRep buttonRep50Default rep'>Catastrophique </div>
        </div>  

        <div  className='tableau_prix_rapport'>
            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi' style={{borderTop:'white', borderLeft:'white'}}> </div>
                <div className='titre_ligne_tableau_resi'> Sans assurance </div>
                <div className='titre_ligne_tableau_resi'> Fond de solidarité </div>
                <div className='titre_ligne_tableau_resi'> MRC franchise 25% </div>
                <div className='titre_ligne_tableau_resi'> MRC franchise 20% </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(255, 57, 57)'}}> Pertes </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {Math.round(PertesCatastrophe).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold', backgroundColor:'rgb(255, 117, 117)'}}> Reste à charge </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers-AssFS_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers-Ass25_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(PertesCatastrophe-outilsFinanciers-Ass20_50).toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport multicolor' style={{fontWeight:'bold'}}> Outils financiers </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} €  </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport'> {outilsFinanciers.toLocaleString()} € </div>
            </div>

            <div className='row_tableau_prix_rapport'>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' style={{fontWeight:'bold',backgroundColor:'rgb(177, 225, 124)'}}> Assurance </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > 0 € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(AssFS_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass25_50).toLocaleString()} € </div>
                <div className='titre_ligne_tableau_resi contenu_resi_tableau_rapport' > {Math.round(Ass20_50).toLocaleString()} € </div>
            </div>
        </div> 

        {/* <div className='rowResiScalePrincipal' style={{flexDirection:'column'}}>
          <div className='row rowresiscale RRS25' style={{width:'95%'}}>
            <img src={dataScale25[0]['value'] < 0.2 ? MascotteRouge : 
              0.2<=dataScale25[0]['value'] && dataScale25[0]['value']<0.4 ? MascotteOrange : 
              0.4<= dataScale25[0]['value'] && dataScale25[0]['value']<0.6 ?  MascotteJaune : 
              0.6 <= dataScale25[0]['value'] && dataScale25[0]['value'] <0.8 ? MascotteVertPale : 
              MascotteVertFlash} 
              alt="mascotte" style={{width:'20%'}}/>
            <RobustesseScale myData={dataScale25} />
          </div>
          <div className='row rowresiscale RRS20'style={{width:'95%'}}>
          <img src={dataScale20[0]['value'] < 0.2 ? MascotteRouge : 
              0.2<=dataScale20[0]['value'] && dataScale20[0]['value']<0.4 ? MascotteOrange : 
              0.4<= dataScale20[0]['value'] && dataScale20[0]['value']<0.6 ?  MascotteJaune : 
              0.6 <= dataScale20[0]['value'] && dataScale20[0]['value'] <0.8 ? MascotteVertPale : 
              MascotteVertFlash} 
              alt="mascotte" style={{width:'20%'}}/>
            <RobustesseScale myData={dataScale20} />
          </div>
        </div> */}

    </div>
  )
}

export default BlocCompResi