import React from 'react'

function ComponentManuel({handleCross, img1, img2, img3, img4, img5}) {
  
  return (
    <div className='containerSlideManuel'>
      <button className='ButonCrossMD' onClick={handleCross}>X</button>
      <div className='LigneimgManuel'>
        <img src={img1} alt="capture d'écran pour l'explication" className="imgManuel"/>
        {img2 === undefined ? "" : <img src={img2} alt="capture d'écran pour l'explication" className="imgManuel"/>}
        {img3 === undefined ? "" : <img src={img3} alt="capture d'écran pour l'explication" className="imgManuel"/>}
        {img4 === undefined ? "" : <img src={img4} alt="capture d'écran pour l'explication" className="imgManuel"/>}
        {img5 === undefined ? "" : <img src={img5} alt="capture d'écran pour l'explication" className="imgManuel"/>}
      </div>
      
    </div>
  )
}

export default ComponentManuel