import React from "react";
import {
    XYPlot,
    YAxis,
    HorizontalGridLines,
    VerticalBarSeries,
    makeVisFlexible,
  } from "react-vis";

function GraphRAC({print, dataPertes, dataRC, dataCE, dataEC, dataEPri, dataEPro, dataAss, PB, maximum}) {
  const yDomain= [0,maximum]
  const FlexXYPlot=makeVisFlexible(XYPlot)
  var hauteurFenetre = window.innerHeight;

  return (
    <div style={{marginTop:'25px'}}>
      <FlexXYPlot height={hauteurFenetre < 700 ? 375 : 530} stackBy="y" {...{ yDomain}} className="no-left-margin">
        <HorizontalGridLines  style={{stroke: '#C6C6C6'}}  />
        {/* <YAxis 
          tickFormat={v => Math.abs(v-PB).toLocaleString()}
          tickLabelAngle={180}
          tickTotal={8}
          style={{
          text: {stroke: 'none', fill: 'black', fontWeight: 40, fontSize:23},
          }}
        /> */}
        <VerticalBarSeries data={dataPertes}  color='#FF3939'/>
        <VerticalBarSeries data={dataRC}  color='#FF7575'/>
        <VerticalBarSeries data={dataEPri}  color='#5EFFF3'/>
        <VerticalBarSeries data={dataCE} color='#8bbaff'/>
        <VerticalBarSeries data={dataEPro} color='#ffff69'/>
        <VerticalBarSeries data={dataEC} color='#d790fa'/>
        <VerticalBarSeries data={dataAss} color='#b1e17c'/>

      </FlexXYPlot>
    </div>

  );
}

export default GraphRAC;
