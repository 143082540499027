import React, {useEffect} from 'react';
import {useLocation, Navigate} from 'react-router-dom'
import apiLink from '../../config.js'

import Entete from '../MEP/entete'
import ButtonQuestions from '../MEP/button question.js';
import OADMenu from '../MEP/oadMenu'
import GraphAssolement3 from './Graphiques/assolement_graph_3';
import ButtonVidéos from '../MEP/buttonVidéo.js';


function Exploitation() {
  const { state } = useLocation();
  const { idExploit } = state;
  const idAgent = JSON.parse(localStorage.getItem('idAgent'));
  const [goToHome, setGoToHome] = React.useState(false)

  const [myDataSurf, setDataSurf]= React.useState([]);
  const [myDataPb, setDataPb]= React.useState([]);
  const [surfTot, setsurfTot]= React.useState(0);
  const [PBTot, setPBTot]= React.useState(0);
  const [RALibelle, setRAlibelle]= React.useState("");

  useEffect ( () => {
    fetch(apiLink + 'page/exploitation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        setDataSurf(response.dataSurface)
        setDataSurf(prev => {
          return prev;
        })
        setDataPb(response.dataPB)
        setDataPb(prev => {
          return prev;
        })
        setsurfTot(response.surfaceTotale)
        setsurfTot(prev => {
          return prev;
        })
        setPBTot(response.PBTotal)
        setPBTot(prev => {
          return prev;
        })
        setRAlibelle(response.libelleRA)
        setRAlibelle(prev => {
          return prev;
        })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
      
    }) 
  }, [idExploit])

  const [goToNewSimul, setGoToNewSimul] = React.useState(false)
  
  const boutonNewSimul = (
    <div className='row'>
      <button type='submit' className='validation impr_simul' onClick={handleClickNewSimul}>  Nouvel assolement </button>
    </div>
  )
  function handleClickNewSimul(event) {
    event.preventDefault()
    setGoToNewSimul(true)
  }
  
  if(goToNewSimul) {
    return <Navigate push to={`/nouvellesimulation`} />
  }
  if(goToHome) {
    return <Navigate push to={`/`} />
  }

  return (
    <div >
      <Entete titre="Mon exploitation"/> 

      <div className='container'>
        <div className='titrePlusVideo'>
          <div className='titre' style={{marginTop:('15px')}}> Comment se décomposent mon assolement et mon produit brut ?  </div> 
          <ButtonVidéos link={'https://www.youtube.com/watch?v=XqMdZF6eu7s&ab_channel=DiagoRisk'}/>
        </div>
        <div className='paragraphe'> Votre région agricole est :  <b>{RALibelle}</b> </div>
        <div className='dispLigne'>
          <div className='column dispLeft'>
            <div className='titrePlus'> Surface <span style={{fontWeight:'normal', fontSize:'70%'}}> ({Math.round(surfTot)} hectares)</span> </div>
            <GraphAssolement3 myData={myDataSurf} PB={""} print={false}/>
          </div>

          <div className='column dispRight'>
            <div className='titrePlus'> Produit brut <span style={{fontWeight:'normal', fontSize:'70%'}}> ({PBTot.toLocaleString()} euros) </span> </div>
            <GraphAssolement3 myData={myDataPb} PB={""} print={false}/>
          </div>
        </div> 
        

        {idAgent!==null ? boutonNewSimul : ""}
      </div>
      <ButtonQuestions/>
      <OADMenu idExploit={idExploit}/>
    </div>

  );
}

export default Exploitation;
