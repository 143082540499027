import React from "react";
import ReactEcharts from "echarts-for-react";

  function GraphAssolement3({myData, PB, print}) { 
    const getOptions = () => {
      let options = {
        series: [
          {
            name: "Assolement de l'exploitation",
            type: "pie",
            radius: [30, 160],
            center: ["50%", "50%"],
            data: myData,
            roseType: 'area',
            itemStyle: {
              borderRadius: 20
            },
            label: {
              fontSize: 14,
              lineHeight: 22,
            },
          }
        ]
      };
  
      if (PB !== "") {
        options.graphic = [
          {
            type: 'text',
            top: 'center',
            left: 'center',
            style: {
              text: PB.toString() + ' €',
              textAlign: 'center',
              fill: '#000',
              fontSize: 14,
              fontWeight: 'bold'
            }
          }
        ];
      }
  
      return options;
    };
  

    return (
      <div style={{marginBottom:"20px", width: print ? '100%': '100%', height: print ? '400px': '350px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphAssolement3;
  