import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphAleasNBjours({myDatasousMoy, myDataSansRisque, print}) { 
    const min = Math.min(...myDataSansRisque) <20 ? 0 : Math.floor((Math.min(...myDataSansRisque) - 20) / 10) * 10
    const tresRisque = [100- myDatasousMoy[0], 100- myDatasousMoy[1], 100- myDatasousMoy[2], 100- myDatasousMoy[3], 100- myDatasousMoy[4], 100- myDatasousMoy[5], 100- myDatasousMoy[6]]
    const sousmoy = [myDatasousMoy[0]-myDataSansRisque[0], myDatasousMoy[1]-myDataSansRisque[1], myDatasousMoy[2]-myDataSansRisque[2], myDatasousMoy[3]-myDataSansRisque[3], myDatasousMoy[4]-myDataSansRisque[4], myDatasousMoy[5]-myDataSansRisque[5], myDatasousMoy[6]-myDataSansRisque[6]]
    function divideDataByTen(data) {
      // Diviser chaque élément du tableau par 10
      var newData = data.map(item => item / 10);
  
      return newData;
    }
    const getOptions = () => {
      let options = {
        grid: {
          top: '20%',
        },
        legend: {
          data: [ 'Année sans risque',"Année avec accident sous la moyenne", 'Année très risquée'],
          textStyle: {
            fontSize: 16, // Ajustez la taille de la police de la légende selon vos besoins
          },
      },
        xAxis: {
            type: 'category',
            data: ['2020','2025','2030','2035', '2040', '2045', '2050'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              
            }
          },
          yAxis: {
            type: 'value',
            name: "Nombre d'année sur 10",
            min: min/10,
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          series: [
            
            {
              name: 'Année sans risque',
              data: divideDataByTen(myDataSansRisque),
              type: 'bar',
              stack: 'stack', // Définir stack sur true
              itemStyle: {
                color: '#74CE00'
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data.toFixed(1)
                  },
                  position:'right',
                  textStyle: {
                    fontSize: 14 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
            {
              name: 'Année avec accident sous la moyenne',
              data: divideDataByTen(sousmoy),
              type: 'bar',
              stack: 'stack', // Définir stack sur true
              itemStyle: {
                color: '#E7DE1F',
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data.toFixed(1)
                  },
                  position:'right',
                  textStyle: {
                    fontSize: 14 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
            {
              name: 'Année très risquée',
              data: divideDataByTen(tresRisque),
              type: 'bar',
              stack: 'stack', // Définir stack sur true
              itemStyle: {
                color: 'red',
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data.toFixed(1)
                  },
                  position:'right',
                  textStyle: {
                    fontSize: 14 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
        ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom:print === true ? "10px" : "20px", width: print === true ? '60%' : '80%', height: print === true ? '550px' :  '450px', marginTop:print === true ? '10px' : '30px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphAleasNBjours;
  