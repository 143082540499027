import React, {useState} from 'react'
import QST from '../../assets/caméra.png'

function ButtonVidéos({link}) {
    const handlVideo = () => {
        window.open(link, '_blank');
    }
    const [texteVisible, setTexteVisible] = useState(false);

    const afficherTexte = () => {
        setTexteVisible(true);
    };

    const cacherTexte = () => {
        setTexteVisible(false);
    }
    const styleTexte = {
        width:'5%',
        position: 'absolute',
        top: '80px', // Ajustez cette valeur pour la position verticale
        left: '570px',
        transform: 'translateX(-50%)',
        background: '#fff',
        border:'solid',
        borderWidth:'1px',
        borderColor:'#A7A7A7',
        color:'#8B8B8B',
        padding: '5px',
        display: texteVisible ? 'block' : 'none',
      };

    return (
    <div style={{marginTop:'15px'}}>
        <div style={styleTexte}>Tutoriel</div>
        <button className='ButtonCam ' onClick={handlVideo} onMouseEnter={afficherTexte} onMouseLeave={cacherTexte}>
            <img src={QST} className="IconeCam"  alt='bouton question'/>
        </button>
    </div>
    )
}

export default ButtonVidéos