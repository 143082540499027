import React from "react";
import ReactEcharts from "echarts-for-react"; function GraphDifference_comp2050({D2020, D2020_2,D2030, D2030_2,D2040, D2040_2, D2050, D2050_2, print}) { 
    const getOptions = () => {
      let options = {
        xAxis: {
            type: 'category',
            data: ['Très courant', 'Courant', 'Exceptionnel', 'Catastrophique'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 14, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              interval: 0 // Forcer l'affichage de toutes les étiquettes de l'axe x // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          legend : {
            data:['2020_1', '2020_2', '2030_1', '2030_2', '2040_1', '2040_2', '2050_1', '2050_2'],
            textStyle: {
              fontSize: 15 // Ajustez la taille de la police de la légende selon vos besoins
            }
          },
          yAxis: {
            type: 'value',
            name: 'Pertes en %',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          series: [
            {
              name: '2020_1',
              data: D2020,
              type: 'line',
              smooth: true,
              symbol: 'circle',
              symbolSize: 10,
              lineStyle: {
                width:2, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: 'green',
              }
            },
            {
              name: '2020_2',
              data: D2020_2,
              type: 'line',
              smooth: true,
              symbol: 'circle',
              symbolSize: 10,
              lineStyle: {
                  width:2, // Ajustez la largeur de la ligne selon vos besoins
                  type: 'dashed',
              },
              itemStyle: {
                color: 'green',
              }
              },
              {
                name: '2030_1',
                data: D2030,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width:2, // Ajustez la largeur de la ligne selon vos besoins
                  },
                itemStyle: {
                  color: '#DDE31B',
                }
              },
              {
                name: '2030_2',
                data: D2030_2,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width:2, // Ajustez la largeur de la ligne selon vos besoins
                    type: 'dashed',
                  },
                itemStyle: {
                  color: '#DDE31B ',
                }
              },
              {
                name: '2040_1',
                data: D2040,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                  width:2, // Ajustez la largeur de la ligne selon vos besoins
                },
                itemStyle: {
                  color: 'orange',
                }
              },
              {
                name: '2040_2',
                data: D2040_2,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                  width:2, // Ajustez la largeur de la ligne selon vos besoins
                  type: 'dashed',
                },
                itemStyle: {
                  color: 'orange',
                }
              },              
              {
                name: '2050_1',
                data: D2050,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                  width:2, // Ajustez la largeur de la ligne selon vos besoins
                },
                itemStyle: {
                  color: 'red',
                }
              },
              {
                name: '2050_2',
                data: D2050_2,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                  width:2, // Ajustez la largeur de la ligne selon vos besoins
                  type: 'dashed',
                },
                itemStyle: {
                  color: 'red',
                }
              },
          ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom: print === true ? "0px" : "20px", width: print === true ? '60%' : '100%', height: '450px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphDifference_comp2050;
  