import React from 'react'
import apiLink from '../../../config.js'
import axios from "axios";
import oeilShow from '../../../assets/oeilShow.png'
import oeilHide from '../../../assets/oeilHide.png'

function PopUpMDP(props) {
    const [MDP, setMDP]= React.useState('');
    const [MDP2, setMDP2]= React.useState('');
    const [Show, setShow]= React.useState(false);
    const [Save, setSave]= React.useState(false)
    const mesDonnees = new FormData(); 
    const idAgent = JSON.parse(localStorage.getItem('idAgent'));

    function handleChangeMDP(event) {
        var MDP = event.target.value
        setMDP(MDP)   
    }
    function handleChangeMDP2(event) {
        var MDP = event.target.value
        setMDP2(MDP)   
    }
    function ShowHide () {
        setShow(!Show)
    }

    function handleSubmit(event) {
        if(MDP===MDP2  && (MDP!=="" || MDP2!=="")) {
            event.preventDefault()
            mesDonnees.set("password", MDP);

            axios({
                method: 'post',
                url: apiLink + 'updatePassword/' + idAgent, 
                data: mesDonnees
            })
            .then(function (reponse) {
                if(reponse.status === 200){
                setSave(true) 
                }
                else {
                alert("Une erreur c'est produite, votre mot de passe n'as pas pu être changé")
                }
            })
            .catch(function (erreur) {
                //On traite ici les erreurs éventuellement survenues
                console.log(erreur);
            });
            setTimeout(function() {
                setMDP('')
                setMDP2('')
                setSave(false)
                props.setTrigger(false)
            }, 1000)
        }
        else {
            alert("Les deux mots de passe sont différents")
        }
        
    }

    return ( props.trigger) ? (
        <div className='popupLegend popupMDP'>
            <div className='popup-inner-MDP'>
                <button className='close-btn close-btn-profil' onClick={() => props.setTrigger(false)}>X</button>
                <form onSubmit={handleSubmit} name="MAJ_MDP" style={{width:'100%'}}>
                    <div className='titreMoins' > Nouveau mot de passe : </div>
                    <div className='PasseWord'>
                        <input 
                        className='input'
                        value={MDP}
                        onChange={handleChangeMDP}
                        placeholder='Mot de passe'
                        type={Show? 'text' : 'password'}
                        style={{marginBottom:'10px', }}
                        required/>
                        <button className='ButtonShowHide ButtonShowHide2' onClick={ShowHide}> <img src={Show? oeilShow : oeilHide} className="IconHome" alt='mot de passe visible ou non'/> </button>
                    </div>
                    <div className='titreMoins' > Nouveau mot de passe (validation) : </div>
                    <div className='PasseWord'>
                        <input 
                        className='input'
                        value={MDP2}
                        onChange={handleChangeMDP2}
                        placeholder='Mot de passe'
                        type={Show? 'text' : 'password'}
                        style={{marginBottom:'10px', }}
                        required/>
                    </div>
                    <div className='textInscription' style={{color:MDP===MDP2  && (MDP!=="" || MDP2!=="")? 'green' :'red'}}>{MDP===MDP2 && (MDP!=="" || MDP2!=="")? 'Mot de passe confirmé' : 'Mot de passe incorrect et/ou différent'}</div>
                    <div className='row'>
                        <button type='submit' className='validation'> Enregistrer </button>
                    </div>
                    <div className='textInscription' style={{color:'green'}}>{Save ? 'Modifications enregistrées' : ''}</div>
                </form>
                {props.children}
            </div>
    
        </div>
      ) : "" ;
}

export default PopUpMDP