import React from 'react';
import {TileLayer, GeoJSON, MapContainer} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

function CarteRisquesCulture2050({data, loading, irrig}) {
    const bbox = require('geojson-bbox');
    const extent = bbox(data); 
  
    function getColor(d) {
      if(!irrig){
        return 	d > 20 ? '#000000' :
                d > 18 ? '#3F004E' :
                d > 16 ? '#7D009D' :
                d > 14 ? '#9F00A5' :
                d > 12 ? '#A30165' :
                d > 10 ? '#A70126' :
                d > 8 ? '#B83141' :
                d > 6 ? '#CA635D' :
                d > 4 ? '#DC9479' :
                d > 2 ? '#EDC696' :
                d > 0 ? '#FFF7B2' :
                d > -2 ? '#F6FCB2' :
                d > -4 ? '#E5F39B' :
                d > -6 ? '#CEEA86' :
                d > -8 ? '#BCE273' :
                d > -10 ? '#9ED669' :
                d > -12 ? '#80C865' :
                d > -14 ? '#60B861' :
                d > -16 ? '#37A657' :
                d > -18 ? '#1A984F' :
                d > -20 ? '#0A8043' :
                '#006838'; //le dernier on ne met pas son nom car le reste
      }
      else {
        return 	d > 90 ? '#0A004A' :
                d > 80 ? '#0B1C76' :
                d > 70 ? '#0D39A3' :
                d > 60 ? '#0E55CF' :
                d > 50 ? '#1071FC' :
                d > 40 ? '#257EFA' :
                d > 30 ? '#3D89F4' :
                d > 20 ? '#5495EF' :
                d > 15 ? '#6CA0E9' :
                d > 10 ? '#8EB6ED' :
                d > 5 ? '#D9E6F9' :
                d > 0.01 ? '#D9E6F9' :
                d > -0.01 ? '#FFFFFF' :
                d > -5 ? '#E1FBF3' :
                d > -10 ? '#C3F6E6' :
                d > -15 ? '#A5F1D9' :
                d > -20 ? '#87EDCD' :
                d > -30 ? '#73ECC5' :
                d > -40 ? '#5FEABE' :
                d > -50 ? '#4CE9B7' :
                d > -60 ? '#38E7B0' :
                d > -70 ? '#29CC9A' :
                d > -80 ? '#1BA67F' :
                d > -90 ? '#0E8164' :
                '#005B49'; //le dernier on ne met pas son nom car le reste
      }
        
    }
  
    function style(feature) {
      return {
        fillColor: getColor(feature.properties.risques),
        weight: 0.2,
        opacity: 1,
        color: '#000000',
        fillOpacity: 0.8,
      };
    }
  
    function highlightFeature(e) {
      var layer = e.target;
      var { risques, libelle} = e.target.feature.properties;
      var infos_risques = risques
      var infos_libelle = libelle
      var unite = irrig ? "mm" : "%"
      layer.bindPopup('<p> <b>' + infos_libelle + ' : </b>' + infos_risques + ' ' + unite +' </p>')
  
    }
    function onEachFeature(feature, layer) {
      layer.on({
        click: highlightFeature
      });
    }
  
    return (
      <div className="totalCarte">
      {!loading && (
          <div className="">
          <MapContainer
            zoom={10}
            className='HeightMapRiskRdt'
            scrollWheelZoom={false}
            maxZoom={14}
            center={[48.832,2.623]}
            whenReady={e => {
              e.target.flyToBounds([
                [extent[1],extent[0]],
                [extent[3], extent[2]]
                  
                ]);
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {data && (
              <div>
                <GeoJSON data={data} 
                style={style} 
                onEachFeature={onEachFeature} />
              </div>
            )}
          </MapContainer>
        </div>
        
      )}
      </div>
    );
  }

export default CarteRisquesCulture2050;
