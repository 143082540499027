import React, {useState} from 'react'
import {Navigate} from 'react-router-dom'
import expl from '../../assets/navig_exploit_ac.png'
import rdt from '../../assets/navig_riskRDT_ac.png'
import prix from '../../assets/navig_riskPRIX_ac.png'
import resis from '../../assets/navig_robustesse_ac.png'
import opti from '../../assets/navig_opti_ac.png'
import optiAss from '../../assets/navig_assol_ac.png'
import r3050 from '../../assets/navig2050_2030-2050_ac.png'
import aleas from '../../assets/navig2050_aleas_ac.png'
import sol50 from '../../assets/navig_solution_ac.png'
import plus from '../../assets/navig2050_plus_ac.png'

import explDefaut from '../../assets/navig_exploit_inac.png'
import rdtDefaut from '../../assets/navig_riskRDT_inac.png'
import prixDefaut from '../../assets/navig_riskPRIX_inac.png'
import resisDefaut from '../../assets/navig_robustesse_inac.png'
import optiDefaut from '../../assets/navig_opti_inac.png'
import optiAssDefaut from '../../assets/navig_assol_inac.png'
import r3050Defaut from '../../assets/navig2050_2030-2050_inac.png'
import aleasDefaut from '../../assets/navig2050_aleas_inac.png'
import sol50Defaut from '../../assets/navig_solution_inac.png'
import plusDefaut from '../../assets/navig2050_plus_inac.png'

function  OADMenu ({idExploit}) {
    const role = JSON.parse(localStorage.getItem('role')); 
    const formule = JSON.parse(localStorage.getItem('formule')); 
    
    const [goToExploit, setGoToExploit] = useState(false)
    const [goToRdt, setGoToRdt] = useState(false)
    const [goToPrix, setGoToPrix] = useState(false)
    const [goToResis, setGoToResis] = useState(false)
    const [goToOpti, setGoToOpti] = useState(false)
    const [goToOptiAss, setGoToOptiAss] = useState(false)
    const [goToresultats3050, setgotoR3050] = useState(false)
    const [goToAleas, setgotoaleas] = useState(false)
    const [goToSolutions2050, setgotoSol50] = useState(false)
    const [Plus, setPlus] = useState(false)

    const url = window.location.href

    function handleClickE() {
        setGoToExploit(true)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(false)
        setgotoR3050(false)
        setgotoaleas(false)
        setgotoSol50(false)
        setPlus(false)
    }
    function handleClickRen() {
        setGoToExploit(false)
        setGoToRdt(true)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(false)
        setgotoR3050(false)
        setgotoaleas(false)
        setgotoSol50(false)
        setPlus(false)
    }
    function handleClickOA() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(true)
        setgotoR3050(false)
        setgotoaleas(false)
        setgotoSol50(false)
        setPlus(false)
    }
    function handleClickR3050() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(false)
        setgotoR3050(true)
        setgotoaleas(false)
        setgotoSol50(false)
        setPlus(false)
    }
    function handleClickaleas() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(false)
        setgotoR3050(false)
        setgotoaleas(true)
        setgotoSol50(false)
        setPlus(false)
    }
    function handleClickSol50() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(false)
        setGoToOptiAss(false)
        setgotoR3050(false)
        setgotoaleas(false)
        setgotoSol50(true)
        setPlus(false)
    }


    function handleClickPlus() {
        // ça ouvre une div avec les 3 boutons dedans 
        setPlus(true)
    }
    function handleClickRes() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(true)
        setGoToOpti(false)
        setGoToOptiAss(false)
        setgotoR3050(false)
        setgotoaleas(false)
        setgotoSol50(false)
        setPlus(true)
    }
    function handleClickO() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(false)
        setGoToResis(false)
        setGoToOpti(true)
        setGoToOptiAss(false)
        setgotoR3050(false)
        setgotoaleas(false)
        setgotoSol50(false)
        setPlus(true)
    }
    function handleClickP() {
        setGoToExploit(false)
        setGoToRdt(false)
        setGoToPrix(true)
        setGoToResis(false)
        setGoToOpti(false)
        setgotoR3050(false)
        setgotoaleas(false)
        setgotoSol50(false)
        setPlus(true)
    }
    

    if(goToExploit) {
        return <Navigate push to={'/exploitation'} state={{idExploit}} />
    }
    if(goToRdt) {
        return <Navigate push to={'/risquesrendement'} state={{idExploit}}/>
    }
    if(goToOptiAss) {
            return <Navigate push to={'/compassolement'} state={{idExploit}}/>
    }
    if(goToresultats3050) {
        return <Navigate push to={'/resultats20302050'} state={{idExploit}}/>
    }
    if(goToAleas) {
        return <Navigate push to={'/aleas'} state={{idExploit}}/>
    }
    if(goToSolutions2050) {
        return <Navigate push to={'/solutions2050'} state={{idExploit}}/>
    }
    if(goToResis) {
        return <Navigate push to={'/resistance'} state={{idExploit}}/>
    }
    if(goToOpti) {
        return <Navigate push to={'/optimisationfinanciere'} state={{idExploit}}/>
    }
    if(goToPrix) {
        return <Navigate push to={'/risquesprix'} state={{idExploit}}/>
    }



    return (
        <div>
        <div className={role==="ROLE_ADMINISTRATEUR" ?'row menuOad backgroundAdmin' : formule==="Flash"  ? 'row menuOad backgroundFlash' :'row menuOad backgroundExploitant'}>
            <button className=' ButtonMenuOAD' disabled={url.indexOf("exploitation") > -1 ? true : false} onClick={handleClickE}> 
                <img src={url.indexOf("exploitation") > -1 ? expl : explDefaut}  alt='icone' className='IconMenuOAD'/> 
                <div className={url.indexOf("exploitation") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}> Exploitation </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("risquesrendement") > -1 ? true : false} onClick={handleClickRen}> 
                <img src={url.indexOf("risquesrendement") > -1 ? rdt : rdtDefaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("risquesrendement") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD  nomMenuOADDefault'}>  Rendement </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("resultats20302050") > -1 ? true : false} onClick={handleClickR3050}> 
                <img src={url.indexOf("resultats20302050") > -1 ? r3050 : r3050Defaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("resultats20302050") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}> 2030/2050 </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("aleas") > -1 ? true : false} onClick={handleClickaleas}> 
                <img src={url.indexOf("aleas") > -1 ? aleas : aleasDefaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("aleas") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}> Aléas </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("solutions2050") > -1 ? true : false} onClick={handleClickSol50}> 
                <img src={url.indexOf("solutions2050") > -1 ? sol50 : sol50Defaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("solutions2050") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}> Solutions </div>
            </button>
            
            <button className='ButtonMenuOAD' disabled={url.indexOf("compassolement") > -1 ? true : false} onClick={handleClickOA}> 
                <img src={url.indexOf("compassolement") > -1 ? optiAss : optiAssDefaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("compassolement") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}>  Assolement </div>
            </button>
            <button className='ButtonMenuOAD'  onClick={handleClickPlus}> 
                <img src={url.indexOf("resistance") > -1 || url.indexOf("optimisation") > -1 ? plus : plusDefaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("resistance") > -1 || url.indexOf("optimisation") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}> Plus </div>
            </button>

            {/* <button className='ButtonMenuOAD' disabled={url.indexOf("resistance") > -1 ? true : false} onClick={handleClickRes}> 
                <img src={url.indexOf("resistance") > -1 ?  resis : resisDefaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("resistance") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD  nomMenuOADDefault'}>  Resistance </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("optimisation") > -1 ? true : false} onClick={handleClickO}> 
                <img src={url.indexOf("optimisation") > -1 ? opti : optiDefaut} alt='icone'  className='IconMenuOAD '/> 
                <div className={url.indexOf("optimisation") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}>  Optimisation </div>
            </button>
            <button className='ButtonMenuOAD' disabled={url.indexOf("risquesprix") > -1 ? true : false} onClick={handleClickP}> 
                <img src={url.indexOf("risquesprix") > -1 ? prix :prixDefaut} alt='icone'  className='IconMenuOAD'/> 
                <div className={url.indexOf("risquesprix") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD  nomMenuOADDefault'}>  Prix </div>
            </button> */}
        </div>
        <div className={Plus ? 'ShowResult' : 'HideResult'}>
            <div className={role==="ROLE_ADMINISTRATEUR" ?'row menuOad SOmenuOad backgroundAdmin' : formule==="Flash"  ? 'row menuOad backgroundFlash' :'row menuOad SOmenuOad backgroundExploitant'}>
                <button className='ButtonMenuOAD' disabled={url.indexOf("resistance") > -1 ? true : false} onClick={handleClickRes} style={{width:'50%'}}> 
                    <img src={url.indexOf("resistance") > -1 ?  resis : resisDefaut} alt='icone'  className='IconMenuOAD'/> 
                    <div className={url.indexOf("resistance") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD  nomMenuOADDefault'}>  Resistance </div>
                </button>
                <button className='ButtonMenuOAD' disabled={url.indexOf("optimisation") > -1 ? true : false} onClick={handleClickO} style={{width:'50%'}}> 
                    <img src={url.indexOf("optimisation") > -1 ? opti : optiDefaut} alt='icone'  className='IconMenuOAD '/> 
                    <div className={url.indexOf("optimisation") > -1 ? 'nomMenuOAD nomMenuOADChecked' : 'nomMenuOAD nomMenuOADDefault'}>  Optimisation </div>
                </button>
            </div>
        </div>
        </div>
    )
    
  }
  
  
  export default OADMenu;