import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphAleasRapport({myDataMoy2D, myDataMax2D, myDataMin2D, myDataQ12D, myDataQ32D, myDataMoy4D, myDataMax4D, myDataMin4D, myDataQ14D, myDataQ34D, degre, aleas, print}) {  
    const getOptions = () => {
      let options = {
        legend: {
          data: ['Minimum 2°C','75% 2°C','Moyenne 2°C', '25% 2°C','Maximum 2°C', 'Minimum 4°C','75% 4°C','Moyenne 4°C', '25% 4°C','Maximum 4°C' ],
          textStyle: {
            fontSize: 16, // Ajustez la taille de la police de la légende selon vos besoins
          },
      },
      grid: {
        top: '20%',
        bottom: '10%' 
      },
        xAxis: {
            type: 'category',
            data: ['2020','2025','2030','2035', '2040', '2045', '2050'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              
            }
          },
          yAxis: {
            type: 'value',
            name: 'Pertes en %',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          series: [
            {
              name: 'Maximum 2°C',
              data: myDataMax2D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: 'red'
              }
            },
            {
              name: '25% 2°C',
              data: myDataQ32D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 4, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: 'orange'
              }
            },
            {
              name: 'Moyenne 2°C',
              data: myDataMoy2D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 4, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: 'yellow'
              }
            },
            {
              name: '75% 2°C',
              data: myDataQ12D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 4, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: '#A3E31B'
              }
            },
            {
              name: 'Minimum 2°C',
              data: myDataMin2D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: 'green'
              }
            },
            {
              name: 'Maximum 4°C',
              data: myDataMax4D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                type: 'dashed',
              },
              itemStyle: {
                color: 'red'
              }
            },
            {
              name: '25% 4°C',
              data: myDataQ34D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 4, // Ajustez la largeur de la ligne selon vos besoins
                type: 'dashed',
              },
              itemStyle: {
                color: 'orange'
              }
            },
            {
              name: 'Moyenne 4°C',
              data: myDataMoy4D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 4, // Ajustez la largeur de la ligne selon vos besoins
                type: 'dashed',
              },
              itemStyle: {
                color: 'yellow'
              }
            },
            {
              name: '75% 4°C',
              data: myDataQ14D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 4, // Ajustez la largeur de la ligne selon vos besoins
                type: 'dashed',
              },
              itemStyle: {
                color: '#A3E31B'
              }
            },
            {
              name: 'Minimum 4°C',
              data: myDataMin4D,
              type: 'line',
              smooth: true,
              symbol: 'none',
              symbolSize: 10,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                type: 'dashed',
              },
              itemStyle: {
                color: 'green'
              }
            }
          ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom:print === true ? "10px" : "20px", width: print === true ? '60%' : '98%', height: print === true ? '550px' :  '450px', marginTop:print === true ? '10px' : '30px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphAleasRapport;
  