import React from "react";
import ReactEcharts from "echarts-for-react"; function GraphDifference({myData2020, myData2,annee, dataGraph2020SimFerme, print}) { 
    const getOptions = () => {
      let options = {
        xAxis: {
            type: 'category',
            data: ['Très courant', 'Courant', 'Exceptionnel', 'Catastrophique'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 14, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              interval: 0 // Forcer l'affichage de toutes les étiquettes de l'axe x // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          legend : {
            data:['2020', annee],
            textStyle: {
              fontSize: 20 // Ajustez la taille de la police de la légende selon vos besoins
            }
          },
          yAxis: {
            type: 'value',
            name: 'Pertes en %',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          series: [
            {
              name: '2020',
              data: myData2020,
              type: 'line',
              smooth: true,
              symbol: 'circle',
              symbolSize: 10,
              lineStyle: {
                width: 3, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: 'green',
              }
            },
            {
                name: annee,
                data: myData2,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width: 3, // Ajustez la largeur de la ligne selon vos besoins
                  },
                  itemStyle: {
                    color: 'red',
                  }
              },
              {
                name: '2020SimFerme',
                data: dataGraph2020SimFerme,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                  width: 3, // Ajustez la largeur de la ligne selon vos besoins
                },
                itemStyle: {
                  color: 'blue',
                }
              },
          ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom: print === true ? "0px" : "20px", width: print === true ? '60%' : '100%', height: '450px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphDifference;
  