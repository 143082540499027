import React, {useState, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import apiLink from '../../../config.js'

import fleche_verte from '../../../assets/Fleche-ouverte-verte.png'
import fleche_grise from '../../../assets/Fleche-fermee-grise.png'

import ButtonQuestions from '../../MEP/button question.js'
import Entete from '../../MEP/entete'
import Simul from './simulation'
import LocalisationIns from '../../Loc_et_assol/localisation_inscription'

function MesSimulations() {

  const [data, setData] = useState({"3603": {"idExploitation": "3603","nom": "Libelle", "date":{"date":"2023-01-17 16:24:24.000000","timezone_type":3,"timezone":"Europe\/Paris"}, "productions": ["Blé tendre"]}}) //eslint-disable-line
  const [goToComp, setGoToComp] = useState(false)
  const [goToComp2050, setGoToComp2050] = useState(false)
  const [Sim1, setSim1] = useState()
  const [Sim2, setSim2] = useState()
  const [idExpType, setidExpType] = useState()
  const [ListCheck, setListCheck] = useState("")
  const [goToHome, setGoToHome] = React.useState(false)
  const idExploitationPrincipale = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));
  const idAgent = JSON.parse(localStorage.getItem('idAgent'))

  const [BExpType, setBExpType] = React.useState(false)
  const [idExploit, setidExploit] = React.useState("")
  const [CityET1, setCityET1] = React.useState("")
  const [CityET2, setCityET2] = React.useState("")
  const [goToExploit, setGoToExploitation] = React.useState(false)
  const [dataCommune, setDataCommune] = React.useState({})
  

  
  useEffect(() => {
    fetch(apiLink + 'getExploitations/' + idAgent)
        .then((response) => {
            return response.json();
        })
        .then(response => {
            if (Object.keys(response).length > 0) {
                setData(response);
                setData(prev => {
                    var list = {};
                    Object.keys(prev).map(key => (
                        list[key] = "unchecked"
                    ));
                    setListCheck(list);
                    return prev;
                });
            } else {
                localStorage.clear();
                setGoToHome(true);
            }
        });

    fetch(apiLink + 'getLocalisation/' + idExploitationPrincipale)
        .then((response) => {
            return response.json();
        })
        .then(response => {
            let city;
            city = response.idCommune;
            fetch(apiLink + 'page/production-rapport/' + idExploitationPrincipale)
                .then((response) => {
                    return response.json();
                })
                .then(response => {
                    let data = response;
                    var vitiPres = false;
                    for (var cle in data) {
                        if (cle.includes("Vignes")) {
                            vitiPres = true;
                        }
                    }
                    if (vitiPres) {
                        fetch(apiLink + 'getExploitationType/' + city + '/2')
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                setidExpType(response);
                                setidExpType(prev => {
                                    return prev.idExploitation;
                                });
                            });
                    } else {
                        fetch(apiLink + 'getExploitationType/' + city)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                setidExpType(response);
                                setidExpType(prev => {
                                    return prev.idExploitation;
                                });
                            });
                    }
                });
        });
        fetch(apiLink + 'getListeExploitationsTypes/2')
        .then((response) => {
            return response.json();
        })
        .then((response) => {
          setDataCommune(response);
          setDataCommune(prev => {
              return prev
          });
        });
        // fetch de l'api qui récupère les explotations type 2050
}, [idAgent, idExploitationPrincipale]);


    function simulAcomparer(key,simulationChecked) {
      var newData = {...ListCheck};
      newData[key] = simulationChecked
      setListCheck(newData)
      setListCheck(prev => {
        return prev;
      }) 
    }
  
  const table_simul=  Object.keys(data).map(key => (
    <Simul 
    keys={key}
    data={data}
    idExploit={key}
    libelle={data[key]["nom"]}
    date={new Date(data[key]["date"]["date"]).toLocaleDateString("fr")}
    assol={data[key]["productions"].join(' / ')}
    simulAcomparer={simulAcomparer}
    ListCheck={ListCheck}
    admin={false}
    />
  ))

  function NbChecked(ListCheck){
    var nbsimul = 0
    Object.keys(ListCheck).map(function(key) {
      if(ListCheck[key] === 'checked'){
        nbsimul+=1
      }
      else{
        nbsimul+=0
      }
    })
    return nbsimul
  }
  function WhatChecked(ListCheck, idExpType){
    var Sim1 = 0
    var Sim2 = 0
    var nbsimul = NbChecked(ListCheck)
    if(nbsimul ===1 ||  nbsimul ===2){
      Object.keys(ListCheck).map(function(key) {
        if(ListCheck[key] === 'checked' && Sim1 === 0){
          Sim1 = key
        }
        else if(ListCheck[key] === 'checked' && Sim1 !== 0) {
          Sim2 = key
        }
      } )
    }
    if (Sim2 === 0) {
      Sim2 = idExpType
    }
    var result=[Sim1,Sim2]
    return (result)
  }
  function Click2(){
    var nbsimul = NbChecked(ListCheck)
    if(nbsimul === 2){
      setSim1(WhatChecked(ListCheck, idExpType)[0])
      setSim2(WhatChecked(ListCheck, idExpType)[1])
      setGoToComp(true)
    }
    else {
      alert("Vous devez sélectionner 2 simulations")
    }
  }
  function Click2_2050(){
    var nbsimul = NbChecked(ListCheck)
    if(nbsimul === 2){
      setSim1(WhatChecked(ListCheck, idExpType)[0])
      setSim2(WhatChecked(ListCheck, idExpType)[1])
      setGoToComp2050(true)
    }
    else {
      alert("Vous devez sélectionner 2 simulations")
    }
  }
  function ClickExpType(){
    var nbsimul = NbChecked(ListCheck)
    if (idExpType !== undefined) {
      if(nbsimul === 1){
        setSim1(WhatChecked(ListCheck, idExpType)[0])
        setSim2(WhatChecked(ListCheck, idExpType)[1])
        setGoToComp(true)
      }
      else {
        alert("Vous devez sélectionner 1 seule simulation")
      }
    }
    else {
      alert("Vous ne pouvez pas comparer votre exploitation à l'exploitation type car votre région agricole n'en possède pas.")
    }
    
  }
  function handleChangeidExpType1(event){
    var idCom = event.target.value
    setCityET1(idCom)
    setCityET2("")
    fetch(apiLink + 'getExploitationType/' + idCom + '/1')
        .then((response) => {
            return response.json();
        })
        .then((response) => {
          setidExploit(response);
          setidExploit(prev => {
              return prev.idExploitation;
          });
        });
  }
  function handleChangeidExpType2(event){
    var idCom = event.target.value
    setCityET1("")
    setCityET2(idCom)
    fetch(apiLink + 'getExploitationType/' + idCom + '/2')
        .then((response) => {
            return response.json();
        })
        .then((response) => {
          setidExploit(response);
          setidExploit(prev => {
              return prev.idExploitation;
          });
        });
  }
  function showhideExpType(){
    if (BExpType){
      setBExpType(false)
    }
    else{
      setBExpType(true)
    }
  }
  function handleSimulerAssol(){
    if(idExploit!==""){
      setGoToExploitation(true)
    }
    else {
        alert("Sélectionnez l'exploitation type que vous souhaitez")
    }
  }

  const button_page_simulation = (
    <div className='row rowCompSimul'> 
      <button onClick={Click2} className='validation validationCompSimul'> Comparer 2 simulations </button>
      <button onClick={Click2_2050} className='validation validationCompSimul'> Comparer 2 simulations à 2050 </button>
      <button onClick={ClickExpType} className='validation validationCompSimul'> Comparer à l'exploitation moyenne </button>
    </div>
  )
  if(goToHome) {
    return <Navigate push to={`/`} />
  }
  if(goToExploit) {
    return <Navigate push to={`/exploitation`} state={{idExploit}}/>
  }
  if(goToComp) {
    return <Navigate push to={`/comparaisonsimulation`} state={{Sim1, Sim2}} />
  }
  if(goToComp2050) {
    return <Navigate push to={`/comparaisonsimulation2050`} state={{Sim1, Sim2}} />
  }

  return (
    <div>
      <Entete titre="Mes simulations"/>
      <div className="container" >
        {idExploitationPrincipale!==null ? button_page_simulation : ""}
        {idExploitationPrincipale!==null ? table_simul : <LocalisationIns />}
        <div className='titre'>Exploitation type <button className='ButtonHome' onClick={showhideExpType} style={{height:'35px', marginLeft:'0px'}}><img src={BExpType ? fleche_verte : fleche_grise} className="LogoRond"  alt='icone' style={{marginRight:'0px'}}/></button> </div>
        <div className={BExpType? 'ShowResultFlex' : 'HideResult'}>
          <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px',justifyContent:'flex-start'}}> 
            <div className='titreMoins' style={{marginTop:'5px'}}> Grandes cultures </div>
            <select value={CityET1} onChange={handleChangeidExpType1}  style={{ marginBottom:'10px'}}>
              <option value="">Sélectionnez</option>
              {Object.entries(dataCommune).map(([idExploitation, city]) => (
                <option key={idExploitation} value={idExploitation}>
                  {`${idExploitation.length === 4 ? idExploitation.slice(0, 1) : idExploitation.slice(0, 2)} - ${city}`}
                </option>
              ))}
            </select>
            <div className='titreMoins' style={{marginTop:'5px'}}> Mixtes </div>
            <select value={CityET2} onChange={handleChangeidExpType2}  style={{ marginBottom:'10px'}}>
              <option value="">Sélectionnez</option>
              {Object.entries(dataCommune).map(([idExploitation, city]) => (
                <option key={idExploitation} value={idExploitation}>
                  {`${idExploitation.length === 4 ? idExploitation.slice(0, 1) : idExploitation.slice(0, 2)} - ${city}`}
                </option>
              ))}
            </select>
          </div>
          <div className='bottom bottom2' style={{marginBottom:'5px',marginTop:'0px'}}>
            <button onClick={handleSimulerAssol} className='validation'> Calculer </button>
          </div>
        </div>
      </div>
      <ButtonQuestions/>
    </div>
  )
}

export default MesSimulations