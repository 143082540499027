import React from 'react';
import Nav from '../MEP/SWNav';
import FooterSW from '../MEP/footer_SW';
import trans from '../../assets/pexels-johannes-strotker-2037694-4303917.jpg'
import arrupvico from '../../assets/image_projet-ARRUP-VICO.png'
import vigne from '../../assets/Grappe-raisin-pexels-pixabay-39511.jpg'
import colza from '../../assets/GC-colza-pexels-mantas-.jpg'
import artb from '../../assets/Projet-betterave-sans-neonicotinoide.png'
import prot from '../../assets/Projet-proteines.png'
function Projets() {
  return (
    <div>
      <Nav/>
      <div className="containerSW" style={{marginLeft:'3%', marginRight:'3%'}}>
        <h1 className="propositionValeur titreSW">Dérisquer les transitions agroécologiques</h1>
        <div className='principal'>
          <div className='top topBisBis'>
            <img src={trans} alt='Deux champs tranversés par un chemin viscinal'  className='Mains' style={{width:'100%'}}/>
          </div>
          <div className='bottom bottom2 bottom3' style={{marginTop:'0px'}} >
            <div className='paragraphe paragrapheLanding'>DiagoRisk c'est : </div>
            <ul>
              <li> <div className='paragraphe paragrapheLanding lila'>Une plateforme de calcul des potentiels agricoles à 2050. Elle nous permet d’estimer l’évolution des rendements et leurs variabilités pour les vins et les grandes cultures </div></li>
              <li> <div className='paragraphe paragrapheLanding lila'> Un banc d’essai numérique pour mesurer l’efficacité des solutions et répondre à des questions : </div></li>
              <ul>
                <li> <div className='paragraphe paragrapheLanding lila'> Quelles variétés ? Quel assolement ?</div></li>
                <li> <div className='paragraphe paragrapheLanding lila'> Quel ombrage pertinent pour mon installation agrivoltaïque ? </div></li>
                <li> <div className='paragraphe paragrapheLanding lila'> Quels seront les besoins en eau en 2035 ou 2050 ? </div></li>
                <li> <div className='paragraphe paragrapheLanding lila'> ... </div></li>
              </ul>
              <li> <div className='paragraphe paragrapheLanding lila'>Un OAD pour mobiliser </div></li>
            </ul>

            <div className='paragraphe paragrapheLanding'>Nous sommes au cœur de plusieurs projets :  </div>
            <ul>
              <li> <div className='paragraphe paragrapheLanding lila'> Colza Zéro Insecticide et Viticulture à moindre traitements </div></li>
              <li> <div className='paragraphe paragrapheLanding lila'> Betteraves sans néonicotinoïde</div></li>
              <li> <div className='paragraphe paragrapheLanding lila'> Légumineuses</div></li>
            </ul>

            <div className='paragraphe paragrapheLanding'>Ces projets partagent une ambition commune : <span style={{fontWeight:'bold'}}>Dérisquer ces nouvelles pratiques pour accélérer leur adoption à grande échelle. </span></div>
          </div>
        </div>

        <h2 className='sous-Titre-SW'> ARRUP VICO</h2>
        <img src={arrupvico} alt='Image du projet ARRUP VICO avec les logos des partenaires' style={{width:'100%'}}/>
        <div style={{width:'100%'}}>
          <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>“Un nouveau projet de recherche, baptisé ARRUPVICO, démarre sur l’Assurabilité des Risques de perte de Récolte liés à la réduction des Usages Phytosanitaires sur VIgne et COlza. Ces travaux s’inscrivent dans la droite ligne des réflexions ministérielles sur les solutions alternatives pour la protection des cultures. Sur vigne, ils complètent les actions menées dans le cadre de @Vitirev.</div>
          <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}> Les agriculteurs et viticulteurs recherchent en permanence des solutions pour conduire leurs cultures en ayant un recours limité aux produits phytosanitaires. Des itinéraires de culture économes en intrants conduisent à des prises de risque dans la gestion des bioagresseurs. Ces travaux visent à trouver les meilleures conditions pour assurer ce type de risque en proposant aux agriculteurs une garantie couvrant leurs éventuelles pertes de récolte liées au développement incontrôlé d’un ravageur des cultures.</div>
          <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>Pour mener à bien ce projet, 6 acteurs de la recherche et du développement (DiagoRisk IFV, TerresInnovia, Inrae, Université de Bordeaux, ACTA) ont allié leurs forces pour travailler ensemble et affiner les modalités d’une couverture qui sécurise la réduction des intrants. Il sera donc question de modélisation des risques climatiques et épidémiques, d’optimisation des règles de décision pour les traitements et de définition des conditions d’indemnisation. Une large place sera accordée à l’intelligence collective pour trouver des solutions d’assurance compatibles avec la multirisque climatique. Des séminaires d’échanges, ouverts aux autres filières agricoles et aux assureurs, sont d’ores et déjà prévus.”  <a className='a_corps_SW' href='https://umt-seven.hub.inrae.fr/tous-les-projets/arrupvico' target='_blank' rel="noopener noreferrer">UMT Seven </a></div>
          <div className='paragraphe paragrapheLanding'>Replay du séminaire de mars 2024 : <a className='a_corps_SW' href='https://umt-seven.hub.inrae.fr/actualites/2024-videos-du-seminaire-arrupvico' target='_blank' rel="noopener noreferrer">https://umt-seven.hub.inrae.fr/actualites/2024-videos-du-seminaire-arrupvico</a></div>
        </div>

        <div className='principal' style={{marginTop:'30px', alignItems:'flex-start'}}>
          <div className='imageprojetARRUP'>
            <img src={vigne} alt='Grappe de raisin'  className='Mains' style={{width:'100%'}}/>
          </div>
          <div className='texteARRUP'>
            <h3 className='sous-sous-titre-SW'>Garantie Réduction Volontaire des Traitements</h3>
            <ul className='paragraphe paragrapheLanding'>
              <li> <div className='paragraphe paragrapheLanding lila'>L’IFV (Institut Français de la Vigne et du Vin) utilise son OAD DeciTrait pour ptimiser et limiter les traitements contre le mildiou, l’Oïdium et le BlackRot. </div></li>
              <li> <div className='paragraphe paragrapheLanding lila'> Nous modélisons le risque associé en vue de mieux couvrir le risque.</div></li>
            </ul>
          </div>
          <div className='imageprojetARRUP'>
            <img src={colza} alt='Champs de colza'  className='Mains' style={{width:'100%'}}/>
          </div>
          <div className='texteARRUP'>
            <h3 className='sous-sous-titre-SW'>Garantie Colza  Zéro Insecticide</h3>
            <ul className='paragraphe paragrapheLanding'>
              <li> <div className='paragraphe paragrapheLanding lila'>Face aux résistances, comment continuer à faire du colza ? </div></li>
              <li> <div className='paragraphe paragrapheLanding lila'> Quel est le risque supplémentaire associé à un Colza Robuste Zéro insecticide ?</div></li>
              <li> <div className='paragraphe paragrapheLanding lila'> Comment fonctionnerait une garantie pour couvrir le risque de transition ? </div></li>
            </ul>
          </div>
        </div>

        <h2 className='sous-Titre-SW'> Betterave sans néonicotinoïde</h2>
        <div className='principal'>
          <div className='bottom bottom2 bottom3' style={{marginTop:'0px'}} >
            <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>Le retrait de l’AMM (Autorisation de Mise sur le Marché) a entrainé une recrudescence de la Jaunisse, transmise par les pucerons.  En modélisant l’interaction Climat-Puceron-Virus-Betterave, nous avons chiffré les pertes, aujourd’hui et à 2050. </div>
            <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>La phase 2 du projet étudiera l’efficacité des solutions en cours d’essai. </div>
          </div>
          <div className='top topBBis'>
            <img src={artb} alt='Logo ARTB et image de betterave sucrière'  className='Mains Prot' style={{width:'100%'}}/>
          </div>
        </div>

        <h2 className='sous-Titre-SW'> Sécuriser le Plan Protéines</h2>
        <div className='principal'>
          <div className='top topBBis'>
            <img src={prot} alt='Logo CAP protéines et image de lentilles'  className='Mains Prot' style={{width:'100%'}}/>
          </div>
          <div className='bottom bottom2 bottom3' style={{marginTop:'0px'}} >
            <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>Une légumineuse représente un risque entre 3 et 5 fois plus important que le blé. Dès lors, doubler les surfaces de légumineuses, est-ce réaliste ? Et que faire pour que cela le devienne ? 
            </div>
            <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>Nous étudions la sécurisation de l’introduction des légumineuses de la parcelle à la France entière.</div>
          </div>
        </div>
      </div>
      <FooterSW/>
    </div>
  );
}

export default Projets;

