import React from "react";
import MDP from "../../../../../assets/Gestion-utilisateur-MDP.png"

class BtnMDP extends React.Component {
    constructor(props) {
      super(props);
      this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
     this.props.clicked(this.props.value);
    }
    render() {
      return (
        <button className='ButtonGestionUtilisateur BGUGrey' onClick={this.btnClickedHandler}> <img src={MDP} className="IconHome" alt='icone' style={{marginLeft:'-10px'}}/> </button>
      )
    }
  }
export default BtnMDP