import React from 'react';

function BlogContainer({titre, texte1, texte2, texte3, image, date, dispo}) {
  return (
      <div className="blogContainer">        
        <div className={dispo === 1 ? 'rowBlog rowBlog1': 'rowBlog rowBlog2'}>
            <div className='imageDroite' style={{display:dispo === 1 ? "none":"flex"}}>
                <img src={image} alt="Image illustrative du post de blog" className="imageBlog"/>
            </div>
            <div className='texteGauche'>
                <h2 className='titreBlog'>
                    {titre}
                </h2>
                <div>
                    <div className='paragraphe Pgauche'> 
                        {texte1}
                    </div>
                    <div className='paragraphe Pgauche'> 
                        {texte2}
                    </div>
                    <div className='paragraphe Pgauche'> 
                        {texte3}
                    </div>
                </div>
                <div className='dateBlog'> 
                    {date}
                </div>
            </div>
            <div className='imageDroite' style={{display:dispo === 2 ? "none":"flex"}}>
                <img src={image} alt="Image illustrative du post de blog" className="imageBlog"/>
            </div>
        </div>
      </div>
  );
}

export default BlogContainer;