import React, {useEffect} from 'react';
import {useLocation, Navigate} from 'react-router-dom'

import Nav from '../MEP/SWNav';
import EnteteSW from '../MEP/enteteSW';
import FooterSW from '../MEP/footer_SW';
import logoPremium from '../../assets/3-picto-offre-premium.png'

function RecapPrePaiement() {
  const { state } = useLocation();
  const { nbPers } = state;
  const taxe = 1.12
  const [TarifsPremium, setTPrem] = React.useState()
  const [nbPersRecap, setnbPersRecap] = React.useState(nbPers)
  const [goToContact, setGoToContact] = React.useState(false)


  useEffect ( () => {
    if (nbPers==="1"){
      setTPrem(520)
    }
    else if (nbPers==="2"){
      setTPrem(488)
    }
    else if (nbPers==="3"){
      setTPrem(390)
    }
    else if (nbPers==="4"){
      setTPrem(341)
    }
    else if (nbPers==="5"){
      setTPrem(312)
    }
    else if (nbPers==="6"){
      setTPrem(293)
    }
    else if (nbPers==="7"){
      setTPrem(279)
    }
    else {
      setTPrem(268)
    }

  }, [nbPers])


  function handleNBPersChange(event){
    var nbPersRecap = event.target.value
    setnbPersRecap(nbPersRecap)
    if (nbPersRecap==="1"){
      setTPrem(520)
    }
    else if (nbPersRecap==="2"){
      setTPrem(488)
    }
    else if (nbPersRecap==="3"){
      setTPrem(390)
    }
    else if (nbPersRecap==="4"){
      setTPrem(341)
    }
    else if (nbPersRecap==="5"){
      setTPrem(312)
    }
    else if (nbPersRecap==="6"){
      setTPrem(293)
    }
    else if (nbPersRecap==="7"){
      setTPrem(279)
    }
    else {
      setTPrem(268)
    }
  }

  function handlePaiementCarte(event){
    event.preventDefault()
    //'Faire le code pour mener au paiement Strippe'
  }
  function handlePaiementAutre(event){
    event.preventDefault()
    setGoToContact(true)
  }

  if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
  }

  return (
    <div>
      <Nav/>
      <EnteteSW titre="Récapitulatif"/>
      <div className="containerSW" style={{display:'flex', justifyContent:'center'}}>

        <div className='card cardAbo cardAboPremium cardAboPremiumRecap'>
            <div className='row ligneReco'  style={{alignItems:'center', marginBottom:'20px'}}>
              <img src={logoPremium} className="LogoProCardRecap" alt='icone'/>
              <div className='titrePlus'  style={{marginTop:'0px'}}>
                Premium
              </div>
            </div>
            <div className='paragraphe paraCardAbo'> Créer un compte afin de personnaliser mon assolement  </div>
            <div className='paragraphe paraCardAbo'> Estimer les risques de mon exploitation grandes cultures, vignes ou mixte </div>
            <div className='paragraphe paraCardAbo'> Estimer de la résistance de mon assolement face à des chocs plus ou moins importants  </div>
            <div className='paragraphe paraCardAbo'> Mesurer l’intérêt de s’assurer en MultiRisque Climatique sur récoltes  </div>
            <div className='paragraphe paraCardAbo'> Comparer mes simulations et me comparer  aux exploitations de mon territoire   </div>
            <div className='paragraphe paraCardAbo'> Optimiser le ratio coût sécurité de mon assurance Récolte (MRC)  </div>
            <div className='paragraphe paraCardAbo'> Mesurer l’impact d’un changement d’assolement en terme de risques  </div>
            <div className='paragraphe paraCardAbo'> Evaluer mes futurs marges en fonction de l’évolution des charges et des produit de l’exploitation  </div>
            <div className='paragraphe paraCardAbo'> Support technique et d’aide par mail  </div>
            <div className='paragraphe paraCardAbo'><b> Session formation et conseil </b> </div>

            <div className='titreMoins' style={{marginTop:'30px'}}>Montant total :</div>

            <div className='row rowTarifRecap'>
              <div style={{width:'40%'}}>
                <div className='paragraphe' style={{marginTop:'5px'}}>Nombre :</div>
                <select value={nbPersRecap} onChange={handleNBPersChange} className='selectAbo'>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>
              </div>

              <div className='row tarifHTPers'>
                <div style={{marginRight:'10px'}}>
                  <div className='paragraphe paraCardAbo tarifHT'>{TarifsPremium * nbPersRecap} € HT</div>
                  <div className='paragraphe paraCardAbo tarifparpers' >{TarifsPremium} €/personne</div>
                </div>
                <div>
                  <div className='paragraphe paraCardAbo tarifHT'>{Math.round(TarifsPremium * nbPersRecap * taxe*100)/100} € HT</div>
                  <div className='paragraphe paraCardAbo tarifparpers' >{Math.round(TarifsPremium *taxe*100)/100} €/personne</div>
                </div>
              </div>

              <div className='boutonsRecap'>
                <div className='row boutonRecap'><button className='validation validationAbo' style={{backgroundColor:'#75B532'}} onClick={handlePaiementCarte}>Payer par carte bancaire</button></div>
                <div className='row boutonRecap'><button className='validation validationAbo' style={{backgroundColor:'#A0BA84'}} onClick={handlePaiementAutre}>
                  <div>Payer par un autre moyen</div>
                  <div>Nous contacter</div>
                </button></div>
              </div>
            </div>

          </div>
        
      </div>
      <FooterSW/>
    </div>
  );
}

export default RecapPrePaiement;

