import React from 'react';
import Nav from '../MEP/SWNav';
import FooterSW from '../MEP/footer_SW';

function MentionsLegales() {
  return (
    <div className="paragraphe">
      <Nav/>
      <div className="containerSW" >
        <h1 className="propositionValeur titreSW">Mentions légales</h1>
        <div className='titre'> Editeur </div>
        <div className="paragraphe">Ce site est la propriété de : </div>
        <div className="paragraphe">RisquesAgricoles </div>
        <div className="paragraphe">25 rue Pétrelle, 75009 Paris</div>
        <div className="paragraphe"> SAS au capital de 20 200 € - RCS Paris 890 880 289</div>
        <div className="paragraphe">Téléphone : <a className='a_corps_SW'  href='tel:+33(0)1.55.32.36.85'> 01 55 32 36 85 </a> / Mail: <a className='a_corps_SW'  href='mailto:luc.boucher@risquesagricoles.com'>luc.boucher@risquesagricoles.com</a></div>
        <div className="paragraphe">Directeur de la publication : Luc Boucher</div>

        <div className='titre'>Hébergeur</div>
        <div className="paragraphe">Ce site est hébergé au près de : </div>
        <div className="paragraphe">OVH </div>
        <div className="paragraphe"> 2 rue Kellermann – 59100 Roubaix – France</div>
        <div className="paragraphe">SAS au capital de 10 069 020 € - RCS Lille Métropole 424 761 419 00045</div>
        

        <div className='titre'>Cookies</div>
        <div className="paragraphe"> A l'occasion de votre navigation sur notre site, des cookies sont déposés sur le navigateur de votre terminal (ordinateur, mobile ou tablette).</div>

        <div className='titreMoins'>Qu'est-ce qu'un cookie ?</div>
        <div className="paragraphe">Les cookies sont des petits fichiers textes déposés sur votre terminal et qui permettent de collecter des informations générées par un site internet. Les cookies permettent notamment d’enregistrer la configuration et les préférences d’affichage, d’analyser le comportement de l’internaute lors de sa navigation, etc.</div>
        <div className="paragraphe">Les finalités et modalités des cookies déposés sur le site sont détaillées ci-dessous.</div>
        <div className="paragraphe">Les cookies sont sauvegardés par votre navigateur internet et seul l’émetteur d’un cookie est susceptible de lire ou de modifier les informations qui y sont contenues.</div>
        <div className="paragraphe">Un cookie ne peut être utilisé que par le serveur web qui vous l'a enregistré, il ne contient aucun programme exécutable et est donc exempt de virus.</div>
        <div className="paragraphe">Certains cookies nécessitent un consentement de votre part, on peut notamment citer :</div>
        <ul>
          <li> <div className='paragraphe lila'>Les cookies de mesure d'audience,</div> </li>
          <li> <div className='paragraphe lila'>Les cookies liés aux opérations relatives à la publicité ciblée,</div> </li>
          <li> <div className='paragraphe lila'>Les cookies de partage (réseaux sociaux).</div> </li>
        </ul>
        <div className="paragraphe">Quand vous naviguez sur notre site, vous consentez à l'installation des cookies sur votre navigateur.</div>
        <div className="paragraphe">Vous pouvez également refuser l'installation de ces cookies, en sélectionnant la configuration adéquate dans les paramètres ou options de votre navigateur, en suivant les liens ci-dessous « Comment gérer vos cookies ? ».</div> 
        <div className="paragraphe">Les cookies dits de session sont conservés le temps de votre navigation et sont supprimés lorsque vous refermez votre navigateur.</div>
        <div className="paragraphe">Les cookies à durée de vie longue sont conservés d’une session à l’autre pour une durée déterminée au moment de leur dépôt. Certains de ces cookies sont accédés par des tiers pour des finalités qui sont décrites ci-dessous.</div>

        <div className='titreMoins'>Quels types de cookies sont déposés et pour quelles fins ?</div>
        <ul>
          <li> <div className='paragraphe lila'> <b>Les cookies de fonctionnement essentiels :</b>
          <div className="paragraphe">Il s'agit des cookies exclusivement déposés par le site, indispensables à la navigation sur notre site et qui vous permettent d'utiliser les principales fonctionnalités du site et de sécuriser votre navigation. Le site ne peut pas fonctionner correctement sans ces cookies. L’installation de ces cookies ne nécessite en conséquence pas votre consentement.
          Ces cookies permettent par exemple de :</div>
          <ul>
            <li> <div className='paragraphe lila'>Mémoriser des données qui permettent de fluidifier votre navigation lorsque vous êtes connecté à votre espace personnel,</div> </li>
            <li> <div className='paragraphe lila'>Mettre en œuvre des mesures de sécurité, comme la déconnexion automatique après un certain temps sans activité,</div> </li>
            <li> <div className='paragraphe lila'>Lutter contre les cyber-attaques, en réalisant des blocages sélectifs.</div> </li>
          </ul>  
          <div className="paragraphe">Pour bénéficier de l'ensemble des fonctionnalités du site, il est conseillé de ne pas refuser le dépôt des cookies de fonctionnement.</div>
          <div className="paragraphe">Ces cookies sont déposés pour une durée maximum de 12 mois.</div></div> </li>
          <li> <div className='paragraphe lila'> <b>Les cookies de mesure d’audience :</b>
          <div className="paragraphe">Ces cookies ne sont pas strictement indispensables à votre navigation sur le site. Ces cookies permettent d’obtenir des statistiques de fréquentation anonymes du site afin d’optimiser son ergonomie, sa navigation et ses contenus. L’installation de ces cookies ne nécessite pas votre consentement.</div>
          <div className="paragraphe">Nous utilisons également, afin d’évaluer les services qui sont rendus sur le site, des outils, tels que Google Analytics, effectuant un suivi complet de votre navigation sur le site, nous permettant de connaître votre comportement lors de votre navigation. Pour désactiver les cookies de mesure d’audience déposés par Google Analytics, rendez vous sur le <a className='a_corps_SW' href='https://tools.google.com/dlpage/gaoptout?hl=fr'>module de désactivation de Google Analytics.</a> </div></div> </li>
          <li> <div className='paragraphe lila'> <b>Les cookies publicitaires et de personnalisation :</b>
          <div className="paragraphe">Ces cookies sont utilisés pour suivre la performance des campagnes marketing que nous engageons et vous proposer des contenus personnalisés.</div>
          <div className="paragraphe">Le refus de ces cookies n'a pas d'impact sur l'utilisation de notre site. Ces cookies nous permettent de vous présenter, au travers de nos différents partenaires, les publicités les plus pertinentes et les plus adaptées à vos centres d'intérêt en fonction de vos habitudes de navigation. En désactivant ces cookies, vous ne bénéficierez plus de cette personnalisation.</div>
          <div className="paragraphe">Pour désactiver les cookies publicitaires, vous pouvez paramétrer votre navigateur en suivant les liens ci-dessous « Comment gérer vos cookies ? »</div></div> </li>
          <li> <div className='paragraphe lila'><b>Les cookies de partage (réseaux sociaux) :</b>
          <div className="paragraphe">Ces boutons applicatifs permettent d’interagir depuis le site avec les réseaux sociaux en en partageant les contenus avec d’autres personnes ou de les informer de votre consultation ou opinion sur ceux-ci, lorsque vous cliquez sur les modules "Partager", "Aimer" de Facebook et de Twitter, par exemple. En désactivant ces cookies, vous ne pourrez plus partager les contenus du site sur les réseaux sociaux.</div></div> </li>
        </ul>
        <div className="paragraphe">Si vous êtes connecté à l’un de ces réseaux sociaux lors de votre navigation sur notre site, les boutons applicatifs de partage permettent de relier les contenus consultés à votre compte utilisateur sur le réseau social. Si vous ne souhaitez pas que le réseau social relie les informations collectées par l’intermédiaire de notre site à votre compte utilisateur, vous devez auparavant vous déconnecter du réseau social.</div>
        <div className="paragraphe">Nous vous invitons à consulter les politiques de protection de la vie privée de ces réseaux sociaux afin de prendre connaissance des finalités d'utilisation, notamment publicitaires, des informations de navigation qu'ils peuvent recueillir grâce à ces boutons applicatifs.</div>
        <div className="paragraphe">Vous pouvez refuser les cookies déposés par les réseaux sociaux par un paramétrage de votre navigateur en suivant les liens ci-dessous « Comment gérer vos cookies ? »</div>

        <div className='titreMoins'>Comment gérer vos cookies ?</div>
        <div className="paragraphe">Vous pouvez faire le choix à tout moment d'exprimer et de modifier vos souhaits en matière de cookies, par les moyens décrits ci-dessous.</div>
        <div className="paragraphe">Quand vous naviguez sur le site, vous consentez à l'installation des cookies sur votre navigateur. Vous pouvez refuser l'installation de ces cookies, en sélectionnant la configuration adéquate dans les paramètres ou options de votre navigateur.</div>
        <div className="paragraphe">Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits. Voici les liens pour chaque navigateur:</div>
        <ul>
            <li><a className='paragraphe a_corps_SW ' href='https://www.flaticon.com/https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies'>Internet Explorer™</a></li>
            <li><a className='paragraphe a_corps_SW' href='https://support.apple.com/fr-fr/guide/safari/sfri11471/mac'>Safari™</a></li>
            <li><a className='paragraphe a_corps_SW' href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=fr'>Chrome™</a></li>
            <li><a className='paragraphe a_corps_SW' href='https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites'>Firefox™</a></li>
            <li><a className='paragraphe a_corps_SW' href='https://www.accepterlescookies.com/comment-accepter-les-cookies-sur-opera.html'>Opera™</a> </li>
        </ul>
        <div className='titre'>Crédits images</div>
        <div className="paragraphe"><a className='a_corps_SW' href='https://www.freepik.com'>https://www.freepik.com/</a></div>
        <div className="paragraphe"><a className='a_corps_SW' href='https://www.pexels.com/fr-fr/'>https://www.pexels.com/fr-fr/</a></div>
        <div className="paragraphe"><a className='a_corps_SW' href='https://www.flaticon.com/'>https://www.flaticon.com/</a></div>
        <div className="paragraphe"><a className='a_corps_SW' href='https://lordicon.com'>https://lordicon.com/</a></div>
      </div>
      <FooterSW/>
    </div>
  );
}

export default MentionsLegales;
