import React, {useRef, useEffect} from 'react'
import {useLocation, Navigate} from 'react-router-dom'
import apiLink from '../../config'

import loading_Data from '../../assets/loading-Data.gif'
import GraphAssolement3 from './Graphiques/assolement_graph_3'
import GraphDifference from './Graphiques/graphDifference'
import GraphRiskMoy from './Graphiques/graphRisques_Moy'
import Entete from '../MEP/entete' 
import OADMenu from '../MEP/oadMenu'
import ButtonQuestions from '../MEP/button question'

function Resultats3050() {
    const { state } = useLocation();
    const { idExploit } = state;
    const [CityName, setCityName] = React.useState("")
    const [libellPRA, setLibellePRA] = React.useState("")
    const [goToHome, setGoToHome] = React.useState(false)

    const [dataSurf, setDataSurf] = React.useState()
    const [dataPB, setDataPb] = React.useState()
    const [R2020_2degre, setR2020_2degre] = React.useState()
    const [R2025_2degre, setR2025_2degre] = React.useState()
    const [R2030_2degre, setR2030_2degre] = React.useState()
    const [R2035_2degre, setR2035_2degre] = React.useState()
    const [R2040_2degre, setR2040_2degre] = React.useState()
    const [R2045_2degre, setR2045_2degre] = React.useState()
    const [R2050_2degre, setR2050_2degre] = React.useState()
    const [Moy_2degre, setRMoy_2degre] = React.useState()
    const [R2020_4degre, setR2020_4degre] = React.useState()
    const [R2025_4degre, setR2025_4degre] = React.useState()
    const [R2030_4degre, setR2030_4degre] = React.useState()
    const [R2035_4degre, setR2035_4degre] = React.useState()
    const [R2040_4degre, setR2040_4degre] = React.useState()
    const [R2045_4degre, setR2045_4degre] = React.useState()
    const [R2050_4degre, setR2050_4degre] = React.useState()
    const [Moy_4degre, setRMoy_4degre] = React.useState()
    const [PB, setPB] = React.useState(0)
    const [surf, setSurf] = React.useState(0)

    const[annee, setAnnee] = React.useState('2050')
    const[anneeComp, setAnneeComp] = React.useState(2050)
    const [R2020, setRdata2020] = React.useState()
    const [R2025, setRdata2025] = React.useState()
    const [R2030, setRdata2030] = React.useState()
    const [R2035, setRdata2035] = React.useState()
    const [R2040, setRdata2040] = React.useState()
    const [R2045, setRdata2045] = React.useState()
    const [R2050, setRdata2050] = React.useState()
    const [Moy, setRMoy] = React.useState()

    const [data2, setData2] = React.useState()
    const [data2020SimFerme, setData2020SimFerme] = React.useState()
    const [dataGraph2020SimFerme, setDataGraph2020SimFerme] = React.useState()
    const [DataRisquesMoySF, setDataRisquesMoySF] = React.useState()
    const [DataRisquesMoySFGraph, setDataRisquesMoySFGraph] = React.useState()

    const [B2d, setB2d]= React.useState('Checked')
    const [B4d, setB4d]= React.useState('UnChecked')
    

    function fetchData() {
      fetch('https://api2050.diagorisk.com/resultatExploitation/?idExploit=' + idExploit)
        .then((response) => response.json())
        .then((response) => {
          if ('error' in response) {
            // Si la réponse contient une erreur, relancer la requête après 60 secondes
            setTimeout(fetchData, 60000);
          } else {
            // Si la réponse est réussie, mettre à jour les données
            setDataSurf(response.dataSurface)
            setDataSurf(prev => {
              setSurf(calculateSum(prev))
              return prev;
            })
            setDataPb(response.dataPB)
            setDataPb(prev => {
              setPB(calculateSum(prev))
              return prev;
            })
            setR2020_2degre(response.R2020_2degre)
            setR2020_2degre(prev => {
              setRdata2020(prev)
              return prev;
            })
            setR2025_2degre(response.R2025_2degre)
            setR2025_2degre(prev => {
              setRdata2025(prev)
              return prev;
            })
            setR2030_2degre(response.R2030_2degre)
            setR2030_2degre(prev => {
              setRdata2030(prev)
              return prev;
            })
            setR2035_2degre(response.R2035_2degre)
            setR2035_2degre(prev => {
              setRdata2035(prev)
              return prev;
            })
            setR2040_2degre(response.R2040_2degre)
            setR2040_2degre(prev => {
              setRdata2040(prev)
              return prev;
            })
            setR2045_2degre(response.R2045_2degre)
            setR2045_2degre(prev => {
              setRdata2045(prev)
              return prev;
            })
            setR2050_2degre(response.R2050_2degre)
            setR2050_2degre(prev => {
              setRdata2050(prev)
              setData2(prev)
              return prev;
            })
            setRMoy_2degre(response.RMoy_2degre)
            setRMoy_2degre(prev => {
              setRMoy(prev)
              return prev;
            })
            setR2020_4degre(response.R2020_4degre)
            setR2020_4degre(prev => {
              return prev;
            })
            setR2025_4degre(response.R2025_4degre)
            setR2025_4degre(prev => {
              return prev;
            })
            setR2030_4degre(response.R2030_4degre)
            setR2030_4degre(prev => {
              return prev;
            })
            setR2035_4degre(response.R2035_4degre)
            setR2035_4degre(prev => {
              return prev;
            })
            setR2040_4degre(response.R2040_4degre)
            setR2040_4degre(prev => {
              return prev;
            })
            setR2045_4degre(response.R2045_4degre)
            setR2045_4degre(prev => {
              return prev;
            })
            setR2050_4degre(response.R2050_4degre)
            setR2050_4degre(prev => {
              return prev;
            })
            setRMoy_4degre(response.RMoy_4degre)
            setRMoy_4degre(prev => {
              return prev;
            })
            let risquemoy = response.RMoy_4degre[0]
            fetch(apiLink + 'page/optimisation/' + idExploit)
            .then( (response) => {
              return response.json()
            })
            .then(response =>{
                setDataRisquesMoySF([(response.dataGraphCN['Sans Assurance'][1]*100-risquemoy).toFixed(2),0,0,0,0,0,0])
                setDataRisquesMoySF(prev => {
                return prev;
                })
            }) 
          }
        })
        .catch((error) => {
          // Gérer les erreurs de la requête
          console.error('Erreur lors de la requête API :', error);
        });
    }

    useEffect ( () => {
      fetch(apiLink + 'page/optimisation/' + idExploit)
      .then( (response) => {
        return response.json()
      })
      .then(response =>{
        if (response !== "Exploitaiton " + idExploit + " non trouvée") {
          setData2020SimFerme([, response.risques7,response.risques20,response.risques50])
          setData2020SimFerme(prev => {
          return prev;
          })
        } else {
          localStorage.clear()
          setGoToHome(true)
        }
      }) 
      fetchData()
      fetch(apiLink + 'getLocalisation/' + idExploit)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
            var city =  response.idCommune
            fetch(apiLink + 'getCommune/' + city )
            .then( (response) => {
            return response.json()
            })
            .then(response =>{
            setLibellePRA(response.libellePetiteRegionAgricole)
            setLibellePRA(prev => {
                return prev;
            })
            var cp = response.codePostal
            var libellecity = response.libelleCommune
            setCityName(cp + ' - ' + libellecity)
            setCityName(prev => {
                return prev;
            })
            })
        })
      
    }, [idExploit])

    const calculateSum = (dataSurface) => {
      if (dataSurface) {
        return dataSurface.reduce((sum, item) => sum + item.value, 0);
      }
      return 0;
    };

    function handle2degre(){
      if (annee==="2025"){
        setData2(R2025_2degre)
      }
      else if (annee==="2030"){
        setData2(R2030_2degre)
      }
      else if (annee==="2035"){
        setData2(R2035_2degre)
      }
      else if (annee==="2040"){
        setData2(R2040_2degre)
      }
      else if (annee==="2045"){
        setData2(R2045_2degre)
      }
      else {
        setData2(R2050_2degre)
      }
      setRdata2020(R2020_2degre)
      setRdata2025(R2025_2degre)
      setRdata2030(R2030_2degre)
      setRdata2035(R2035_2degre)
      setRdata2040(R2040_2degre)
      setRdata2045(R2045_2degre)
      setRdata2050(R2050_2degre)
      setRMoy(Moy_2degre)
      setB2d('Checked')
      setB4d('UnChecked')
    }
    function handle4degre(){
      if (annee==="2025"){
        setData2(R2025_4degre)
      }
      else if (annee==="2030"){
        setData2(R2030_4degre)
      }
      else if (annee==="2035"){
        setData2(R2035_4degre)
      }
      else if (annee==="2040"){
        setData2(R2040_4degre)
      }
      else if (annee==="2045"){
        setData2(R2045_4degre)
      }
      else {
        setData2(R2050_4degre)
      }
      setRdata2020(R2020_4degre)
      setRdata2025(R2025_4degre)
      setRdata2030(R2030_4degre)
      setRdata2035(R2035_4degre)
      setRdata2040(R2040_4degre)
      setRdata2045(R2045_4degre)
      setRdata2050(R2050_4degre)
      setRMoy(Moy_4degre)
      setB2d('UnChecked')
      setB4d('Checked')
    }
    function handleChangeAnneeComp(event){
      var anneeC = parseInt(event.target.value, 10);
      setAnneeComp(anneeC)
      if (anneeC === 2025 ){
        setAnnee('2025')
        if(B2d==="Checked"){
          setData2(R2025_2degre)
        }
        else {
          setData2(R2025_4degre)
        }
      }
      else if (anneeC === 2030 ){
        setAnnee('2030')
        if(B2d==="Checked"){
          setData2(R2030_2degre)
        }
        else {
          setData2(R2030_4degre)
        }
      } 
      else if (anneeC === 2035 ){
        setAnnee('2035')
        if(B2d==="Checked"){
          setData2(R2035_2degre)
        }
        else {
          setData2(R2035_4degre)
        }
      } 
      else if (anneeC === 2040 ){
        setAnnee('2040')
        if(B2d==="Checked"){
          setData2(R2040_2degre)
        }
        else {
          setData2(R2040_4degre)
        }
      } 
      else if (anneeC === 2045 ){
        setAnnee('2045')
        if(B2d==="Checked"){
          setData2(R2045_2degre)
        }
        else {
          setData2(R2045_4degre)
        }
      } 
      else {
        setAnnee('2050')
        if(B2d==="Checked"){
          setData2(R2050_2degre)
        }
        else {
          setData2(R2050_4degre)
        }
      }
    }
    function handleShowHide(){
      if(dataGraph2020SimFerme===undefined){
          setDataGraph2020SimFerme(data2020SimFerme)
          setDataRisquesMoySFGraph(DataRisquesMoySF)
      }
      else{
          setDataGraph2020SimFerme()
          setDataRisquesMoySFGraph()
      }
    }
    if(goToHome) {
      return <Navigate push to={`/`} />
    }

    return (
        <div>
            <Entete titre="Comparaison 2020 avec 2030/2050"/>
            <div className='container'>
            <div className='paragraphe'> Les grandes cultures n'ayant pas de date de semis ou pour la viticulture une date moyenne de débourrement sur les graphiques suivant sont les productions qui ne sont pas encore simulées par notre outil.</div>
            {dataSurf!== undefined && dataPB!== undefined  ? (
              <div className='dispLigne'>
                <div className='column dispLeft'>
                    <div className='titrePlus'> Surface <span style={{fontWeight:'normal', fontSize:'70%'}}> ({surf !== undefined ? surf.toFixed(2) : ""} hectares)</span> </div>
                    <GraphAssolement3 myData={dataSurf} PB={""} print={false}/>
                </div>

                <div className='column dispRight'>
                    <div className='titrePlus'> Produit brut <span style={{fontWeight:'normal', fontSize:'70%'}}> ({ PB !== undefined ?PB.toFixed(0).toLocaleString() : ""} euros) </span> </div>
                    <GraphAssolement3 myData={dataPB} PB={""} print={false}/>
                </div>
              </div> 
            ) : (
              <div className='dispLigne'>
                <div className='column dispLeft'>
                    <div className='titrePlus'> Surface <span style={{fontWeight:'normal', fontSize:'70%'}}> ({surf !== undefined ? surf.toFixed(2) : ""} hectares)</span> </div>
                    <img src={loading_Data} alt="image de chargement" className=""/>
                </div>

                <div className='column dispRight'>
                    <div className='titrePlus'> Produit brut <span style={{fontWeight:'normal', fontSize:'70%'}}> ({ PB !== undefined ?PB.toFixed(0).toLocaleString() : ""} euros) </span> </div>
                    <img src={loading_Data} alt="image de chargement" className=""/>
                </div>
              </div> 
            )}

                <div className='row flexLigne espaceprint' style={{alignItems:'flex-start',justifyContent:'flex-start'}}>
                <div className='titre'>Evolution du risque entre 2020 et {anneeComp}</div> 
                <div style={{width:'30%', marginLeft:'30px', display:'flex', flexDirection:'column'}}> 
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div className='paragraphe'>2025</div>
                    <div className='paragraphe'>2050</div>
                    </div>
                    <input style={{margin:'0px', height:'35px', width:'100%'}} type="range" id="cowbell" name="cowbell" min={2025} max={2050} value={anneeComp} step="5" onChange={handleChangeAnneeComp}/>
                </div>
                
                </div>

                <div className='row' style={{justifyContent:'flex-start'}}>
                <button onClick={handle2degre} className={B2d==='UnChecked' ? 'buttonResi buttonEntite buttonResiDefault' : 'buttonResi buttonEntite button2050degreChecked'}> +2°C </button>
                <button onClick={handle4degre} className={B4d==='UnChecked' ? 'buttonResi buttonEntite buttonResiDefault' : 'buttonResi buttonEntite button2050degreChecked'}> +4°C </button>
                </div>

                <div className='dispLigne'>
                {R2020!== undefined && R2030!== undefined && R2040!== undefined && R2050!== undefined && Moy!== undefined ? (
                  <div className='column dispLeft dispLeft2050'>
                    <GraphDifference myData2020={R2020} myData2={data2} annee={annee} dataGraph2020SimFerme={dataGraph2020SimFerme}/> 
                    <GraphRiskMoy Data2D={Moy_2degre} Data4D={Moy_4degre} DataSF={DataRisquesMoySFGraph} />
                  </div>
                ) : (
                <div className='column dispLeft dispLeft2050'>
                    <img src={loading_Data} alt="image de chargement" className=""/>
                    <img src={loading_Data} alt="image de chargement" className=""/>
                </div> )}
                

                {R2020!== undefined && R2030!== undefined && R2040!== undefined && R2050!== undefined && Moy!== undefined ? (
                  
                <div className='column dispRight dispRight2050'>
                  <div> 
                    <div className='titre' style={{textAlign:'center'}}> Evolution du coût du risque en % de produit brut en point </div>
                    <div className='paragraphe' style={{textAlign:'center'}}> Commune : <span style={{fontWeight:'bold'}}>{CityName}</span></div>
                    <div className='paragraphe' style={{textAlign:'center'}}> Petite région agricole : <span style={{fontWeight:'bold'}}>{libellPRA}</span></div>
                  </div>
                  <div className='tableau_prix_rapport' style={{width:'100%'}}> 
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}>Par rapport à 2020 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'#7030A2', height:'60px', textAlign:'center'}}> Très courant <br/>(3 ans)</div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'#FABE06', height:'60px', textAlign:'center'}}> Courant <br/>(7 ans) </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050'style={{backgroundColor:'#0027D6', height:'60px', textAlign:'center'}}> Exceptionnel <br/>(20 ans)</div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'#DB04B9', height:'60px', textAlign:'center'}}> Catastrophique <br/>(50 ans) </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'white', color:'black', height:'60px', textAlign:'center'}}> Moyen</div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2025 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2025[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2025[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2025[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2025[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[1]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2030 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2030[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2030[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2030[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2030[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[2]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2035 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2035[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2035[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2035[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2035[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[3]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2040 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2040[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2040[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2040[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2040[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Moy[4]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2045 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2045[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2045[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2045[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2045[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[5]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2050 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2050[0]- R2020[0]).toFixed(1).toLocaleString()}% </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2050[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2050[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {(R2050[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Moy[6]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                  </div>

                  <div className='titre espaceprint'>Evolution du coût du risque en euros </div> 
                  <div className='tableau_prix_rapport' style={{width:'100%'}}> 
                  <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}>Par rapport à 2020 </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'#7030A2', height:'60px', textAlign:'center'}}> Très courant <br/>(3 ans)</div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'#FABE06', height:'60px', textAlign:'center'}}> Courant <br/>(7 ans) </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050'style={{backgroundColor:'#0027D6', height:'60px', textAlign:'center'}}> Exceptionnel <br/>(20 ans)</div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'#DB04B9', height:'60px', textAlign:'center'}}> Catastrophique <br/>(50 ans) </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport abscisse_tab_2050' style={{backgroundColor:'white', color:'black', height:'60px', textAlign:'center'}}> Moyen</div>
                  </div>
                  <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2025 </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2025[0] * PB / 100 - R2020[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2025[1] * PB / 100 - R2020[1] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2025[2] * PB / 100 - R2020[2] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2025[3] * PB / 100 - R2020[3] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> {Number(Math.round(Moy[1] * PB / 100 - Moy[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                  </div>
                  <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2030 </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2030[0] * PB / 100 - R2020[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2030[1] * PB / 100 - R2020[1] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2030[2] * PB / 100 - R2020[2] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2030[3] * PB / 100 - R2020[3] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> {Number(Math.round(Moy[2] * PB / 100 - Moy[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                  </div>
                  <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2035 </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2035[0] * PB / 100 - R2020[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2035[1] * PB / 100 - R2020[1] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2035[2] * PB / 100 - R2020[2] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2035[3] * PB / 100 - R2020[3] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> {Number(Math.round(Moy[3] * PB / 100 - Moy[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                  </div>
                  <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2040 </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2040[0] * PB / 100 - R2020[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2040[1] * PB / 100 - R2020[1] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2040[2] * PB / 100 - R2020[2] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2040[3] * PB / 100 - R2020[3] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> {Number(Math.round(Moy[4] * PB / 100 - Moy[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                  </div>
                  <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2045 </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2045[0] * PB / 100 - R2020[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2045[1] * PB / 100 - R2020[1] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2045[2] * PB / 100 - R2020[2] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2045[3] * PB / 100 - R2020[3] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> {Number(Math.round(Moy[5] * PB / 100 - Moy[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                  </div>
                  <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2050 </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2050[0] * PB / 100 - R2020[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2050[1] * PB / 100 - R2020[1] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2050[2] * PB / 100 - R2020[2] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tab_2050'> {Number(Math.round(R2050[3] * PB / 100 - R2020[3] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                    <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> {Number(Math.round(Moy[6] * PB / 100 - Moy[0] * PB / 100).toFixed(0)).toLocaleString()} € </div>
                  </div>
                </div>

                </div>
                ) : (
                  <div className='column dispRight dispRight2050'>
                    <img src={loading_Data} alt="image de chargement" className=""/>
                  </div>
                )}
                </div>
                <button className='ButtonHome' onClick={handleShowHide} style={{fontSize:'20px'}}>+</button>
            </div>
            <ButtonQuestions/>
            <OADMenu idExploit={idExploit}/>
        </div>
    )
}

export default Resultats3050