import React from 'react'
import {Navigate} from 'react-router-dom'
import apiLink from '../../config'

import Entete from '../MEP/entete'
import GraphDifference from '../OAD/Graphiques/graphDifference.js';
import GraphRiskMoy from '../OAD/Graphiques/graphRisques_Moy.js';

function UneCulture() {
  const [CP, setCP]= React.useState('');
  const [PBCp, setPBCp]= React.useState(false);
  const [city, setCity]= React.useState("");
  const [ListVille, setList]= React.useState();
  const [culture, setCulture] = React.useState("")
  const [Precocite, setPrecocite] = React.useState("")
  const [semis, setSemis] = React.useState("")
  const [aleas, setAleas] = React.useState("")
  const [dataListeSemis, setDataListeSemis] = React.useState([])
  const [goToHome, setGoToHome] = React.useState(false)
  const CPVierzon = [{'value': "02120", "libelle": "02120"}, {'value': "10170", "libelle": "10170"}, {'value': "11400", "libelle": "11400"},{'value': "17170", "libelle": "17170"},{'value': "31320", "libelle": "31320"},{'value': "36150", "libelle": "36150"},{'value': "36260", "libelle": "36260"},{'value': "51230", "libelle": "51230"},{'value': "52110", "libelle": "52110"},{'value': "67150", "libelle": "67150"},{'value': "78850", "libelle": "78850"}]

  const [ShowResult, setShowResult]= React.useState(false);

  const [R2020_2degre, setR2020_2degre] = React.useState()
  const [R2025_2degre, setR2025_2degre] = React.useState()
  const [R2030_2degre, setR2030_2degre] = React.useState()
  const [R2035_2degre, setR2035_2degre] = React.useState()
  const [R2040_2degre, setR2040_2degre] = React.useState()
  const [R2045_2degre, setR2045_2degre] = React.useState()
  const [R2050_2degre, setR2050_2degre] = React.useState()
  const [Moy_2degre, setRMoy_2degre] = React.useState()
  const [R2020_4degre, setR2020_4degre] = React.useState()
  const [R2025_4degre, setR2025_4degre] = React.useState()
  const [R2030_4degre, setR2030_4degre] = React.useState()
  const [R2035_4degre, setR2035_4degre] = React.useState()
  const [R2040_4degre, setR2040_4degre] = React.useState()
  const [R2045_4degre, setR2045_4degre] = React.useState()
  const [R2050_4degre, setR2050_4degre] = React.useState()
  const [Moy_4degre, setRMoy_4degre] = React.useState()
  const [PB, setPB] = React.useState(0)
  const [surf, setSurf] = React.useState(0)

  const[annee, setAnnee] = React.useState('2050')
  const[anneeComp, setAnneeComp] = React.useState(2050)
  const [R2020, setRdata2020] = React.useState()
  const [R2025, setRdata2025] = React.useState()
  const [R2030, setRdata2030] = React.useState()
  const [R2035, setRdata2035] = React.useState()
  const [R2040, setRdata2040] = React.useState()
  const [R2045, setRdata2045] = React.useState()
  const [R2050, setRdata2050] = React.useState()
  const [Moy, setRMoy] = React.useState()

  const [data2, setData2] = React.useState()

  const [B2d, setB2d]= React.useState('Checked')
  const [B4d, setB4d]= React.useState('UnChecked')

  function handleScrollSelect(event){
    var city = event.target.value
    setCity(city)
  }
  function handleChange(event) {
    var CP = event.target.value
    setCP(CP)   
    fetch(apiLink + 'getCommunes/'+ CP)
      .then( (response) => {
        return response.json()
      })
      .then(response =>{
        setList(response.data)
        setList(prev => {
          prev = prev || []
          if (prev.length === 0 && CP.length>4){
            setPBCp(true)
          }
          else {
            setPBCp(false)
          }
          return prev;
        })
      }) 
  }
  function handleChangePrecocite(event){
    var precocite = event.target.value
    setPrecocite(precocite)
  }
  function handleChangeSemis(event){
      var semis = event.target.value
      setSemis(semis)
  }
  function handleChangeCUlture(event){
    var cult = event.target.value
    setCulture(cult)
    if (cult === "1123" || cult === "1113" || cult === "115") {
      setDataListeSemis([{'value': "25.10", "libelle": "25 octobre"}, {'value': "30.10", "libelle": "30 octobre"},{'value': "05.11", "libelle": "5 novembre"}, {'value': "10.11", "libelle": "10 novembre"}, {'value': "15.11", "libelle": "15 novembre"}, {'value': "20.11", "libelle": "20 novembre"},  {'value': "25.11", "libelle": "25 novembre"}])
    }
    else if (cult === "1122" || cult === "1112" || cult === "114") {
      setDataListeSemis([{'value': "15.02", "libelle": "15 février"}, {'value': "20.02", "libelle": "20 février"}, {'value': "25.02", "libelle": "25 février"},{'value': "05.03", "libelle": "5 mars"}, {'value': "10.03", "libelle": "10 mars"},{'value': "15.03", "libelle": "15 mars"} ])
    }
    else if (cult === "118") {
      setDataListeSemis([{'value': "15.03", "libelle": "15 mars"}, {'value': "20.03", "libelle": "20 mars"}, {'value': "25.03", "libelle": "25 mars"}, {'value': "30.03", "libelle": "30 mars"}, {'value': "05.04", "libelle": "5 avril"}, {'value': "10.04", "libelle": "10 avril"}, {'value': "15.04", "libelle": "15 avril"}, {'value': "20.04", "libelle": "20 avril"}, {'value': "25.04", "libelle": "25 avril"}, {'value': "30.04", "libelle": "30 avril"}, {'value': "05.05", "libelle": "5 mai"}, {'value': "10.05", "libelle": "10 mai"}, {'value': "15.05", "libelle": "15 mai"}, {'value': "20.05", "libelle": "20 mai"}, {'value': "25.05", "libelle": "25 mai"}, {'value': "30.05", "libelle": "30 mai"}])
    }
    else if (cult === "222") {
      setDataListeSemis([{'value': "15/08", "libelle": "15 août"}, {'value': "20/08", "libelle": "20 août"}, {'value': "25/08", "libelle": "25 août"},{'value': "30/08", "libelle": "30 août"}, {'value': "05/09", "libelle": "05 septembre"}])
    }
  }
  function handleChangeAleas(event){
    var al = event.target.value
    setAleas(al)
  }

  const calculateSum = (dataSurface) => {
    if (dataSurface) {
      return dataSurface.reduce((sum, item) => sum + item.value, 0);
    }
    return 0;
  };

  function handle2degre(){
    if (annee==="2025"){
      setData2(R2025_2degre)
    }
    else if (annee==="2030"){
      setData2(R2030_2degre)
    }
    else if (annee==="2035"){
      setData2(R2035_2degre)
    }
    else if (annee==="2040"){
      setData2(R2040_2degre)
    }
    else if (annee==="2045"){
      setData2(R2045_2degre)
    }
    else {
      setData2(R2050_2degre)
    }
    setRdata2020(R2020_2degre)
    setRdata2025(R2025_2degre)
    setRdata2030(R2030_2degre)
    setRdata2035(R2035_2degre)
    setRdata2040(R2040_2degre)
    setRdata2045(R2045_2degre)
    setRdata2050(R2050_2degre)
    setRMoy(Moy_2degre)
    setB2d('Checked')
    setB4d('UnChecked')
  }
  function handle4degre(){
    if (annee==="2025"){
      setData2(R2025_4degre)
    }
    else if (annee==="2030"){
      setData2(R2030_4degre)
    }
    else if (annee==="2035"){
      setData2(R2035_4degre)
    }
    else if (annee==="2040"){
      setData2(R2040_4degre)
    }
    else if (annee==="2045"){
      setData2(R2045_4degre)
    }
    else {
      setData2(R2050_4degre)
    }
    setRdata2020(R2020_4degre)
    setRdata2025(R2025_4degre)
    setRdata2030(R2030_4degre)
    setRdata2035(R2035_4degre)
    setRdata2040(R2040_4degre)
    setRdata2045(R2045_4degre)
    setRdata2050(R2050_4degre)
    setRMoy(Moy_4degre)
    setB2d('UnChecked')
    setB4d('Checked')
  }
  function handleChangeAnneeComp(event){
    var anneeC = parseInt(event.target.value, 10);
    setAnneeComp(anneeC)
    if (anneeC === 2025 ){
      setAnnee('2025')
      if(B2d==="Checked"){
        setData2(R2025_2degre)
      }
      else {
        setData2(R2025_4degre)
      }
    }
    else if (anneeC === 2030 ){
      setAnnee('2030')
      if(B2d==="Checked"){
        setData2(R2030_2degre)
      }
      else {
        setData2(R2030_4degre)
      }
    } 
    else if (anneeC === 2035 ){
      setAnnee('2035')
      if(B2d==="Checked"){
        setData2(R2035_2degre)
      }
      else {
        setData2(R2035_4degre)
      }
    } 
    else if (anneeC === 2040 ){
      setAnnee('2040')
      if(B2d==="Checked"){
        setData2(R2040_2degre)
      }
      else {
        setData2(R2040_4degre)
      }
    } 
    else if (anneeC === 2045 ){
      setAnnee('2045')
      if(B2d==="Checked"){
        setData2(R2045_2degre)
      }
      else {
        setData2(R2045_4degre)
      }
    } 
    else {
      setAnnee('2050')
      if(B2d==="Checked"){
        setData2(R2050_2degre)
      }
      else {
        setData2(R2050_4degre)
      }
    }
  }
  function handleCalculer(){
    fetch('https://api2050.diagorisk.com/resultatCulture/?idCommune=' + city + '&Culture=' + culture + '&Semis=' + semis + '&Aleas=' + aleas) // 'https://api2050.diagorisk.com/resultatCulture/?idCommune=' + city + '&Culture=' + culture + '&Semis=' + semis + '&Aleas=' + aleas OU 'https://api2050.diagorisk.com/resultatExploitation/?idExploit=' + "8156"
      .then( (response) => {
        return response.json()
      })
      .then(response =>{
        setSurf(calculateSum(response.dataSurface))
        setSurf(prev => {
          return prev;
        })
        setPB(calculateSum(response.dataPB))
        setPB(prev => {
          return prev;
        })
        setR2020_2degre(response.R2020_2degre)
        setR2020_2degre(prev => {
          setRdata2020(prev)
          return prev;
        })
        setR2025_2degre(response.R2025_2degre)
        setR2025_2degre(prev => {
          setRdata2025(prev)
          return prev;
        })
        setR2030_2degre(response.R2030_2degre)
        setR2030_2degre(prev => {
          setRdata2030(prev)
          setData2(prev)
          return prev;
        })
        setR2035_2degre(response.R2035_2degre)
        setR2035_2degre(prev => {
          setRdata2035(prev)
          return prev;
        })
        setR2040_2degre(response.R2040_2degre)
        setR2040_2degre(prev => {
          setRdata2040(prev)
          return prev;
        })
        setR2045_2degre(response.R2045_2degre)
        setR2045_2degre(prev => {
          setRdata2045(prev)
          return prev;
        })
        setR2050_2degre(response.R2050_2degre)
        setR2050_2degre(prev => {
          setRdata2050(prev)
          return prev;
        })
        setRMoy_2degre(response.RMoy_2degre)
        setRMoy_2degre(prev => {
          setRMoy(prev)
          return prev;
        })
        setR2020_4degre(response.R2020_4degre)
        setR2020_4degre(prev => {
          return prev;
        })
        setR2025_4degre(response.R2025_4degre)
        setR2025_4degre(prev => {
          return prev;
        })
        setR2030_4degre(response.R2030_4degre)
        setR2030_4degre(prev => {
          return prev;
        })
        setR2035_4degre(response.R2035_4degre)
        setR2035_4degre(prev => {
          return prev;
        })
        setR2040_4degre(response.R2040_4degre)
        setR2040_4degre(prev => {
          return prev;
        })
        setR2045_4degre(response.R2045_4degre)
        setR2045_4degre(prev => {
          return prev;
        })
        setR2050_4degre(response.R2050_4degre)
        setR2050_4degre(prev => {
          return prev;
        })
        setRMoy_4degre(response.RMoy_4degre)
        setRMoy_4degre(prev => {
          return prev;
        })
    })
    setShowResult(true)
  }
  if(goToHome) {
    return <Navigate push to={`/`} />
  }
  

  return (
    <div>
        <Entete titre="Analyse sur 1 culture"/>
        <div className='container'>
          <div className='titrePlus'> Zoom sur une seule culture</div>
          <div className='principal principalBis' style={{alignItems:'flex-start'}}>
            <div className='bottom bottom2' style={{marginTop:'0px'}}>
              {/* <div className='titreMoins' style={{marginTop:'5px'}}>Sélectionnez votre commune : </div>
              <select value={city} onChange={handleScrollSelect}  style={{ marginBottom:'10px'}}>
                  <option value="">Sélectionnez</option>
                  {dataCommune.map((item)=>( <option key={item.idCommune} value={item.idCommune}> {item.City} </option> ) )}
              </select>*/}
              <div className='titreMoins'> Saisissez votre code postal <span style={{color:'red', fontWeight:'normal'}}>{PBCp ? 'Ce code postal est incorrecte' : ''}</span></div>
              {/*<input 
              className='input'
              maxLength="5"
              value={CP}
              style={{width:'98%'}}
              onChange={handleChange}
              placeholder='Code Postal'
              type='text'
              required/>*/}
              <select value={CP} onChange={handleChange} style={{width:'100%'}} required>
                  <option value="">Choisissez</option>
                  {CPVierzon.map((item)=>( <option key={item.key} value={item.key} > {item.libelle} </option> ) )}
              </select> 
              <div className='titreMoins'> Sélectionnez votre ville </div>
              <select value={city} onChange={handleScrollSelect} style={{width:'100%'}} required>
                  <option value="">Choisissez</option>
                  { Array.isArray(ListVille) ? ListVille.map((item)=>( <option key={item.key} value={item.key} > {item.libelle} </option> ) ) : ListVille===undefined ?  <option value={'pas de ville'}>  </option>  : <option value={ListVille.libelle}> {ListVille.libelle} </option> }
              </select> 
            </div>

            <div className='bottom bottom2' style={{marginTop:'0px'}}>
              <div className='titreMoins' style={{marginTop:'5px'}}>Culture :</div>
              <select value={culture} onChange={handleChangeCUlture}  style={{ marginBottom:'10px', width:'100%'}}>
                  <option value="">Sélectionnez</option>
                  <option value="1122">Blé tendre de printemps</option>
                  <option value="1123">Blé tendre d'hiver</option>
                  <option value="1112">Blé dur de printemps</option>
                  <option value="1113">Blé dur d'hiver</option>
                  <option value="114">Orge de printemps</option>
                  <option value="115">Orge d'hiver</option>
                  <option value="118">Maïs</option>
                  <option value="222">Colza</option>
                  {/*<option value="1">Gamay N</option>
                  <option value="2">Chardonnay B</option>
                  <option value="3">Pinot N</option>
                  <option value="4">Chasselas</option>
                  <option value="5">Sauvignon B</option>
                  <option value="6">Carignan N</option>
                  <option value="7">Grenache N</option>
                  <option value="8">Syrah N</option>
                  <option value="9">Ugni Blanc B</option>
                  <option value="10">Caber.Sauvignon N</option>
                  <option value="11">Cinsault N</option>
                  <option value="12">Merlot N</option>
                  <option value="13">Cabernet Franc N</option>
                  <option value="14">Mourvedre N</option>
                  <option value="15">Tannat N</option>
                  <option value="16">Cot N</option>
                  <option value="17">Petit Verdot N</option>
                  <option value="18">Riesling B</option> */}
                  

              </select>
              <div className='titreMoins' style={{marginTop:'5px'}}>Précocite :</div>
              <select className='dateSemisUneCulture' value={Precocite} onChange={handleChangePrecocite}  style={{ marginBottom:'10px', width:'100%'}} disabled={culture.length<=2 && culture.length>0 ? true : false}>
                  <option value="">Sélectionnez</option>
                  <option value="5">Tardive</option>
                  <option value="5.5">1/2 Tardive</option>
                  <option value="6">1/2 tardive à 1/2 précoce</option>
                  <option value="6.5">1/2 précoce</option>
                  <option value="7">Précoce</option>
                  <option value="7.5">Très précoce</option>
              </select>
              <div className='titreMoins' style={{marginTop:'5px'}}>Date de semis :</div>
              <select className='dateSemisUneCulture' value={semis} onChange={handleChangeSemis}  style={{ marginBottom:'10px', width:'100%'}} disabled={culture.length<=2 && culture.length>0 ? true : false}>
                  <option value="">Sélectionnez</option>
                  {dataListeSemis.map((item)=>( <option key={item.value} value={item.value} > {item.libelle} </option> ) )}
              </select>
              
              {/* <input 
              className='dateSemisUneCulture'
              value={semis} 
              onChange={handleChangeSemis}  
              style={{ marginBottom:'10px', width:'98%'}}
              placeholder='JJ.MM'
              disabled={culture.length<=2 && culture.length>0 ? true : false}
              /> */}

              <div className='titreMoins' style={{marginTop:'5px'}}>Aléas :</div>
              <select value={aleas} onChange={handleChangeAleas}  style={{ marginBottom:'10px', width:'100%'}}>
                  <option value="">Sélectionnez</option>
                  <option value="1">Gel</option>
                  <option value="2">Coup de chaleur</option>
                  <option value="3">Stress hydrique</option>
                  <option value="4"> Excès d'eau</option>
                  {/*<option value="5">Manque de rayonnement</option>*/}
                  <option value="6">Tous</option>
              </select>
            </div>
          </div>

          <div className='row'><button className='validation' onClick={handleCalculer}>Calculer</button></div>

          <div className={ShowResult ? 'ShowResult' : 'HideResult'}> 
            <div className='row flexLigne espaceprint' style={{alignItems:'flex-start',justifyContent:'flex-start'}}>
            <div className='titre'>Evolution du risque entre 2020 et {anneeComp}</div> 
            <div style={{width:'30%', marginLeft:'30px', display:'flex', flexDirection:'column'}}> 
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <div className='paragraphe'>2025</div>
                <div className='paragraphe'>2050</div>
              </div>
              <input style={{margin:'0px', height:'35px', width:'100%'}} type="range" id="cowbell" name="cowbell" min={2025} max={2050} value={anneeComp} step="5" onChange={handleChangeAnneeComp}/>
            </div>
            
          </div>

          <div className='row' style={{justifyContent:'flex-start'}}>
            <button onClick={handle2degre} className={B2d==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep button2050degreChecked'}> +2°C </button>
            <button onClick={handle4degre} className={B4d==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep button2050degreChecked'}> +4°C </button>
          </div>

          <div className='dispLigne'>
            <div className='column dispLeft dispLeft2050'>
              <GraphDifference myData2020={R2020} myData2={data2} annee={annee}/> 
              <GraphRiskMoy Data2D={Moy_2degre} Data4D={Moy_4degre} DataSF={[]} />
            </div>

            {R2020!== undefined && R2030!== undefined && R2040!== undefined && R2050!== undefined && Moy!== undefined ? (
            <div className='column dispRight dispRight2050'>
              <div className='titre'>Evolution du coût du risque en % de produit brut en point </div> 
              <div className='tableau_prix_rapport' style={{width:'100%'}}> 
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}>Par rapport à 2020 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#7030A2', height:'60px', textAlign:'center'}}> Très courant <br/>(3 ans)</div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#FABE06', height:'60px', textAlign:'center'}}> Courant <br/>(7 ans) </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport'style={{backgroundColor:'#0027D6', height:'60px', textAlign:'center'}}> Exceptionnel <br/>(20 ans)</div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#DB04B9', height:'60px', textAlign:'center'}}> Catastrophique <br/>(50 ans) </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'white', color:'black', height:'60px', textAlign:'center'}}> Moyen</div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2025 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2025[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[1]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2030 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2030[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[2]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2035 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2035[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[3]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2040 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2040[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Moy[4]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2045 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[0]- R2020[0]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2045[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050' style={{fontWeight:'bold'}}> { (Moy[5]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                    <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2050 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[0]- R2020[0]).toFixed(1).toLocaleString()}% </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[1]- R2020[1]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[2]- R2020[2]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (R2050[3]- R2020[3]).toFixed(1).toLocaleString()} % </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Moy[6]- Moy[0]).toFixed(1).toLocaleString()} % </div>
                    </div>
                  </div>

                  <div className='titre espaceprint'>Evolution du coût du risque en euros par hectare </div> 
                  <div className='tableau_prix_rapport' style={{width:'100%'}}> 
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}>Par rapport à 2020 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#7030A2', height:'60px', textAlign:'center'}}> Très courant <br/>(3 ans)</div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#FABE06', height:'60px', textAlign:'center'}}> Courant <br/>(7 ans) </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport'style={{backgroundColor:'#0027D6', height:'60px', textAlign:'center'}}> Exceptionnel <br/>(20 ans)</div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'#DB04B9', height:'60px', textAlign:'center'}}> Catastrophique <br/>(50 ans) </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050 abscisse_tableau_prix_rapport' style={{backgroundColor:'white', color:'black', height:'60px', textAlign:'center'}}> Moyen</div>
                    </div>
                    <div className='row_tableau_prix_rapport'> 
                      <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2025 </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2025[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[1]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2030 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2030[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[2]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                      <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2035 </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2035[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[3]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                        <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2040 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2040[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[4]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                      <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2045 </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2045[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                      <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[5]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                    </div>
                    <div className='row_tableau_prix_rapport'>
                      <div className='ordonnée_tableau_prix_rapport ordonne_2050' style={{fontWeight:'bold'}}> 2050 </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[0]*PB/100- R2020[0]*PB/100)/surf).toFixed(0).toLocaleString()}€ </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[1]*PB/100- R2020[1]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[2]*PB/100- R2020[2]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'> { (Math.round(R2050[3]*PB/100- R2020[3]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                        <div className='data_tableau_prix_rapport data_tableau_2050'style={{fontWeight:'bold'}}> { (Math.round(Moy[6]*PB/100- Moy[0]*PB/100)/surf).toFixed(0).toLocaleString()} € </div>
                    </div>
                  </div>
                </div>
            ) : ("")}
            </div>
          </div>
        </div>
    </div>
  )
}

export default UneCulture