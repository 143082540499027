import React from "react";
import ReactEcharts from "echarts-for-react";

  function GraphCamemberg({myData, PB, print}) { 
    const getOptions = () => {
      let options = {
        series: [
          {
            name: "Assolement de l'exploitation",
            type: "pie",
            radius: [30, 160],
            center: ["50%", "50%"],
            data: myData,
            itemStyle: {
              borderRadius: 0,
              borderColor: 'white', // Couleur de la bordure blanche
              borderWidth: 2, // Épaisseur de la bordure
            },
            label: {
              fontSize: 15,
              lineHeight: 22,
            },
          }
        ]
      };
  
      if (PB !== "") {
        options.graphic = [
          {
            type: 'text',
            top: 'center',
            left: 'center',
            style: {
              text: PB.toString() + ' €',
              textAlign: 'center',
              fill: '#000',
              fontSize: 14,
              fontWeight: 'bold'
            }
          }
        ];
      }
  
      return options;
    };
  

    return (
      <div style={{marginBottom:"20px", width: print ? '70%': '90%', height: print ? '400px': '425px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphCamemberg;
  