import React, {useEffect, useRef}  from 'react'
import apiLink from '../../../config.js'
import {useLocation} from 'react-router-dom'

import ButtonQuestions from '../../MEP/button question.js'
import Entete from '../../MEP/entete'
import GraphAssolement3 from '../../OAD/Graphiques/assolement_graph_3';
import BlocCompRdt from './Composant_comparaison_simulations/bloc_Comp_rdt';
import Graph3VaguesComp from './Composant_comparaison_simulations/Graph_trois_vagues_comp';
import BlocCompOpti from './Composant_comparaison_simulations/bloc_Comp_Opti';
import BlocCompResi from './Composant_comparaison_simulations/bloc_Comp_résistance';

import IMGProd from '../../../assets/navig_exploit_ac.png'
import IMGRDT from '../../../assets/navig_riskRDT_ac.png'
import IMGPrix from '../../../assets/navig_riskPRIX_ac.png'
import IMGResid from '../../../assets/navig_robustesse_ac.png'
import IMGOpti from '../../../assets/navig_opti_ac.png'

function CompSimul() {
  const { state } = useLocation();
  const { Sim1, Sim2 } = state; //récupérer les deux Simulations

  const idExploitationPrincipale = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));

  const [Libelle1, setLibell1]= React.useState("");
  const [Libelle2, setLibell2]= React.useState("");
  const [myDataSurf1, setDataSurf1]= React.useState([]);
  const [myDataSurf2, setDataSurf2]= React.useState([]);
  const [surfTot1, setsurfTot1]= React.useState(0);
  const [surfTot2, setsurfTot2]= React.useState(0);
  const [PBTot1, setPBTot1]= React.useState(0);
  const [PBTot2, setPBTot2]= React.useState(0);
  const [ChargesCoef1, setChargesCoef1]= React.useState();
  const [ChargesCoef2, setChargesCoef2]= React.useState();
  const [Risques7_1, setR7_1] = React.useState(0)
  const [Risques7_2, setR7_2] = React.useState(0)
  const [Risques20_1, setR20_1] = React.useState(0)
  const [Risques20_2, setR20_2] = React.useState(0)
  const [Risques50_1, setR50_1] = React.useState(0)
  const [Risques50_2, setR50_2] = React.useState(0)
  const [dataPerso_1, setDP_1]= React.useState();
  const [dataPerso_2, setDP_2]= React.useState();
  const [dataVagues7, setDV7]= React.useState();
  const [dataVagues20, setDV20]= React.useState();
  const [dataVagues50, setDV50]= React.useState();
  const [maximumRDT, setmaxDRT]= React.useState();
  const [dataQ1, setDQ1]= React.useState();
  const [dataQ2, setDQ2]= React.useState();
  const [dataQ3, setDQ3]= React.useState();
  const [dataGraphCN_1, setDataGraphCN_1] = React.useState([
    [
      [0, 35, 17, 'Sans ass', 'Sans assurance']
    ],
    [
      [1, 14, 61, 'MRC 20%','Sans option' ],
      [0.4, 19, 49,'MRC 25%' ,'Sans option' ],
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 60%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 60%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 110%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 110%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 120%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 120%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 90%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 90%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 105%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 105%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 110%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 110%']
    ],
    [
      [1.25, 11, 68, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
      [0.65, 14, 55, 'MRC 25%', 'Rachat rendement 110%, rachat prix 120%'],
      
    ],
  ])
  const [dataGraphCN_2, setDataGraphCN_2] = React.useState([
    [
      [0, 35, 17, 'Sans ass', 'Sans assurance']
    ],
    [
      [1, 14, 61, 'MRC 20%','Sans option' ],
      [0.4, 19, 49,'MRC 25%' ,'Sans option' ],
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 60%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 60%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 110%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 110%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 120%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 120%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 90%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 90%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 105%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 105%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 110%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 110%']
    ],
    [
      [1.25, 11, 68, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
      [0.65, 14, 55, 'MRC 25%', 'Rachat rendement 110%, rachat prix 120%'],
      
    ],
  ])
  const [PertesCourant_1, setPCO_1]= React.useState(0);
  const [PertesExceptionnel_1, setPE_1]= React.useState(0);
  const [PertesCatastrophe_1, setPCA_1]= React.useState(0); 
  const [Ass20_7_1, setAss20_7_1]= React.useState(0);
  const [Ass25_7_1, setAss25_7_1]= React.useState(0);
  const [AssFS_7_1, setAssFS_7_1]= React.useState(0);
  const [Ass20_20_1, setAss20_20_1]= React.useState(0);
  const [Ass25_20_1, setAss25_20_1]= React.useState(0);
  const [AssFS_20_1, setAssFS_20_1]= React.useState(0);
  const [Ass20_50_1, setAss20_50_1]= React.useState(0);
  const [Ass25_50_1, setAss25_50_1]= React.useState(0);
  const [AssFS_50_1, setAssFS_50_1]= React.useState(0);
  const [PertesCourant_2, setPCO_2]= React.useState(0);
  const [PertesExceptionnel_2, setPE_2]= React.useState(0);
  const [PertesCatastrophe_2, setPCA_2]= React.useState(0); 
  const [Ass20_7_2, setAss20_7_2]= React.useState(0);
  const [Ass25_7_2, setAss25_7_2]= React.useState(0);
  const [AssFS_7_2, setAssFS_7_2]= React.useState(0);
  const [Ass20_20_2, setAss20_20_2]= React.useState(0);
  const [Ass25_20_2, setAss25_20_2]= React.useState(0);
  const [AssFS_20_2, setAssFS_20_2]= React.useState(0);
  const [Ass20_50_2, setAss20_50_2]= React.useState(0);
  const [Ass25_50_2, setAss25_50_2]= React.useState(0);
  const [AssFS_50_2, setAssFS_50_2]= React.useState(0);
  const [dataScale25_1, seTDataScale25_1] = React.useState([{value:0.56, name:'Robustesse MRC 25%'}]);
  const [dataScale20_1, seTDataScale20_1] = React.useState([{value:0.51, name:'Robustesse MRC 20%'}]);
  const [dataScale25_2, seTDataScale25_2] = React.useState([{value:0.56, name:'Robustesse MRC 25%'}]);
  const [dataScale20_2, seTDataScale20_2] = React.useState([{value:0.51, name:'Robustesse MRC 20%'}]);

  const ChargesTot1 = PBTot1*ChargesCoef1
  const ventesMoy1 = [{x:-6,y:PBTot1*0.99},{x:-3,y:PBTot1*0.995},{x:0,y:PBTot1},{x:3,y:PBTot1*1.01},{x:6,y:PBTot1*1.02},{x:9,y:PBTot1*1.04},{x:12,y:PBTot1*1.05},{x:18,y:PBTot1*1.07}]
  const chargesMoy1 = [{x:-6,y:ChargesTot1*0.95},{x:-3,y:ChargesTot1*0.98},{x:0,y:ChargesTot1},{x:3,y:ChargesTot1*1.01},{x:6,y:ChargesTot1*1.02},{x:9,y:ChargesTot1*1.04},{x:12,y:ChargesTot1*1.05},{x:18,y:ChargesTot1*1.07}]
  const ChargesTot2 = PBTot2*ChargesCoef2
  const ventesMoy2 = [{x:-6,y:PBTot2*0.99},{x:-3,y:PBTot2*0.995},{x:0,y:PBTot2},{x:3,y:PBTot2*1.01},{x:6,y:PBTot2*1.02},{x:9,y:PBTot2*1.04},{x:12,y:PBTot2*1.05},{x:18,y:PBTot2*1.07}]
  const chargesMoy2 = [{x:-6,y:ChargesTot2*0.95},{x:-3,y:ChargesTot2*0.98},{x:0,y:ChargesTot2},{x:3,y:ChargesTot2*1.01},{x:6,y:ChargesTot2*1.02},{x:9,y:ChargesTot2*1.04},{x:12,y:ChargesTot2*1.05},{x:18,y:ChargesTot2*1.07}]



  function shapeData(dataGraph){
    return [
      [
        [100*Math.round(dataGraph['Sans Assurance'][0]*1000)/1000, 100*Math.round(dataGraph['Sans Assurance'][1]*1000)/1000, 100*Math.round(dataGraph['Sans Assurance'][2]*1000)/1000, 'Sans ass', 'Sans assurance']
      ],
      [
        [100*Math.round(dataGraph['MRC 20%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20%'][2]*1000)/1000, 'MRC 20%','Sans option' ],
        [100*Math.round(dataGraph['MRC 25%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25%'][2]*1000)/1000,'MRC 25%' ,'Sans option' ],
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 60% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 60% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 60% '][2]*1000)/1000, 'MRC 20%','Rachat prix 60% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 60% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 60% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 60% '][2]*1000)/1000, 'MRC 25%','Rachat prix 60% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 110% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 110% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 110% '][2]*1000)/1000, 'MRC 20%','Rachat prix 110% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 110% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 110% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 110% '][2]*1000)/1000, 'MRC 25%','Rachat prix 110% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 120% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 120% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 120% '][2]*1000)/1000, 'MRC 20%','Rachat prix 120% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 120% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 120% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 120% '][2]*1000)/1000, 'MRC 25%','Rachat prix 120% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 90%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 90%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 90%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 90%'],
        [100*Math.round(dataGraph['MRC 25% + Rendement 90%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 90%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 90%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 90%']
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 105%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 105%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 105%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 105%'],
        [100*Math.round(dataGraph['MRC 25% + Rendement 105%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 105%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 105%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 105%']
      ], 
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 110%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 110% '],
        [100*Math.round(dataGraph['MRC 25% + Rendement 110%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110%'][2]*1000)/1000, 'MRC 25% ','Rachat rendement 110% ']
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
        [100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 110%, rachat prix 120%'],
        
      ],
    ]
  }
  const minX_1= 0
  const minY_1 = 0
  const maxX_1 = Math.round(dataGraphCN_1[8][0][0])%2 === 0 ? Math.round(dataGraphCN_1[8][0][0]) + 2 : Math.round(dataGraphCN_1[8][0][0]) +1 
  const maxY_1 = Math.round(dataGraphCN_1[0][0][1])%2 === 0 ? Math.round(dataGraphCN_1[0][0][1]) + 2 : Math.round(dataGraphCN_1[0][0][1]) +1 
  const minX_2= 0
  const minY_2 = 0
  const maxX_2 = Math.round(dataGraphCN_2[8][0][0])%2 === 0 ? Math.round(dataGraphCN_2[8][0][0]) + 2 : Math.round(dataGraphCN_2[8][0][0]) +1 
  const maxY_2 = Math.round(dataGraphCN_2[0][0][1])%2 === 0 ? Math.round(dataGraphCN_2[0][0][1]) + 2 : Math.round(dataGraphCN_2[0][0][1]) +1 

  useEffect ( () => {
    fetch(apiLink + 'page/exploitation/' + Sim1)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataSurf1(response.dataSurface)
      setDataSurf1(prev => {
        return prev;
      })
      setsurfTot1(response.surfaceTotale)
      setsurfTot1(prev => {
        return prev;
      })
      setPBTot1(response.PBTotal)
      setPBTot1(prev => {
        return prev;
      })
    })
    fetch(apiLink + 'page/exploitation/' + Sim2)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataSurf2(response.dataSurface)
      setDataSurf2(prev => {
        return prev;
      })
      setsurfTot2(response.surfaceTotale)
      setsurfTot2(prev => {
        return prev;
      })
      setPBTot2(response.PBTotal)
      setPBTot2(prev => {
        return prev;
      })
    })
    fetch(apiLink + 'page/risqueprix/' + Sim1)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setChargesCoef1(response.charge)
      setChargesCoef1(prev => {
        return prev;
      })
    }) 
    fetch(apiLink + 'page/risqueprix/' + Sim2)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setChargesCoef2(response.charge)
      setChargesCoef2(prev => {
        return prev;
      })
    }) 
    fetch(apiLink + 'page/optimisation/' + Sim1)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGraphCN_1(shapeData(response.dataGraphCN))
      setDataGraphCN_1(prev => {
      return prev;
      })
      setR7_1(response.risques7)
      setR7_1(prev => {
        return prev;
      })
      setR20_1(response.risques20)
      setR20_1(prev => {
        return prev;
      })
      setR50_1(response.risques50)
      setR50_1(prev => {
        return prev;
      })
    }) 
    fetch(apiLink + 'page/optimisation/' + Sim2)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGraphCN_2(shapeData(response.dataGraphCN))
      setDataGraphCN_2(prev => {
      return prev;
      })
      setR7_2(response.risques7)
      setR7_2(prev => {
        return prev;
      })
      setR20_2(response.risques20)
      setR20_2(prev => {
        return prev;
      })
      setR50_2(response.risques50)
      setR50_2(prev => {
        return prev;
      })
    }) 
    fetch(apiLink + 'page/production-rapport/' + Sim1)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      let data1 = response
        fetch(apiLink + 'page/production-rapport/' + Sim2)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
        
        let data2 = response
        var vitiPres = false
        for (var cle in data1) {
          if (cle.includes("Vignes")) {
            vitiPres = true
          }
        }
        for (var cle in data2) {
          if (cle.includes("Vignes")) {
            vitiPres = true
          }
        }
        if(vitiPres){
        fetch(apiLink + 'page/comparaison/' + Sim1 + '/' + Sim2 + '/2')
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP_1(response.dataPerso)
          setDP_1(prev => {
              return prev;
          })
          setDP_2(response.dataPerso2)
          setDP_2(prev => {
              return prev;
          })
          setLibell1(response.libelleExploitation1)
          setLibell1(prev => {
              return prev;
          })
          setLibell2(response.libelleExploitation2)
          setLibell2(prev => {
              return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
              return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
              return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
              setmaxDRT(prev[prev.length-2].y+20)
              setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
              setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
              setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
              return prev;
          })
        }) 
      }
      else {
        fetch(apiLink + 'page/comparaison/' + Sim1 + '/' + Sim2)
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP_1(response.dataPerso)
          setDP_1(prev => {
              return prev;
          })
          setDP_2(response.dataPerso2)
          setDP_2(prev => {
              return prev;
          })
          setLibell1(response.libelleExploitation1)
          setLibell1(prev => {
              return prev;
          })
          setLibell2(response.libelleExploitation2)
          setLibell2(prev => {
              return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
              return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
              return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
              setmaxDRT(prev[prev.length-1].y+20)
              setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
              setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
              setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
              return prev;
          })
        }) 
      } 
    });
  });

    
    fetch(apiLink + 'page/resistance/'  + Sim1)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
      setPCO_1(response[0].val.indemnisationmoyenne_7)
      setPCO_1(prev => {
          return prev;
      })
      setPE_1(response[0].val.indemnisationmoyenne_20)
      setPE_1(prev => {
          return prev;
      })
      setPCA_1(response[0].val.indemnisationmoyenne_50)
      setPCA_1(prev => {
          return prev;
      })
      setAss20_7_1(response[20].val.indemnisationmoyenne_7)
      setAss20_7_1(prev => {
          return prev;
      })
      setAss20_20_1(response[20].val.indemnisationmoyenne_20)
      setAss20_20_1(prev => {
          return prev;
      })
      setAss20_50_1(response[20].val.indemnisationmoyenne_50)
      setAss20_50_1(prev => {
          return prev;
      })
      setAss25_7_1(response[25].val.indemnisationmoyenne_7)
      setAss25_7_1(prev => {
          return prev;
      })
      setAss25_20_1(response[25].val.indemnisationmoyenne_20)
      setAss25_20_1(prev => {
          return prev;
      })
      setAss25_50_1(response[25].val.indemnisationmoyenne_50)
      setAss25_50_1(prev => {
          return prev;
      })
      setAssFS_7_1(response[50].val.indemnisationmoyenne_7)
      setAssFS_7_1(prev => {
          return prev;
      })
      setAssFS_20_1(response[50].val.indemnisationmoyenne_20)
      setAssFS_20_1(prev => {
          return prev;
      })
      setAssFS_50_1(response[50].val.indemnisationmoyenne_50)
      setAssFS_50_1(prev => {
          return prev;
      })
      seTDataScale25_1(response['robustesse']['25'])
      seTDataScale25_1(prev => {
          return prev;
      })
      seTDataScale20_1(response['robustesse']['20'])
      seTDataScale20_1(prev => {
          return prev;
      })
    }) 
    fetch(apiLink + 'page/resistance/'  + Sim2)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
      setPCO_2(response[0].val.indemnisationmoyenne_7)
      setPCO_2(prev => {
          return prev;
      })
      setPE_2(response[0].val.indemnisationmoyenne_20)
      setPE_2(prev => {
          return prev;
      })
      setPCA_2(response[0].val.indemnisationmoyenne_50)
      setPCA_2(prev => {
          return prev;
      })
      setAss20_7_2(response[20].val.indemnisationmoyenne_7)
      setAss20_7_2(prev => {
          return prev;
      })
      setAss20_20_2(response[20].val.indemnisationmoyenne_20)
      setAss20_20_2(prev => {
          return prev;
      })
      setAss20_50_2(response[20].val.indemnisationmoyenne_50)
      setAss20_50_2(prev => {
          return prev;
      })
      setAss25_7_2(response[25].val.indemnisationmoyenne_7)
      setAss25_7_2(prev => {
          return prev;
      })
      setAss25_20_2(response[25].val.indemnisationmoyenne_20)
      setAss25_20_2(prev => {
          return prev;
      })
      setAss25_50_2(response[25].val.indemnisationmoyenne_50)
      setAss25_50_2(prev => {
          return prev;
      })
      setAssFS_7_2(response[50].val.indemnisationmoyenne_7)
      setAssFS_7_2(prev => {
          return prev;
      })
      setAssFS_20_2(response[50].val.indemnisationmoyenne_20)
      setAssFS_20_2(prev => {
          return prev;
      })
      setAssFS_50_2(response[50].val.indemnisationmoyenne_50)
      setAssFS_50_2(prev => {
          return prev;
      })
      seTDataScale25_2(response['robustesse']['25'])
      seTDataScale25_2(prev => {
          return prev;
      })
      seTDataScale20_2(response['robustesse']['20'])
      seTDataScale20_2(prev => {
          return prev;
      })
    }) 
  }, [Sim1, Sim2])

  const ScrollProd= useRef(null);
  const ScrollRDT= useRef(null);
  const ScrollPrix= useRef(null);
  const ScrollResi= useRef(null);
  const ScrollOpti= useRef(null);
  const scrollToProd = () => ScrollProd.current.scrollIntoView(true);
  const scrollToRDT = () => ScrollRDT.current.scrollIntoView(true);
  const scrollToPrix = () => ScrollPrix.current.scrollIntoView(true);
  const scrollToResi = () => ScrollResi.current.scrollIntoView(true);
  const scrollToOpti = () => ScrollOpti.current.scrollIntoView(true);

  const comp_simul = (
    <div>
      <div className='menuComparaison'>
        <button onClick={scrollToProd} className='buttonMenuComp'> <img src={IMGProd} alt='icone' className='iconMenuComp'/> </button>
        <button onClick={scrollToRDT} className='buttonMenuComp'> <img src={IMGRDT} alt='icone' className='iconMenuComp'/> </button>
        <button onClick={scrollToPrix} className='buttonMenuComp'> <img src={IMGPrix} alt='icone' className='iconMenuComp'/> </button>
        <button onClick={scrollToResi} className='buttonMenuComp'> <img src={IMGResid} alt='icone' className='iconMenuComp'/> </button>
        <button onClick={scrollToOpti} className='buttonMenuComp'> <img src={IMGOpti} alt='icone' className='iconMenuComp'/> </button>
      </div>

      <div ref={ScrollProd} className='titre'>Productions </div> 
      <div className='dispLigne'>
        <div className='column dispLeft BlocComparaison BCL'>
          <div className='titrePlus'> {Libelle1} <span style={{fontWeight:'normal', fontSize:'70%'}}> {Math.round(surfTot1*100)/100} ha</span> </div>
          <GraphAssolement3 myData={myDataSurf1} PB={PBTot1} print={false}/>
        </div>

        <div className='column dispLeft BlocComparaison'>
          <div className='titrePlus'> {Libelle2} <span style={{fontWeight:'normal', fontSize:'70%'}}> {Math.round(surfTot2*100)/100} ha  </span> </div>
          <GraphAssolement3 myData={myDataSurf2} PB={PBTot2} print={false}/>
        </div>
      </div> 

      <div ref={ScrollRDT} className='titre'>Quel est mon niveau de risques ? </div>
      <div className='paragraphe'> La ligne noire pleine représente la simulation de gauche et celle en pointillée, la simulation de droite. La comparaison ce fait sur les exploitations moyennes grandes cultures</div>
      <div style={{marginTop:'10px'}}>
        <Graph3VaguesComp dataPerso_1={dataPerso_1} dataPerso_2={dataPerso_2} dataVagues7={dataVagues7} dataVagues20={dataVagues20} dataVagues50={dataVagues50} maximum={maximumRDT} dataQ1={dataQ1} dataQ2={dataQ2} dataQ3={dataQ3} />
      </div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
        <div className='paragraphe' style={{marginBottom:'10px'}} > <span style={{fontWeight:'1000px', fontSize:'30px', color:'red'}}>___</span> Risques de {Libelle1} </div>
          <BlocCompRdt Risques7={Risques7_1} Risques20={Risques20_1} Risques50={Risques50_1} />
        </div>
        <div className='BlocComparaison'>
        <div className='paragraphe' style={{marginBottom:'10px'}} > <span style={{fontWeight:'1000px', fontSize:'30px', color:'red'}}>---</span> Risques de {Libelle2} </div>
          <BlocCompRdt Risques7={Risques7_2} Risques20={Risques20_2} Risques50={Risques50_2} />
        </div>
      </div>


      {/* <div ref={ScrollPrix} className='titre'>Quel est l’impact de l’évolution du prix sur ma marge ? </div>
      <div className='paragraphe'>Comparaison de la marge avec une évolution moyenne des charges et de prix.  </div>
      <div className='paragraphe'>Ces calculs sont réaslisés pour des charges représentant <span style={{fontWeight:'bold'}}> {ChargesCoef1*100} %</span> du produit brut au mois 0. Ces charges calculées sans charges sociales, sans charges financières, sans assurances et sans amortissement.</div>
      <GraphMargesComparaison dataPrix1={ventesMoy1} dataCharges1={chargesMoy1} dataPrix2={ventesMoy2} dataCharges2={chargesMoy2} maximum={maximum} minimum={minimum} print={false}/>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
          <div className='row legend'>
            <div className='row legendBloc'>
              <div className='legendGraphiquePrix legendcharges'>|</div>
              <div className='paragraphe'>Charges</div>
            </div>
            <div className='row legendBloc'>
              <div className='legendGraphiquePrix legendventes'>|</div>
              <div className='paragraphe'>Prix de vente</div>
            </div>
          </div>
          <BlocCompPrix pvente={ventesMoy1} chargesf={chargesMoy1}/> 
        </div>
        <div className='BlocComparaison'>
          <div className='row legend'>
            <div className='row legendBloc'>
              <div className='legendGraphiquePrix legendcharges legendGraphiquePrixBis'>--</div>
              <div className='paragraphe'>Charges</div>
            </div>
            <div className='row legendBloc'>
              <div className='legendGraphiquePrix legendventes legendGraphiquePrixBis'>--</div>
              <div className='paragraphe'>Prix de vente</div>
            </div>
          </div>
          <BlocCompPrix pvente={ventesMoy2} chargesf={chargesMoy2}/>
        </div>
      </div> */}

      <div ref={ScrollResi}  className='titre'>Quelle est la résistance aux chocs de votre exploitation ? </div>
      <div style={{fontSize:"20px", marginBottom:'20px'}}> Evaluation de votre reste à charge en fonction des différents types d’assurances les plus utilisées et du niveau de risques. Les outils financiers regroupent : économie de charges, épargne professionnelle, capacité d'emprunt, épargne privée. Ici les outils financiers sont fixésà 2000€ chacun.</div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
          <BlocCompResi PertesCourant={PertesCourant_1} PertesExceptionnel={PertesExceptionnel_1} PertesCatastrophe={PertesCatastrophe_1} Ass20_7={Ass20_7_1} Ass20_20={Ass20_20_1} Ass20_50={Ass20_50_1} Ass25_7={Ass25_7_1} Ass25_20={Ass25_20_1} Ass25_50={Ass25_50_1} AssFS_7={AssFS_7_1} AssFS_20={AssFS_20_1} AssFS_50={AssFS_50_1} PBTot={PBTot1} dataScale20={dataScale20_1} dataScale25={dataScale25_1}/>
        </div>
        <div className='BlocComparaison' style={{marginLeft:'20px'}}>
          <BlocCompResi PertesCourant={PertesCourant_2} PertesExceptionnel={PertesExceptionnel_2} PertesCatastrophe={PertesCatastrophe_2} Ass20_7={Ass20_7_2} Ass20_20={Ass20_20_2} Ass20_50={Ass20_50_2} Ass25_7={Ass25_7_2} Ass25_20={Ass25_20_2} Ass25_50={Ass25_50_2} AssFS_7={AssFS_7_2} AssFS_20={AssFS_20_2} AssFS_50={AssFS_50_2} PBTot={PBTot2} dataScale20={dataScale20_2} dataScale25={dataScale25_2}/>
        </div>
      </div>

      <div ref={ScrollOpti} className='titre'>Quel est mon niveau de sécurité ?  </div>
      <div className='paragraphe'>
        <div className='paragraphe' style={{marginBottom:'10px'}}> Sur le graphique :  </div>
        <ul>
          <li> <div className='lila paragraphe'>  À l’horizontal, le<span style={{fontWeight:'bold'}}> coût net </span>(montant annuel de la prime  - les subventions) de l’assurance en % du produit brut </div> </li>
          <li> <div className='lila paragraphe'>  A la verticale, le <span style={{fontWeight:'bold'}}>reste à charge</span> après indemnisation, calculé en moyenne et en % du produit brut </div> </li>
          
        </ul>

        <div className='paragraphe' style={{marginBottom:'20px'}}> Les rond avec un <span style={{fontWeight:'bold'}}> intérieur blanc </span>correspondent à l'assurance <span style={{fontWeight:'bold'}}>MRC 20%</span>, ceux <span style={{fontWeight:'bold'}}> sans</span> correspondent à l'assurance <span style={{fontWeight:'bold'}}>MRC 25%</span>. </div>

        <div className='paragraphe' style={{marginBottom:'10px'}}> Pour lire les détails, passer sur le point avec la souris : </div>
        <div className='paragraphe' > <span style={{fontWeight:'bold'}}>RAC = </span> reste à charge moyen après indemnisation</div>
        <div className='paragraphe' > <span style={{fontWeight:'bold'}}>C = </span>coût net </div>
        

      </div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
          <BlocCompOpti data={dataGraphCN_1} minX={minX_1} minY={minY_1} maxX={maxX_1} maxY={maxY_1} PBTot={PBTot1} />
        </div>
        <div className='BlocComparaison' style={{marginLeft:'20px'}}>
          <BlocCompOpti data={dataGraphCN_2} minX={minX_2} minY={minY_2} maxX={maxX_2} maxY={maxY_2} PBTot={PBTot2}/>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <Entete titre="Comparaison de simulation"/>
      <div className="container" >
        {idExploitationPrincipale!==null ? comp_simul : "Pour avoir accès à cette onglet vous devez créer un compte"}
      </div>
      <ButtonQuestions/>
    </div>
  )
}

export default CompSimul