import React, {useEffect} from 'react';
import apiLink from '../../config.js'
import {useLocation} from 'react-router-dom'

import Entete from '../MEP/entete'
import OADMenu from '../MEP/oadMenu'
import ButtonQuestions from '../MEP/button question.js';

import rouge from '../../assets/feux-rouge.png'
import orange from '../../assets/feux-orange.png'
import vert from '../../assets/feux-vert.png'
import reco1 from '../../assets/reco1.png'
import reco2 from '../../assets/reco2.png'

function Optimisation() {
  const { state } = useLocation();
  const { idExploit } = state;

  const [Risques7, setR7] = React.useState(0)
  const [Risques20, setR20] = React.useState(0)
  const [Risques50, setR50] = React.useState(0)

  const [RAC7, setRAC7] = React.useState(0)
  const [RAC20, setRAC20] = React.useState(0)
  const [RAC50, setRAC50] = React.useState(0)

  const [Reco_Ti_1, setRecoTi1] = React.useState()
  const [Reco_Te_1, setRecoTe1] = React.useState()
  const [Reco_Ti_2, setRecoTi2] = React.useState()
  const [Reco_Te_2, setRecoTe2] = React.useState()

  useEffect ( () => {
  fetch(apiLink + 'page/optimisation/' + idExploit)
  .then( (response) => {
    return response.json()
  })
  .then(response =>{
    setR7(response.risques7)
    setR7(prev => {
    return prev;
    })
    setR20(response.risques20)
    setR20(prev => {
    return prev;
    })
    setR50(response.risques50)
    setR50(prev => {
    return prev;
    })
    setRAC7(response.resteAcharge7)
    setRAC7(prev => {
    return prev;
    })
    setRAC20(response.resteAcharge20)
    setRAC20(prev => {
    return prev;
    })
    setRAC50(response.resteAcharge50)
    setRAC50(prev => {
    return prev;
    })
    setRecoTi1(response.reco1.titre)
    setRecoTi1(prev => {
    return prev;
    })
    setRecoTe1(response.reco1.texte)
    setRecoTe1(prev => {
    return prev;
    })
    setRecoTi2(response.reco2.titre)
    setRecoTi2(prev => {
    return prev;
    })
    setRecoTe2(response.reco2.texte)
    setRecoTe2(prev => {
    return prev;
    })
  }) 
  }, [idExploit])

  return (
    <div >
      <Entete titre="Optimisation"/>
      <div className='container'>
        <div className='titre'>Quel est mon niveau de sécurité ?  </div>

        <div className='dispLigne'>
          <div className='dispLeftOpt'>
            <div className='row flexFeux'>
              <div className='colEntete'>
                <div className='enteteOpti'>Risque </div>
                <div className='enteteOpti'>Coût risque </div>
                <div className='enteteOpti'>Reste à charge net </div>
              </div>

              <div className='bloc'>
                <div className='enteteOpti titreEntOpti titre7'>Courant</div>
                <div className='blocColor bloc7'>
                  <div className='enteteOpti'>{Math.round(Risques7*10)/10} % </div>
                </div>
                <img src={15<=(Risques7-RAC7)? vert : (Risques7-RAC7)<=10 ? rouge : orange} className="Feux"  alt={15<=(Risques7-RAC7)? 'Feux de signalisation au vert' : (Risques7-RAC7)<=10 ? 'Feux de signalisation au rouge' : 'Feux de signalisation au orange'}/>
                <div className='blocColor bloc7'>
                  <div className='enteteOpti'>{(Math.round(RAC7*10)/10).toLocaleString()} % </div>
                </div>
              </div>

              <div className='bloc'>
                <div className='enteteOpti titreEntOpti titre20'>Exceptionnel</div>
                <div className='blocColor bloc20'>
                  <div className='enteteOpti'>{Math.round(Risques20*10)/10} % </div>
                </div>
                <img src={15<=(Risques20-RAC20)? vert : (Risques20-RAC20)<=10 ? rouge : orange} className="Feux"  alt={15<=(Risques20-RAC20)? 'Feux de signalisation au vert' : (Risques20-RAC20)<=10 ? 'Feux de signalisation au rouge' : 'Feux de signalisation au orange'}/>
                <div className='blocColor bloc20'>
                  <div className='enteteOpti'>{(Math.round(RAC20*10)/10).toLocaleString()} % </div>
                </div>
              </div>

              <div className='bloc'>
                <div className='enteteOpti titreEntOpti titre50'>Catastrophique</div>
                <div className='blocColor bloc50'>
                  <div className='enteteOpti'>{(Math.round(Risques50*10)/10)} % </div>
                </div>
                <img src={15<=(Risques50-RAC50)? vert : (Risques50-RAC50)<=10 ? rouge : orange} className="Feux"  alt={15<=(Risques50-RAC50)? 'Feux de signalisation au vert' : (Risques50-RAC50)<=10 ? 'Feux de signalisation au rouge' : 'Feux de signalisation au orange'}/>
                <div className='blocColor bloc50'>
                  <div className='enteteOpti'>{(Math.round(RAC50*10)/10).toLocaleString()} % </div>
                </div>
              </div>
            </div>

            <div style={{marginTop:'30px', marginBottom:'30px'}}>
              <div className='paragraphe'>
                <b>Coût risque = </b> Coût du risque en % du produit brut </div>
              <div className='paragraphe'>
                <b>Reste à charge net  = </b> Pertes - indemnisations + prime d'assurance - subventions pour la recommandation 1 en % du produit brut 
              </div>
              <div className='paragraphe'>
                Les feux indiquent la différence entre la perte et le reste à charge :
                <ul>
                  <li><div className='lila'>Différence inférieure à 10% le feux est rouge</div></li>
                  <li><div className='lila'>Différence entre 10% et 15% le feux est orange</div></li>
                  <li><div className='lila'>Différence supérieure à 15% le feux est vert</div></li>
                </ul>
              </div>
            </div>
          </div>

          <div className='dispRightOpt'>
            <div className='titre'>Optimisation de ma stratégie : </div>
            <div className='card cardViolet'>
              <div className='row ligneReco'>
                <img src={reco1} className="Reco" alt='Icone'/>
                <div className='titre'>
                  Recommandation 1 : 
                </div>
              </div>
              <div className='titreCard'>
                {Reco_Ti_1}
              </div>
              <div className='paragraphe'>
              {Reco_Te_1}
              </div>
            </div>

            <div className='card cardB'>
              <div className='row ligneReco'>
              <img src={reco2} className="Reco"  alt='icone'/>
                <div className='titre'>
                  Recommandation 2 : 
                </div>
              </div>
              <div className='titreCard'>
                {Reco_Ti_2}
              </div>
              <div className='paragraphe'>
                {Reco_Te_2}
              </div>
            </div>
          </div>
        </div>

      </div>
      <ButtonQuestions/>
      <OADMenu idExploit={idExploit}/>
    </div>
  );
}

export default Optimisation;
