import React, {useEffect} from 'react';
import apiLink from '../../config.js'
import {useLocation, Navigate} from 'react-router-dom'

import Entete from '../MEP/entete'
import OADMenu from '../MEP/oadMenu'
import Graph3Vagues from './Graphiques/Troisvagues'
import ButtonQuestions from '../MEP/button question.js';
import ButtonVidéos from '../MEP/buttonVidéo.js';


import CarteRisquesPRACour from './Cartes/Cartes_risques_rendement/cartes_risques_PRA_cour'
import CarteRisquesPRAExcep  from './Cartes/Cartes_risques_rendement/cartes_risques_PRA_excep'
import CarteRisquesPRACata from './Cartes/Cartes_risques_rendement/cartes_risques_PRA_cata'

import CarteRisquesRACour from './Cartes/Cartes_risques_rendement/cartes_risques_RA_cour'
import CarteRisquesRAExcep  from './Cartes/Cartes_risques_rendement/cartes_risques_RA_excep'
import CarteRisquesRACata from './Cartes/Cartes_risques_rendement/cartes_risques_RA_cata'

import CarteRisquesDEPTCour from './Cartes/Cartes_risques_rendement/cartes_risques_DEPT_cour'
import CarteRisquesDEPTExcep  from './Cartes/Cartes_risques_rendement/cartes_risques_DEPT_excep'
import CarteRisquesDEPTCata from './Cartes/Cartes_risques_rendement/cartes_risques_DEPT_cata'

import CarteRisquesREGCour from './Cartes/Cartes_risques_rendement/cartes_risques_REG_cour'
import CarteRisquesREGExcep  from './Cartes/Cartes_risques_rendement/cartes_risques_REG_excep'
import CarteRisquesREGCata from './Cartes/Cartes_risques_rendement/cartes_risques_REG_cata'

import LegendeCarteRiskRDT from './Cartes/legendes_risques_rendement';

function RisquesRdt() {
  const { state } = useLocation();
  const { idExploit } = state;
  const [goToHome, setGoToHome] = React.useState(false)
  const [GC, setGC] = React.useState(true)


  const [loadingReg, setLoadingReg] = React.useState(true);
  const [loadingDept, setLoadingDept] = React.useState(true);
  const [loadingRA, setLoadingRA] = React.useState(true);
  const [loadingPRA, setLoadingPRA] = React.useState(true);

  const [dataReg, setDataReg] = React.useState(0)
  const [dataDEPT, setDataDEPT] = React.useState(0)
  const [dataRA, setDataRA] = React.useState(0)
  const [dataPRA, setDataPRA] = React.useState(0)
  const [dataGeoExploit, setDataGE] = React.useState(0)

  const [Risques7, setR7] = React.useState(0)
  const [Risques20, setR20] = React.useState(0)
  const [Risques50, setR50] = React.useState(0)

  const [dataPerso, setDP]= React.useState();
  const [dataVagues7, setDV7]= React.useState();
  const [dataVagues20, setDV20]= React.useState();
  const [dataVagues50, setDV50]= React.useState();
  const [maximum, setmax]= React.useState();
  const [dataQ1, setDQ1]= React.useState();
  const [dataQ2, setDQ2]= React.useState();
  const [dataQ3, setDQ3]= React.useState();
  const [Taille, setTaille] = React.useState();

  const [BCou, setBCou]= React.useState('Checked')
  const [BExc, setBExc]= React.useState('UnChecked')
  const [BCat, setBCat]= React.useState('UnChecked')
  const [TypeRisque, setTypeRisque]= React.useState('Courant')

  const [Bpra, setBpra]= React.useState('Checked')
  const [Bra, setBra]= React.useState('UnChecked')
  const [Bdept, setBdept]= React.useState('UnChecked')
  const [Breg, setBreg]= React.useState('UnChecked')
  const [Echelle, setEchelle]= React.useState('PRA')

  const [BpopupLeg, setBpopupLeg]= React.useState(false)


  function handlePressCourant() {
    setBCou('Checked')
    setBExc('UnChecked')
    setBCat('UnChecked')
    setTypeRisque('Courant')
  }

  function handlePressExceptionnel() {
    setBExc('Checked')
    setBCou('UnChecked')
    setBCat('UnChecked')
    setTypeRisque('Exceptionnel')
  }

  function handlePressCatastrophique() {
    setBCat('Checked')
    setBCou('UnChecked')
    setBExc('UnChecked')
    setTypeRisque('Catastrophique')
  }

  function handlePressBpra() {
    setBpra('Checked')
    setBra('UnChecked')
    setBdept('UnChecked')
    setBreg('UnChecked')
    setEchelle('PRA')
  }
  
  function handlePressBra() {
    setBpra('UnChecked')
    setBra('Checked')
    setBdept('UnChecked')
    setBreg('UnChecked')
    setEchelle('RA')
  }
  
  function handlePressBdept() {
    setBpra('UnChecked')
    setBra('UnChecked')
    setBdept('Checked')
    setBreg('UnChecked')
    setEchelle('DEPT')
  }

  function handlePressBreg() {
    setBpra('UnChecked')
    setBra('UnChecked')
    setBdept('UnChecked')
    setBreg('Checked')
    setEchelle('REG')
  }

  useEffect ( () => {
    fetch(apiLink + 'page/optimisation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        setR7(response.risques7)
        setR7(prev => {
        return prev;
        })
        setR20(response.risques20)
        setR20(prev => {
        return prev;
        })
        setR50(response.risques50)
        setR50(prev => {
        return prev;
        })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
    }) 

    fetch(apiLink + 'page/risquerendement/' + idExploit)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      let data = response
      var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
        fetch(apiLink + 'page/risquerendement/' + idExploit +"/2")
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP(response.dataPerso)
          setDP(prev => {
            setGC(false)
            return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
            return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
            return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
            setmax(prev[prev.length-2].y+20)
            setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
            setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
            setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
            setTaille(prev.length)
            return prev;
          })
        })
      }
      else {
        fetch(apiLink + 'page/risquerendement/' + idExploit)
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP(response.dataPerso)
          setDP(prev => {
            setGC(true)
            return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
            return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
            return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
            setmax(prev[prev.length-1].y+20)
            setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
            setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
            setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
            setTaille(prev.length)
            return prev;
          })
        }) 
      }
    }) 
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
    }) 
    fetch(apiLink + 'page/risquerendement/' + idExploit + "/2")
    .then( (response) => {
        return response.json()
    })


    fetch(apiLink + 'getLocalisation/' + idExploit)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        let idReg = response.idRegion
        fetch(apiLink + 'page/production-rapport/' + idExploit)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
            let data = response
            var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
              fetch(apiLink + 'getGeoRegion/' + idReg +"/2")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataReg(response.data)
                setDataReg(prev => {
                  setLoadingReg(false);
                  return prev;
                })
              })
            }
            else {
              fetch(apiLink + 'getGeoRegion/' + idReg +"/1")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataReg(response.data)
                setDataReg(prev => {
                  setLoadingReg(false);
                  return prev;
                })
              })
            }
          })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
  })

    fetch(apiLink + 'getLocalisation/' + idExploit)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        let idDEPT = response.departement
        fetch(apiLink + 'page/production-rapport/' + idExploit)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
            let data = response
            var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
              fetch(apiLink + 'getGeoDepartement/' + idDEPT +"/2")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataDEPT(response.data)
                setDataDEPT(prev => {
                  setLoadingDept(false);
                  return prev;
                })
              })
            }
            else {
              fetch(apiLink + 'getGeoDepartement/' + idDEPT +"/1")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataDEPT(response.data)
                setDataDEPT(prev => {
                  setLoadingDept(false);
                  return prev;
                })
              })
            }
          })
        fetch(apiLink + 'getGeoLocaExploitation/' + idExploit )
            .then( (response) => {
              return response.json()
            })
            .then(response =>{
              setDataGE(response.data)
              setDataGE(prev => {
                return prev;
              })
            })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
  })

  fetch(apiLink + 'getLocalisation/' + idExploit)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        let idRA = response.idRegionAgricole
        fetch(apiLink + 'page/production-rapport/' + idExploit)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
            let data = response
            var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
              fetch(apiLink + 'getGeoRegionAgricole/' + idRA +"/2")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataRA(response.data)
                setDataRA(prev => {
                  setLoadingRA(false);
                  return prev;
                })
              })
            }
            else {
              fetch(apiLink + 'getGeoRegionAgricole/' + idRA +"/1")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataRA(response.data)
                setDataRA(prev => {
                  setLoadingRA(false);
                  return prev;
                })
              })
            }
          })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
  })
  
  fetch(apiLink + 'getLocalisation/' + idExploit)
    .then( (response) => {
        return response.json()
    })
    .then(response =>{
      if (response !== "Exploitaiton " + idExploit + " non trouvée") {
        let idPRA = response.idPetiteRegionAgricole
        fetch(apiLink + 'page/production-rapport/' + idExploit)
          .then( (response) => {
            return response.json()
          })
          .then(response =>{
            let data = response
            var vitiPres = false
      for (var cle in data) {
        if (cle.includes("Vignes")) {
          vitiPres = true
        }
      }
      if(vitiPres){
              fetch(apiLink + 'getGeoPetiteRegionAgricole/' + idPRA +"/2")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataPRA(response.data)
                setDataPRA(prev => {
                  setLoadingPRA(false);
                  return prev;
                })
              })
            }
            else {
              fetch(apiLink + 'getGeoPetiteRegionAgricole/' + idPRA +"/1")
              .then( (response) => {
                return response.json()
              })
              .then(response =>{
                setDataPRA(response.data)
                setDataPRA(prev => {
                  setLoadingPRA(false);
                  return prev;
                })
              })
            }
          })
      } else {
        localStorage.clear()
        setGoToHome(true)
      }
  })
  }, [idExploit])

  if(goToHome) {
    return <Navigate push to={`/`} />
  }

  return (
    <div >
      <Entete titre="Risques rendement"/>
      <div className='container'>
        <div className='titrePlusVideo'>
          <div className='titre' style={{marginTop:('15px')}}> Quel est mon niveau de risques ? </div>
          <ButtonVidéos link={'https://www.youtube.com/watch?v=CdqjfSc6KIM&ab_channel=DiagoRisk'}/>
        </div>
        <div className='paragraphe'>Votre exploitation comparée aux {Taille} exploitations moyennes {GC ? " spécialisées en grandes cultures" : " mixte (viticulture + grandes cultures)"} des différentes petites régions agricoles, en % de perte par rapport au produit brut.</div>


        <div className='dispLigne'>
          <div className='dispLeftOpt'>
            <div style={{marginTop:'10px'}}>
              <Graph3Vagues dataPerso={dataPerso} dataVagues7={dataVagues7} dataVagues20={dataVagues20} dataVagues50={dataVagues50} maximum={maximum} dataQ1={dataQ1} dataQ2={dataQ2} dataQ3={dataQ3} GC={GC}/>
            </div>
            <div className='row axeX'>
              <div className='paragraphe' style={{width:'60%', marginLeft:'5%'}}>25% les moins exposées</div>
              <div className='paragraphe' style={{width:'35%'}}>50%</div>
              <div className='paragraphe' style={{width:'27.6%'}}>25% les plus exposées</div>
            </div>
          </div>
            
          <div className='dispRightOpt'>
            <div className='titreMoins'>Mon risque : </div>
            <div className='column marge'>
              <div className='videBloc row videBloc7'>
                <div className='pleinBloc row pleinBloc7'>
                  <div className='titreBloc'>Courant </div>
                </div>
                <div className='paragrapheBloc paragrapheBloc7'>{Math.round(Risques7*10)/10} % <span style={{fontWeight:'normal', fontSize:'70%'}}> du produit brut </span> </div>
              </div>

              <div className='videBloc row videBloc20'>
                <div className='pleinBloc row pleinBloc20'>
                  <div className='titreBloc'>Exceptionnel </div>
                </div>
                <div className='paragrapheBloc paragrapheBloc20'>{Math.round(Risques20*10)/10} % <span style={{fontWeight:'normal', fontSize:'70%'}}> du produit brut </span> </div>
              </div>

              <div className='videBloc row videBloc50'>
                <div className='pleinBloc row pleinBloc50'>
                  <div className='titreBloc'>Catastrophique </div>
                </div>
                <div className='paragrapheBloc paragrapheBloc50'> {Math.round(Risques50*10)/10} % <span style={{fontWeight:'normal', fontSize:'70%'}}> du produit brut </span> </div>
              </div>

            </div>
            <div className='paragraphe' style={{marginBottom:'10px'}} > <span style={{fontWeight:'1000px', fontSize:'30px', color:'red'}}>---</span> Le risque de votre exploitation rapport aux exploitations moyennes </div>
            <div className='paragraphe'> Le risque <span style={{color:'#FFC000'}}>"courant"</span> se produit de manière fréquente (tous les 7 ans environ) et à des effets peu importants sur la perte de rendement. </div>
            <div className='paragraphe'> Le risque <span style={{color:'#0027CC'}}>"exceptionnel"</span> se produit de manière un peu plus fréquente (tous les 20 ans environ) et à des effets plus importants sur la perte de rendement.  </div>
            <div className='paragraphe'> Le risque <span style={{color:'#DB04B9'}}>"catastrophique"</span> se produit de manière un peu fréquente (tous les 50 ans environ) et à des effets importants sur la perte de rendement.  </div>
            <div className='paragraphe' style={{fontWeight:'bold'}}> La base de comparaison se base soit sur des exploitations types à 100% grandes cultures soit sur des exploitations comprenant de la viticulture (avec ou sans grandes cultures)</div>
          </div>
          
        </div>
        <div className='titreMoins' style={{marginBottom:'10px'}}>Les risques moyens de ma région </div>
        <div className='paragraphe'style={{marginBottom:'5px'}}>Le point noir correspond à la localisation du siège social de votre exploitation  </div>
        <div className='paragraphe'style={{marginBottom:'20px'}}>La couleur de la carte corresponds au risque de perte en % de leur produit brut des exploitations types de chaque entité (petites régions agricoles, régions agricoles et département)  </div>
        <div className='blocCarte'>
          <div className='row flexLigne flexLigneBtnRdt'>
            <button className={BCou==='UnChecked' ? 'buttonResi buttonEntite buttonRep7Default rep buttonCarte' : 'buttonResi buttonEntite buttonRep7Checked rep buttonCarte'}  onClick={handlePressCourant} >
              Courant
            </button>
            <button className={BExc==='UnChecked' ? 'buttonResi buttonEntite  buttonRep20Default rep buttonCarte' : 'buttonResi buttonEntite  buttonRep20Checked rep buttonCarte'} onClick={handlePressExceptionnel}>
              Exceptionnel
            </button>
            <button className={BCat==='UnChecked' ? 'buttonResi buttonEntite  buttonRep50Default rep buttonCarte' : 'buttonResi buttonEntite  buttonRep50Checked rep buttonCarte'} onClick={handlePressCatastrophique}>
              Catastrophique
            </button>
            <button className='buttonResi buttonEntite  buttonLegendeCarte rep' onClick={() => setBpopupLeg(true)}>
              Légende
            </button>
          </div>
          <LegendeCarteRiskRDT trigger={BpopupLeg} setTrigger={setBpopupLeg}/>
          {dataPRA !== 0 && dataRA !== 0 && dataDEPT !== 0 && dataReg !== 0 ? (
          (TypeRisque==='Courant' && Echelle==='PRA') ? (<CarteRisquesPRACour data={dataPRA} loading={loadingPRA} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Exceptionnel' && Echelle==='PRA') ? (<CarteRisquesPRAExcep data={dataPRA} loading={loadingPRA} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Catastrophique' && Echelle==='PRA') ? (<CarteRisquesPRACata data={dataPRA} loading={loadingPRA} dataGE={dataGeoExploit}/>)  
            : (TypeRisque==='Courant' && Echelle==='RA') ? (<CarteRisquesRACour data={dataRA} loading={loadingRA} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Exceptionnel' && Echelle==='RA') ? (<CarteRisquesRAExcep data={dataRA} loading={loadingRA} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Catastrophique' && Echelle==='RA') ? (<CarteRisquesRACata data={dataRA} loading={loadingRA} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Courant' && Echelle==='DEPT') ? (<CarteRisquesDEPTCour data={dataDEPT} loading={loadingDept} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Exceptionnel' && Echelle==='DEPT') ? (<CarteRisquesDEPTExcep data={dataDEPT} loading={loadingDept} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Catastrophique' && Echelle==='DEPT') ? (<CarteRisquesDEPTCata data={dataDEPT} loading={loadingDept} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Courant' && Echelle==='REG') ? (<CarteRisquesREGCour data={dataReg} loading={loadingReg} dataGE={dataGeoExploit}/>) 
            : (TypeRisque==='Exceptionnel' && Echelle==='REG') ? (<CarteRisquesREGExcep data={dataReg} loading={loadingReg} dataGE={dataGeoExploit}/>) 
            : (<CarteRisquesREGCata data={dataReg} loading={loadingReg} dataGE={dataGeoExploit}/>) 
            ): (
              ""
            )}
          <div className='row flexLigne flexLigneBtnRdt'>
          <button className={Bpra==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'}  onClick={handlePressBpra} >
              Petite région agricole
            </button>
            <button className={Bra==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'} onClick={handlePressBra} >
              Région agricole
            </button>
            <button className={Bdept==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'}onClick={handlePressBdept} >
              Département
            </button>
          </div>  
        </div>
        
      </div>
      <ButtonQuestions/>
      <OADMenu idExploit={idExploit}/>
    </div>
  );
}

export default RisquesRdt;
