import React, {useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom'

import mail from '../../assets/mail.png'
import tel from '../../assets/tel.png'
import pin from '../../assets/pin-localisation-blanche.png'
import logo_PERQO from '../../assets/Logo-saison-perqo.jpg'
import feuille_DR from '../../assets/Feuille-v2.tigeRVB(fondvert).png'
import LI from '../../assets/LinkedIn.png'
import Pdf_CGU from '../../assets/PDF/2023-07-10_CGU.pdf'
import Pdf_CGV from '../../assets/PDF/2023-07-10_CGV.pdf'

function FooterSW() {
    const [goToML, setGoToML] = useState(false)
    const [goToPDD, setGoToPDD] = useState(false)
    const [goToHome, setGoToHome] = useState(false)
    const [goToDiag, setgoToDiag] = useState(false)
    const [goToBlog, setGoToBlog] = useState(false)
    const [goToProjets, setgoToProjets] = useState(false)
    const [goToQSN, setGoToQSN] = useState(false)
    const [goToLocalisation, setGoToLocalisation] = useState(false)
    const [goToConnexion, setGoToConnexion] = useState(false)
    const [goToSimulation, setGoToSimulation] = useState(false)
    const [goToDR50, setGoToDR50] = useState(false)
    const [idAgent, setidAgent] = useState();
    const [role, setrole] = useState();
    const url = window.location.href
    const root = window.location.origin

    useEffect(() => {
      const items = JSON.parse(localStorage.getItem('idAgent'));
      const items2 = JSON.parse(localStorage.getItem('role'));
      if (items) {
        setidAgent(items);
        setrole(items2)
      }
    }, []);
  
    function handleClickML(event) {
      event.preventDefault()
      setGoToML(true)
    }
  
    function handleClickPDD(event) {
      event.preventDefault()
      setGoToPDD(true)
    }
    function handleClickHome(event) {
      event.preventDefault()
      setGoToHome(true)
    }
    function handleClickDC(event) {
        event.preventDefault()
        setgoToDiag(true)
    }
    function handleClickProjets(event) {
        event.preventDefault()
        setgoToProjets(true)
    }
    function handleClickQSN(event) {
        event.preventDefault()
        setGoToQSN(true)
    }
    function handleClickBlog(event) {
        event.preventDefault()
        setGoToBlog(true)
    }
    function handleSubmitLoca(event) {
      event.preventDefault()
      setGoToLocalisation(true)
    }
    function handleSubmitAccesCompte(event) {
      event.preventDefault()
      if(idAgent !== undefined) {
        setGoToSimulation(true)
      }
      else {
        setGoToConnexion(true)
      }
    }
    function handleSubmitDR50(event) {
      event.preventDefault()
      setGoToDR50(true)
    }
    
    if(goToML) {
        return <Navigate push to={`/mentionslegales`} />
    }
    if(goToPDD) {
    return <Navigate push to={`/protectiondesdonnees`} />
    }
    if(goToHome) {
      return <Navigate push to={`/`} />
    }
    if(goToDiag) {
        return <Navigate push to={`/diagnostics_climatiques`} />
    }
    if(goToProjets) {
        return <Navigate push to={`/projets`} />
    }
    if(goToBlog) {
        return <Navigate push to={`/blog`} />
    }
    if(goToQSN) {
        return <Navigate push to={`/quisommesnous`} />
    }
    if(goToLocalisation) {
      return <Navigate push to={`/ma_localisation`} />
    }
    if(goToConnexion) {
      return <Navigate push to={`/connexion`} />
    }
    if(goToSimulation) {
      return <Navigate push to={`/simulations`}/>
    }
    if(goToDR50) {
      return <Navigate push to={`/diagorisk2050`}/>
    }
  
    return (
      <div>  
        <div className='footer' style={{color:'rgb(146, 208, 80)'}}>
          <div className='footer_gauche'>
            <div className='titreMoins' style={{marginBottom:'0px', marginTop:'5%'}} > Accès rapide :</div>
            <ul>
              <li> <button onClick={handleClickHome} className='paragraphe buttonSiteFooter' disabled={url===root+'/' ? true : false}> Accueil</button > </li>
              <li> <button onClick={handleClickDC} className='paragraphe buttonSiteFooter' disabled={url.indexOf("diagnostics_climatiques") > -1 ? true : false}> Diagnostics climatiques</button> </li>
              <li> <button onClick={handleClickProjets} className='paragraphe buttonSiteFooter' disabled={url.indexOf("projets") > -1 ? true : false}> Projets </button> </li>
              <li> <button onClick={handleClickQSN} className='paragraphe buttonSiteFooter' disabled={url.indexOf("quisommesnous") > -1 ? true : false}> Qui sommes-nous ? </button> </li>
              <li> <button onClick={handleClickBlog} className='paragraphe buttonSiteFooter' disabled={url.indexOf("blog") > -1 ? true : false}> Actus</button> </li>
            </ul>
          </div>
          <div className='footer_gauche_centre' style={{marginBottom:'0px', marginTop:'1%'}}>
              <div className='titreMoins' style={{marginBottom:'10px', marginTop:'0px'}} > Contact</div>
              
              <div className='row contactLeft '>
                <img src={mail} alt="icone mail" className="logo_contact"/>
                <a href='mailto:contact@diagorisk.com' className='paragraphe'> contact@diagorisk.com </a>
              </div>
              <div className='row contactLeft'>
                <img src={tel} alt="icone téléphone" className="logo_contact"/>
                <a href='tel:+33(0)6.85.48.01.87' className='paragraphe'> +33(0)6.85.48.01.87 </a>
              </div>
          </div>
          <div className='footer_centre'>
            <img src={pin} style={{marginTop:'1%'}} alt="Icone de localisation" className="logo_pin_Footer"/>
            <div className='titreMoins' style={{marginBottom:'0px', marginTop:'0px'}} > Le PERQO</div>
            <div className='paragraphe' style={{fontWeight:'bold'}}>Incubateur Région Ile-de-France </div>
            <div className='paragraphe'>2 rue Simone Veil </div>
            <div className='paragraphe' style={{marginBottom:'5px'}} >93400 Saint-Ouen-sur-Seine </div>
            <img src={logo_PERQO} alt="Logo du Perqo" className="logo_perqo_Footer"/>
            
          </div>
          <div className='footer_droit'>
            <div>
              <div className='titreMoins' style={{marginBottom:'0px', marginTop:'0px'}} > L'OAD :</div>
              <ul style={{marginBottom:"-20%"}}>
                <li> <button onClick={handleSubmitLoca} className='paragraphe buttonSiteFooter'> Découvrir DiagoRisk </button> </li>
                <li> <button onClick={handleSubmitAccesCompte} className='paragraphe buttonSiteFooter'> Accéder à mon compte </button> </li>
                <li> <button onClick={handleSubmitDR50} className='paragraphe buttonSiteFooter' style={{display:role === "ROLE_ADMINISTRATEUR" ? 'block': 'none'}}>  Diagorisk 2030 - 2050 </button> </li>
              </ul>
            </div>
            
            <div className='rowFooter'>
              <a href='https://www.linkedin.com/company/diagorisk/'  target='_blank' rel="noopener noreferrer" style={{width:'15%', marginBottom:'5%'}}><img src={LI} alt="Logo LinkedIn cliquable" className="logo_LI"/></a>
              <img src={feuille_DR} alt="Logo du Perqo" className="logo_feuille_Footer"/>
            </div>
          </div>
        </div>
  
        <div className='copyright'>
          <div style={{marginLeft:"10px"}} className='paragraphe'>Copyright © 2024 RisquesAgricoles SAS</div>
          <div className='row' style={{marginRight:"10px"}} >
            <button className='ButtonHome buttonFooter' onClick={handleClickML} > Mentions légales </button>
            <div className='buttonFooter'> | </div>
            <button className='ButtonHome buttonFooter' onClick={handleClickPDD} > Protection des données </button>
            <div className='buttonFooter'> | </div>
            <a href = {Pdf_CGU} className='ButtonHome buttonFooter' target='_blank' rel="noopener noreferrer">Conditions génrales d'utilisation </a>
            <div className='buttonFooter'>   | </div>
            <a href = {Pdf_CGV} className='ButtonHome buttonFooter' target='_blank' rel="noopener noreferrer">Conditions génrales de vente </a>
          </div>
        </div>
      </div>
    );
  }
  
  export default FooterSW;
  
  