import React from 'react';
import legend from '../../../assets/legendes-cartes.png'

function LegendeCarteRiskRDT(props) {

  return ( props.trigger) ? (
    <div className='popupLegend'>
        <div className='popup-inner'>
            <button className='close-btn' onClick={() => props.setTrigger(false)}>X</button>
            <img src={legend} alt="legend_carte" className="legendCarte_RiskRDT"/>
            {props.children}
        </div>

    </div>
  ) : "" ;
}

export default LegendeCarteRiskRDT;
