import React, {useState, useEffect} from 'react'
import Entete from '../MEP/entete'
import apiLink from '../../config';
import LegendeCarteRiskRDT2050 from '../OAD/Cartes/legendes_risques_2050';
import CarteRisquesCulture2050 from './cartes_risques_par_culture_2050';
import podium from '../../assets/podium.png'
import GEL from '../../assets/navig2050_gel_ac.png'
import EE from '../../assets/navig2050_exceseau_ac.png'
import SH from '../../assets/navig2050_SH_ac.png'
import CDC from '../../assets/navig2050_cdcl_ac.png'
import loading_Data from '../../assets/loading-Data.gif'

function Dr2050VueDEnsemble() {
  const [CP, setCP]= useState('');
  const [PBCp, setPBCp]= useState(false);
  const [city, setCity]= useState("1");
  const [ListVille, setList]= useState();
  const [ShowLeReste, setShowLeReste] = useState(false)

  const [ClickedBTH, setClickedBTH]= useState(true);
  const [ClickedBTP, setClickedBTP]= useState(false);
  const [ClickedBDH, setClickedBDH]= useState(false);
  const [ClickedBDP, setClickedBDP]= useState(false);
  const [ClickedOH, setClickedOH]= useState(false);
  const [ClickedOP, setClickedOP]= useState(false);
  const [ClickedMAIS, setClickedMAIS]= useState(false);
  const [ClickedCOLZA, setClickedCOLZA]= useState(false);
  const [ClickedVITI, setClickedVITI]= useState(false);

  const [CULTURE, setCULTURE]= useState('1113');
  const [ANNEE, setANNEE]= useState('2025');
  const [RCP, setRCP]= useState('4.5');
  const [GEOGRAPHIE, setGEOGRAPHIE]= useState('FRANCE');
  const [RISQUE, setRISQUE]= useState('Co');
  const [dataHierar, setDataHierar]= useState();
  const [dataPodium, setDataPodium]= useState();

  const [loadingEntite, setLoadingEntite] = useState(true);
  const[LoadingPodium,setLoadingPodium] = useState(false)

  const [dataGeo, setDataGeo] = useState(0)

  const [Bfrance, setBfrance]= useState('Checked')
  const [BfrancePRA, setBfrancePRA]= useState('UnChecked')
  const [Bra, setBra]= useState('UnChecked')
  const [Bdept, setBdept]= useState('UnChecked')
  const [Breg, setBreg]= useState('UnChecked')

  const [BCou, setBCou]= useState('Checked')
  const [BExc, setBExc]= useState('UnChecked')
  const [BCat, setBCat]= useState('UnChecked')
  const [BMoy, setBMoy]= useState('UnChecked')
  const [BRiskIrrig, setBRiskIrrig]= useState('Risk')
  const [BIrrigR, setBIrrigR]= useState('Checked')
  const [BIrrigP, setBIrrigP]= useState('UnChecked')

  const [B25, setB25]= useState('Checked')
  const [B30, setB30]= useState('UnChecked')
  const [B35, setB35]= useState('UnChecked')
  const [B40, setB40]= useState('UnChecked')
  const [B45, setB45]= useState('UnChecked')
  const [B50, setB50]= useState('UnChecked')

  const [B2D, setB2D]= useState('Checked')
  const [B4D, setB4D]= useState('UnChecked')
  const [BPodium, setBPodium]= useState('1113')


  const [BpopupLeg, setBpopupLeg]= useState(false)

  useEffect ( () => {
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=1&Culture=1112&Annee=2025&Rcp=4.5&Geographie=FRANCE&Risque=Co')
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
  }, [])

  function handlePressCourant() {
    setBCou('Checked')
    setBExc('UnChecked')
    setBCat('UnChecked')
    setBMoy('UnChecked')
    setRISQUE('Co')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Co')
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Co') 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }

  function handlePressExceptionnel() {
    setBExc('Checked')
    setBCou('UnChecked')
    setBCat('UnChecked')
    setBMoy('UnChecked')
    setRISQUE('Exc')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Exc')
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Exc') 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }

  function handlePressCatastrophique() {
    setBCat('Checked')
    setBCou('UnChecked')
    setBExc('UnChecked')
    setBMoy('UnChecked')
    setRISQUE('Ca')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Ca')
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Ca') 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePressMoyen() {
    setBCat('UnChecked')
    setBCou('UnChecked')
    setBExc('UnChecked')
    setBMoy('Checked')
    setRISQUE('Moy')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Moy')
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Moy') 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePressRisqueIrrig() {
    if(BRiskIrrig === 'Irrig'){
      setBRiskIrrig('Risk')
      setLoadingEntite(true)
      if (BCou === 'Checked'){
        setRISQUE('Co')
        fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Co')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataGeo(response.data)
          setDataGeo(prev => {
            setLoadingEntite(false);
            return prev;
          })
        })
        fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Co') 
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataPodium(response.podium)
          setDataPodium(prev => {
            return prev;
          })
          setDataHierar(response.table)
          setDataHierar(prev => {
            return prev;
          })
        })
      }
      else if (BExc === 'Checked'){
        setRISQUE('Exc')
        setLoadingEntite(true)
        fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Exc')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataGeo(response.data)
          setDataGeo(prev => {
            setLoadingEntite(false);
            return prev;
          })
        })
        fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Exc') 
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataPodium(response.podium)
          setDataPodium(prev => {
            return prev;
          })
          setDataHierar(response.table)
          setDataHierar(prev => {
            return prev;
          })
        })
      }
      else if (BCat === 'Checked'){
        setRISQUE('Ca')
        setLoadingEntite(true)
        fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Ca')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataGeo(response.data)
          setDataGeo(prev => {
            setLoadingEntite(false);
            return prev;
          })
        })
        fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Ca') 
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataPodium(response.podium)
          setDataPodium(prev => {
            return prev;
          })
          setDataHierar(response.table)
          setDataHierar(prev => {
            return prev;
          })
        })
      }
      else if (BMoy === 'Checked'){
        setRISQUE('Moy')
        setLoadingEntite(true)
        fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=Moy')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataGeo(response.data)
          setDataGeo(prev => {
            setLoadingEntite(false);
            return prev;
          })
        })
        fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=Moy') 
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataPodium(response.podium)
          setDataPodium(prev => {
            return prev;
          })
          setDataHierar(response.table)
          setDataHierar(prev => {
            return prev;
          })
        })
      }
    }
    else {
      if(CULTURE=== '600'){
        alert("Vous ne pouvez pas consulter l'irrigation pour la viticulture. Veuillez choisir une autre culture.")
      }
      else if (BIrrigP === 'Checked'){
        setRISQUE('IrrigP')
        setBRiskIrrig('Irrig')
        setLoadingEntite(true)
        fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=IrrigP')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataGeo(response.data)
          setDataGeo(prev => {
            setLoadingEntite(false);
            return prev;
          })
        })
        fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=IrrigP') 
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataPodium(response.podium)
          setDataPodium(prev => {
            return prev;
          })
          setDataHierar(response.table)
          setDataHierar(prev => {
            return prev;
          })
        })
      }
      else if (BIrrigR === 'Checked'){
        setRISQUE('IrrigR')
        setBRiskIrrig('Irrig')
        setLoadingEntite(true)
        fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=IrrigR')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataGeo(response.data)
          setDataGeo(prev => {
            setLoadingEntite(false);
            return prev;
          })
        })
        fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=IrrigR') 
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          setDataPodium(response.podium)
          setDataPodium(prev => {
            return prev;
          })
          setDataHierar(response.table)
          setDataHierar(prev => {
            return prev;
          })
        })
      }
    }    
  }
  function handlePressIrrigR() {
    setBIrrigR('Checked')
    setBIrrigP('UnChecked')
    setRISQUE('IrrigR')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=IrrigR')
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    setDataPodium()
    setDataHierar()
  }
  function handlePressIrrigP() {
    setBIrrigR('UnChecked')
    setBIrrigP('Checked')
    setRISQUE('IrrigP')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE+ '&Risque=IrrigP')
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    setDataPodium()
    setDataHierar()
  }

  function handlePressBfrance() {
    setBfrance('Checked')
    setBfrancePRA('UnChecked')
    setBra('UnChecked')
    setBdept('UnChecked')
    setBreg('UnChecked')
    setGEOGRAPHIE('FRANCE')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=FRANCE&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
  }
  function handlePressBFranceqPRA() {
    setBfrance('UnChecked')
    setBfrancePRA('Checked')
    setBra('UnChecked')
    setBdept('UnChecked')
    setBreg('UnChecked')
    setGEOGRAPHIE('PRA')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=PRA&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
  }
  
  function handlePressBra() {
    setBfrance('UnChecked')
    setBfrancePRA('UnChecked')
    setBra('Checked')
    setBdept('UnChecked')
    setBreg('UnChecked')
    setGEOGRAPHIE('RA')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=RA&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
  }
  
  function handlePressBdept() {
    setBfrance('UnChecked')
    setBfrancePRA('UnChecked')
    setBra('UnChecked')
    setBdept('Checked')
    setBreg('UnChecked')
    setGEOGRAPHIE('DEPT')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=DEPT&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
  }

  function handlePressBreg() {
    setBfrance('UnChecked')
    setBfrancePRA('UnChecked')
    setBra('UnChecked')
    setBdept('UnChecked')
    setBreg('Checked')
    setGEOGRAPHIE('REG')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=REG&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
  }

  function handleClickBTH(){
    setClickedBTH(true)
    setClickedBTP(false)
    setClickedBDH(false)
    setClickedBDP(false)
    setClickedOH(false)
    setClickedOP(false)
    setClickedMAIS(false)
    setClickedCOLZA(false)
    setClickedVITI(false)
    setCULTURE('1113')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=1113&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1113&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickBTP(){
    setClickedBTH(false)
    setClickedBTP(true)
    setClickedBDH(false)
    setClickedBDP(false)
    setClickedOH(false)
    setClickedOP(false)
    setClickedMAIS(false)
    setClickedCOLZA(false)
    setClickedVITI(false)
    setCULTURE('1112')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=1112&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1112&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickBDH(){
    setClickedBTH(false)
    setClickedBTP(false)
    setClickedBDH(true)
    setClickedBDP(false)
    setClickedOH(false)
    setClickedOP(false)
    setClickedMAIS(false)
    setClickedCOLZA(false)
    setClickedVITI(false)
    setCULTURE('1123')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=1123&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1123&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickBDP(){
    setClickedBTH(false)
    setClickedBTP(false)
    setClickedBDH(false)
    setClickedBDP(true)
    setClickedOH(false)
    setClickedOP(false)
    setClickedMAIS(false)
    setClickedCOLZA(false)
    setClickedVITI(false)
    setCULTURE('1122')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=1122&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1122&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickOH(){
    setClickedBTH(false)
    setClickedBTP(false)
    setClickedBDH(false)
    setClickedBDP(false)
    setClickedOH(true)
    setClickedOP(false)
    setClickedMAIS(false)
    setClickedCOLZA(false)
    setClickedVITI(false)
    setCULTURE('115')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=115&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=115&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickOP(){
    setClickedBTH(false)
    setClickedBTP(false)
    setClickedBDH(false)
    setClickedBDP(false)
    setClickedOH(false)
    setClickedOP(true)
    setClickedMAIS(false)
    setClickedCOLZA(false)
    setClickedVITI(false)
    setCULTURE('114')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=114&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=114&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickMAIS(){
    setClickedBTH(false)
    setClickedBTP(false)
    setClickedBDH(false)
    setClickedBDP(false)
    setClickedOH(false)
    setClickedOP(false)
    setClickedMAIS(true)
    setClickedCOLZA(false)
    setClickedVITI(false)
    setCULTURE('118')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=118&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=118&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickCOLZA(){
    setClickedBTH(false)
    setClickedBTP(false)
    setClickedBDH(false)
    setClickedBDP(false)
    setClickedOH(false)
    setClickedOP(false)
    setClickedMAIS(false)
    setClickedCOLZA(true)
    setClickedVITI(false)
    setCULTURE('222')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=222&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=222&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickVITI(){
    setClickedBTH(false)
    setClickedBTP(false)
    setClickedBDH(false)
    setClickedBDP(false)
    setClickedOH(false)
    setClickedOP(false)
    setClickedMAIS(false)
    setClickedCOLZA(false)
    setClickedVITI(true)
    setCULTURE('600')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture=600&Annee=' + ANNEE + '&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=600&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
    })
  }
  function handleClickPodiumBTH(){
    setBPodium('1113')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1113&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumBTP(){
    setBPodium('1112')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1112&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumBDH(){
    setBPodium('1123')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1123&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumBDP(){
    setBPodium('1122')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=1122&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumOH(){
    setBPodium('115')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=115&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumOP(){
    setBPodium('114')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=114&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumMAIS(){
    setBPodium('118')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=118&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumCOLZA(){
    setBPodium('222')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=222&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handleClickPodiumVITI(){
    setBPodium('600')
    setLoadingPodium(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city + '&Culture=600&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        setLoadingPodium(false)
        return prev;
      })
    })
  }
  function handlePress2025() {
    setB25('Checked')
    setB30('UnChecked')
    setB35('UnChecked')
    setB40('UnChecked')
    setB45('UnChecked')
    setB50('UnChecked')
    setANNEE('2025')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=2025&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE + '&Annee=2025&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePress2030() {
    setB25('UnChecked')
    setB30('Checked')
    setB35('UnChecked')
    setB40('UnChecked')
    setB45('UnChecked')
    setB50('UnChecked')
    setANNEE('2030')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=2030&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE + '&Annee=2030&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePress2035() {
    setB25('UnChecked')
    setB30('UnChecked')
    setB35('Checked')
    setB40('UnChecked')
    setB45('UnChecked')
    setB50('UnChecked')
    setANNEE('2035')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=2035&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE + '&Annee=2035&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePress2040() {
    setB25('UnChecked')
    setB30('UnChecked')
    setB35('UnChecked')
    setB40('Checked')
    setB45('UnChecked')
    setB50('UnChecked')
    setANNEE('2040')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=2040&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE + '&Annee=2040&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePress2045() {
    setB25('UnChecked')
    setB30('UnChecked')
    setB35('UnChecked')
    setB40('UnChecked')
    setB45('Checked')
    setB50('UnChecked')
    setANNEE('2045')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=2045&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE + '&Annee=2045&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePress2050() {
    setB25('UnChecked')
    setB30('UnChecked')
    setB35('UnChecked')
    setB40('UnChecked')
    setB45('UnChecked')
    setB50('Checked')
    setANNEE('2050')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=2050&Rcp=' + RCP + '&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE + '&Annee=2050&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePress2D() {
    setB2D('Checked')
    setB4D('UnChecked')
    setRCP('4.5')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=4.5&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=4.5&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handlePress4D() {
    setB2D('UnChecked')
    setB4D('Checked')
    setRCP('8.5')
    setLoadingEntite(true)
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp=8.5&Geographie=' + GEOGRAPHIE + '&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=8.5&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
  }
  function handleChange(event) {
    var CP = event.target.value
    setCP(CP)   
    fetch(apiLink + 'getCommunes/'+ CP)
      .then( (response) => {
        return response.json()
      })
      .then(response =>{
        setList(response.data)
        setList(prev => {
          prev = prev || []
          if (prev.length === 0 && CP.length>4){
            setPBCp(true)
          }
          else {
            setPBCp(false)
          }
          return prev;
        })
      }) 
  }
  function handleSubmit(event) {
    event.preventDefault()
    if (CULTURE ==='1112'){
      setBPodium('1112')
    }
    else if(CULTURE ==='1122'){
      setBPodium('1122')
    }
    else if(CULTURE ==='114'){
      setBPodium('114')
    }
    else if(CULTURE ==='115'){
      setBPodium('115')
    }
    else if(CULTURE ==='118'){
      setBPodium('118')
    }
    else if(CULTURE ==='222'){
      setBPodium('222')
    }
    else{
      setBPodium('1113')
    }
    setShowLeReste(true)
    fetch('https://api2050.diagorisk.com/hierarchisationCarto/?idCommune=' + city +  '&Culture='+ CULTURE +  '&Annee=' + ANNEE + '&Rcp=' + RCP + '&Risque=' + RISQUE) 
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataPodium(response.podium)
      setDataPodium(prev => {
        return prev;
      })
      setDataHierar(response.table)
      setDataHierar(prev => {
        return prev;
      })
    })
    setLoadingEntite(true)
    setGEOGRAPHIE('RA')
    setBfrance('UnChecked')
    setBfrancePRA('UnChecked')
    setBra('Checked')
    setBdept('UnChecked')
    setBreg('UnChecked')
    fetch('https://api2050.diagorisk.com/risqueCarto/?idCommune=' + city + '&Culture='+ CULTURE + '&Annee=' + ANNEE + '&Rcp='+ RCP + '&Geographie=RA&Risque=' + RISQUE)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataGeo(response.data)
      setDataGeo(prev => {
        setLoadingEntite(false);
        return prev;
      })
    })
  }
  function handleScrollSelect(event){
    var city = event.target.value
    setCity(city)

   }

   function sortTableData(tableData) {
    // Obtenir les clés de l'objet tableData
    const keys = Object.keys(tableData);
  
    // Trier les valeurs associées aux clés de manière croissante
    const sortedValues = Object.keys(tableData).sort((a, b) => tableData[a] - tableData[b]);
  
    // Créer un nouvel objet pour stocker les clés triées avec les valeurs triées
    const sortedTableData = {};
  
    // Assigner les clés triées avec les valeurs triées
    sortedValues.forEach((key, index) => {
        sortedTableData[(index + 1).toString()] = key;
    });
  
    return sortedTableData;
}

   function ColorConditionnelle(dataHierar, data, typeData){
    var tableData = {'1112': dataHierar[typeData][0]['1112'],'1113':dataHierar[typeData][0]['1113'],'1122':dataHierar[typeData][0]['1122'],'1123':dataHierar[typeData][0]['1123'], '114':dataHierar[typeData][0]['114'], '115':dataHierar[typeData][0]['115'],'118':dataHierar[typeData][0]['118'], '222': dataHierar[typeData][0]['222'], } //'600': dataHierar[typeData][0]['600']
    var tableClassee = sortTableData(tableData)

    if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '1') {
      return '#339A54'
    }
    else if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '2') {
      return '#60B856'
    }
    else if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '3') {
      return '#A6D96A'
    }
    else if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '4') {
      return '#D3EC95'
    }
    else if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '5') {
      return '#FFFFC0'
    }
    else if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '6') {
      return '#FED791'
    }
    else if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '7') {
      return '#FDAE61'
    }
    else if (Object.keys(tableClassee).find(key => tableClassee[key] === data) === '8') {
      return '#EA5733'
    }
    else {
      return '#D60004'
    }
    

   }

  return (
    <div >
      <Entete titre="DiagoRisk 2030 - 2050" Home={true} />

      <div className='container'>
        <div className='paragraphe'>Vous pouvez observer la différence de perte en terme de rendement avec la perte possible en moyenne entre 2016 et 2020 pour chaque culture, en jouant sur la gravité du risque, le scénarios climatique  et la taille de l’entité géographique concidérée.</div>

        <form onSubmit={handleSubmit} className='row' style={{justifyContent:'flex-start'}}>
          <div style={{width:'33%', marginRight:'5%'}}>
            <div className='titreMoins'> Saisissez votre code postal <span style={{color:'red', fontWeight:'normal'}}>{PBCp ? 'Ce code postal est incorrecte' : ''}</span></div>
            <input 
            className='input'
            maxLength="5"
            value={CP}
            onChange={handleChange}
            placeholder='Code Postal'
            type='text'
            required/>
          </div>
          <div style={{width:'33%'}}>
            <div className='titreMoins'> Sélectionnez votre ville </div>
            <select value={city} onChange={handleScrollSelect} required>
              <option value="">Choisissez</option>
              { Array.isArray(ListVille) ? ListVille.map((item)=>( <option key={item.key} value={item.key} > {item.libelle} </option> ) ) : ListVille===undefined ?  <option value={'pas de ville'}>  </option>  : <option value={ListVille.libelle}> {ListVille.libelle} </option> }
            </select>
          </div>
          <div className='row'  style={{width:'33%'}}>
            <button type='submit' className='validation'> Valider </button>
          </div>
        </form>

        {/* LISTE RA */}
        <div className='ligneMD'> 
          <div className={ClickedBTH ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_BT_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_BT_INAC'} onClick={handleClickBTH}><button className='ButtonHome paragraphe buttonOngletMD'> Blé tendre d'hiver </button></div>
          <div className={ClickedBTP? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_BT_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_BT_INAC'} onClick={handleClickBTP}><button className='ButtonHome paragraphe buttonOngletMD'> Blé tendre de printemps </button></div>
          <div className={ClickedOH ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC  ongletMD_OH_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_OH_INAC'} onClick={handleClickOH}><button className='ButtonHome paragraphe buttonOngletMD'> Orge d'hiver </button></div>
          <div className={ClickedOP ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_OP_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_OP_INAC'} onClick={handleClickOP}><button className='ButtonHome paragraphe buttonOngletMD'> Orge de printemps </button></div>
          <div className={ClickedBDH ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC  ongletMD_BD_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_BD_INAC'} onClick={handleClickBDH}><button className='ButtonHome paragraphe buttonOngletMD'> Blé dur d'hiver </button></div>
          <div className={ClickedBDP ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC  ongletMD_BD_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_BD_INAC'} onClick={handleClickBDP}><button className='ButtonHome paragraphe buttonOngletMD'>Blé dur de printemps</button></div>
          <div className={ClickedMAIS ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC'} onClick={handleClickMAIS}><button className='ButtonHome paragraphe buttonOngletMD'>Maïs </button></div>
          <div className={ClickedCOLZA ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_COLZA_AC' :'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_COLZA_INAC'} onClick={handleClickCOLZA}><button className='ButtonHome paragraphe buttonOngletMD'> Colza </button></div>
          <div className={ClickedVITI ? 
            (BRiskIrrig === 'Irrig' ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC onglet_DISABLE' : 'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_VITI_AC' ) :
            (BRiskIrrig === 'Irrig' ? 'paragraphe ongletMD_Cult_INAC ongletMD_INAC onglet_DISABLE' : 'paragraphe ongletMD_Cult_INAC ongletMD_INAC ongletMD_VITI_INAC')} ><button className='ButtonHome paragraphe buttonOngletMD' onClick={handleClickVITI} disabled={BRiskIrrig === 'Irrig' ? true : false}> Viticulture </button></div>
        </div>

        <div className='rowNumberCarte' style={{marginTop:'40px'}}>
          <div className='blocAnneeCartes row'>
          <button className={B25==='UnChecked' ? 'buttonResi buttonEntite buttonAnneeCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonAnneeCartesChecked rep buttonCarte'}  onClick={handlePress2025} >
              2025
            </button>
            <button className={B30==='UnChecked' ? 'buttonResi buttonEntite buttonAnneeCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonAnneeCartesChecked rep buttonCarte'}  onClick={handlePress2030} >
              2030
            </button>
            <button className={B35==='UnChecked' ? 'buttonResi buttonEntite buttonAnneeCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonAnneeCartesChecked rep buttonCarte'}  onClick={handlePress2035} >
              2035
            </button>
            <button className={B40==='UnChecked' ? 'buttonResi buttonEntite buttonAnneeCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonAnneeCartesChecked rep buttonCarte'}  onClick={handlePress2040} >
              2040
            </button>
            <button className={B45==='UnChecked' ? 'buttonResi buttonEntite buttonAnneeCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonAnneeCartesChecked rep buttonCarte'}  onClick={handlePress2045} >
              2045
            </button>
            <button className={B50==='UnChecked' ? 'buttonResi buttonEntite buttonAnneeCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonAnneeCartesChecked rep buttonCarte'}  onClick={handlePress2050} >
              2050
            </button>
          </div>
          <div className='BlocRCPCartes row'>
            <button className={B2D==='UnChecked' ? 'buttonResi buttonEntite buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonRCPCartesChecked rep buttonCarte'}  onClick={handlePress2D} >
              2°C
            </button>
            <button className={B4D==='UnChecked' ? 'buttonResi buttonEntite buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite buttonRCPCartesChecked rep buttonCarte'}  onClick={handlePress4D} >
              4°C
            </button>
          </div>
        </div>

        <div className='blocCarte'>
          <div className='row flexLigne flexLigneBtnRdt' style={{marginTop:'0px', marginBottom:'0px', flexWrap:'wrap', justifyContent:'space-between'}}>
            <div className='row flexLigne flexLigneBtnRdt switchcoloneLigne' >
              <button className={BCou==='UnChecked' ? 'buttonResi buttonEntite buttonRep7Default rep buttonCarte' : 'buttonResi buttonEntite buttonRep7Checked rep buttonCarte'}  onClick={handlePressCourant} disabled={BRiskIrrig === 'Irrig' ? true : false}>
                Courant
              </button>
              <button className={BExc==='UnChecked' ? 'buttonResi buttonEntite  buttonRep20Default rep buttonCarte' : 'buttonResi buttonEntite  buttonRep20Checked rep buttonCarte'} onClick={handlePressExceptionnel} disabled={BRiskIrrig === 'Irrig' ? true : false}>
                Exceptionnel
              </button>
              <button className={BCat==='UnChecked' ? 'buttonResi buttonEntite  buttonRep50Default rep buttonCarte' : 'buttonResi buttonEntite  buttonRep50Checked rep buttonCarte'} onClick={handlePressCatastrophique} disabled={BRiskIrrig === 'Irrig' ? true : false}>
                Catastrophique
              </button>
              <button className={BMoy==='UnChecked' ? 'buttonResi buttonEntite  buttonRepMoyDefault rep buttonCarte' : 'buttonResi buttonEntite  buttonRepMoyChecked rep buttonCarte'} onClick={handlePressMoyen} disabled={BRiskIrrig === 'Irrig' ? true : false}>
                Moyen
              </button>
            </div>
            <button style={{marginLeft:'-7px', background: BRiskIrrig === "Irrig" ? 'linear-gradient(to right, #D6D6D6 50%, #33BEFF 50%)' : 'linear-gradient(to right, #92D050 50%, #D6D6D6 50%)'}} className='buttonResi buttonEntite  buttonRisqueOUIrrigCartesChecked rep buttonCarte switchcoloneLigne' onClick={handlePressRisqueIrrig} >
              <div className='row rep' style={{marginBottom:'0px',width:'100%', color: '#616161', justifyContent:'space-between'}} >
                <div style={{ width:'50%'}}>Risque</div>
                <div style={{marginLeft:'5px', width:'50%'}}>Irrigation</div>
              </div>
            </button>
            <div className='row flexLigne flexLigneBtnRdt switchcoloneLigne' s>
              <button className={BIrrigR==='UnChecked' ? 'buttonResi buttonEntite  buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite  buttonRisqueOUIrrigCartesChecked rep buttonCarte'} onClick={handlePressIrrigR} disabled={BRiskIrrig === 'Risk' ? true : false}>
                Irrigation de résillience
              </button>
              <button className={BIrrigP==='UnChecked' ? 'buttonResi buttonEntite  buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite  buttonRisqueOUIrrigCartesChecked rep buttonCarte'} onClick={handlePressIrrigP} disabled={BRiskIrrig === 'Risk' ? true : false}>
                Irrigation de performance
              </button>
              <button className='buttonResi buttonEntite  buttonCarte rep' onClick={() => setBpopupLeg(true)} style={{backgroundColor:'#b5b5b5', marginBottom:'0px'}}>
                Légende
              </button>
            </div>
          </div>
          <LegendeCarteRiskRDT2050 trigger={BpopupLeg} setTrigger={setBpopupLeg} irrig={BRiskIrrig === 'Irrig'  ? true : false}/>
          {dataGeo !== 0 ? (
            <CarteRisquesCulture2050 data={dataGeo} loading={loadingEntite} irrig={BRiskIrrig === 'Irrig'  ? true : false}/> 
            ): (
              ""
            )}
          <div className='row flexLigne flexLigneBtnRdt'>
            <button className={Bfrance==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'}  onClick={handlePressBfrance} >
              France
            </button>
            <button className={BfrancePRA==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'}  onClick={handlePressBFranceqPRA} >
              France à la PRA
            </button>
            <button className={Breg==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'}  onClick={handlePressBreg} disabled={ShowLeReste ? false : true}>
              Région administrative
            </button>
            <button className={Bdept==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'}onClick={handlePressBdept} disabled={ShowLeReste ? false : true}>
              Département
            </button>
            <button className={Bra==='UnChecked' ? 'buttonResi buttonEntite rep buttonResiDefault' : 'buttonResi buttonEntite rep buttonCartoEntiteChecked'} onClick={handlePressBra} disabled={ShowLeReste ? false : true}>
              Région agricole
            </button>
          </div>  
        </div>
        <div className='paragraphe'> PRA = petite région agricole</div>

        {dataHierar !== undefined && dataPodium !== undefined ?(
        <div>
          <div className='titreMoins'> Bilan des différences de pertes en rendement potentiel entre {ANNEE} et la moyenne des pertes entre 2016 et 2020 pour votre région agricole  </div>
          <div className='paragraphe'>Cliquez sur l’une des cultures dans le tableau pour voir la classification de ses aléas, du plus impactant au moins impactant. Les données du podium ne sont pas en différence mais en pertes pour l'année {ANNEE}</div>
          <div className='row' style={{marginTop:'20px'}}>
            <div className='tableauCarteParCultureHierar'>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}> Valeur du risque  {RISQUE === "Co" ? 'courant' : RISQUE === "Ca" ? "catastrophique" : RISQUE === "Exc" ? 'exceptionnel' : 'moyen'} en {ANNEE} avec un réchauffement de + {RCP === '4.5' ? "2°C" : "4°C"} </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{fontWeight:'bold', height:'60px', textAlign:'center'}}> Rendement potentiel de 2022</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{fontWeight:'bold', height:'60px', textAlign:'center'}}> Variation de perte avec la moyenne 2016 à 2020 </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{fontWeight:'bold', height:'60px', textAlign:'center'}}> Variation en quantité </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{fontWeight:'bold', height:'60px', textAlign:'center'}}> Variation en euros</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '1113' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe' style={{ fontWeight:'bold', }} onClick={handleClickPodiumBTH}> Blé tendre d'hiver </button> </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['1113']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1113', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['1113']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1113', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['1113']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1113', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['1113']} €/ha</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '1112' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe'  style={{ fontWeight:'bold'}} onClick={handleClickPodiumBTP}> Blé tendre de printemps</button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['1112']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1112', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['1112']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1112', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['1112']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1112', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['1112']} €/ha</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '115' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe'  style={{ fontWeight:'bold'}} onClick={handleClickPodiumOH}> Orge d'hiver </button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['115']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '115', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['115']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '115', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['115']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '115', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['115']} €/ha</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '114' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe'  style={{ fontWeight:'bold'}} onClick={handleClickPodiumOP}> Orge de printemps </button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['114']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '114', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['114']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '114', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['114']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '114', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['114']} €/ha</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '1123' ? '#C5E0E9' : "white"}}><button className='ButtonHome paragraphe'  style={{ fontWeight:'bold'}} onClick={handleClickPodiumBDH}> Blé dur d'hiver </button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['1123']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1123', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['1123']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1123', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['1123']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1123', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['1123']} €/ha</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '1122' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe' style={{ fontWeight:'bold'}} onClick={handleClickPodiumBDP}> Blé dur de printemps </button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['1122']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1122', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['1122']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1122', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['1122']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '1122', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['1122']} €/ha</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '118' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe'  style={{ fontWeight:'bold'}} onClick={handleClickPodiumMAIS}> Maïs </button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['118']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '118', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['118']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '118', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['118']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '118', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['118']} €/ha</div>
              </div>
              <div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '222' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe'  style={{ fontWeight:'bold'}} onClick={handleClickPodiumCOLZA}> Colza </button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['222']} t/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '222', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['222']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '222', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['222']} t/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '222', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['222']} €/ha</div>
              </div>
              {/*<div className='row_tableau_prix_rapport'>
                  <div className='ordonnée_tableau_prix_rapport ordonne_2050CartesParCultureHierar' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px', backgroundColor: BPodium === '600' ? '#C5E0E9' : "white"}}> <button className='ButtonHome paragraphe'  style={{ fontWeight:'bold'}} onClick={handleClickPodiumVITI}> Viticulture </button>  </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierarRDT ' style={{height:'60px', textAlign:'center'}}> {dataHierar['RendementRPO'][0]['600']} hl/ha</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '600', 'PourcentageRDT')}}> {dataHierar['PourcentageRDT'][0]['600']} %</div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar ' style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '600', 'PerteTonne')}}> {dataHierar['PerteTonne'][0]['600']} hl/ha </div>
                  <div className='data_tableau_prix_rapport data_tableau_2050CartesParCultureHierar'style={{height:'60px', textAlign:'center', backgroundColor:ColorConditionnelle(dataHierar, '600', 'PourcentagePB')}}> {dataHierar['PourcentagePB'][0]['600']} €/ha</div> 
              </div>*/}

            </div>
            {LoadingPodium === false ? (
            <div className='row imagePodium' style={{justifyContent:'space-evenly', alignItems:'flex-start', backgroundImage: `url(${podium})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: '85%', position: 'relative', width: '600px', flexDirection:'column'}}> 
              <div className='titreMoins' style={{textAlign:'center', display:'flex', width:'100%',justifyContent:'center'}}> {BPodium === '1113' ? "Blé tendre d'hiver" : BPodium === '1112' ? "Blé tendre de printemps" : BPodium === '1123' ? "Blé dur d'hiver" : BPodium === '1122' ? "Blé dur de printemps" : BPodium === '114' ? "Orge de printemps" : BPodium === '115' ? "Orge d'hiver" : BPodium === '118' ? "Maïs" : BPodium === '222'?"Colza" : "Viticulture"} </div>
              <div style={{display:'flex',justifyContent:'space-between', alignItems:'flex-start', width:'100%'}}>
                <div className='elementspodium'>
                  <img src={dataPodium["2"][0]["alea"] === 'SH' ? SH : dataPodium["2"][0]["alea"] === 'EE' ? EE : dataPodium["2"][0]["alea"] === 'CDC' ? CDC : dataPodium["2"][0]["alea"] === 'GEL' ? GEL : ''} alt="icone" className="imageAleaPodium" style={{marginTop:'60px'}}/>
                  <div className='paragraphe'> {dataPodium["2"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodium["2"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodium["2"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodium["2"][0]["alea"] === 'GEL' ? "Gel" : ''} </div>
                </div>
                <div className='elementspodium'>
                  <img src={dataPodium["1"][0]["alea"] === 'SH' ? SH : dataPodium["1"][0]["alea"] === 'EE' ? EE : dataPodium["1"][0]["alea"] === 'CDC' ? CDC : dataPodium["1"][0]["alea"] === 'GEL' ? GEL : ''} alt="icone" className="imageAleaPodium" style={{marginTop:'0px'}}/>
                  <div className='paragraphe'> {dataPodium["1"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodium["1"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodium["1"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodium["1"][0]["alea"] === 'GEL' ? "Gel" : ''} </div>
                </div>
                <div className='elementspodium'>
                  <img src={dataPodium["3"][0]["alea"] === 'SH' ? SH : dataPodium["3"][0]["alea"] === 'EE' ? EE : dataPodium["3"][0]["alea"] === 'CDC' ? CDC : dataPodium["3"][0]["alea"] === 'GEL' ? GEL : ''} alt="icone" className="imageAleaPodium" style={{marginTop:'120px'}}/>
                  <div className='paragraphe'> {dataPodium["3"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodium["3"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodium["3"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodium["3"][0]["alea"] === 'GEL' ? "Gel" : ''} </div>
                </div>  
              </div>
              <div className='row' style={{marginTop:'27%', justifyContent:'space-evenly', width:'100%', textAlign:'center'}}>
                <div className='paragraphe' style={{width:'30%'}}> {dataPodium["2"][0]["perte"]} % </div>
                <div className='paragraphe' style={{width:'30%'}}> {dataPodium["1"][0]["perte"]} % </div>
                <div className='paragraphe' style={{width:'30%'}}> {dataPodium["3"][0]["perte"]} % </div>
              </div>
              <div style={{width:'100%', textAlign:'center'}}> Pertes en rendement potentiel</div>
            </div> 
            ) : 
            <div style={{width:'600px', display:'flex', justifyContent:'center', alignItems:'center'}}>
              <img src={loading_Data} alt="image de chargement" className=""/>
            </div>
          }
          </div>
        </div>
        ) : "" }

      </div>
    </div>
  )
}

export default Dr2050VueDEnsemble