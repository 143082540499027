import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GrapheColzaZI({DataConv, DataRobuste, DataZI, DataZIetRob,}) { 
    const getOptions = () => {
      let options = {
        xAxis: {
            type: 'category',
            data: ['Très courant', 'Courant', 'Exceptionnel', 'Catastrophique'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              interval: 0 // Forcer l'affichage de toutes les étiquettes de l'axe x
            }
          },
          legend : {
            data:['Conventionnel', 'Robuste', 'Zéro insecticide', 'Robuste et Zéro insecticide'],
            textStyle: {
              fontSize: 14 // Ajustez la taille de la police de la légende selon vos besoins
            },
          },
          yAxis: {
            type: 'value',
            name: 'Pertes en %',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          series: [
            {
              name: 'Conventionnel',
              data: DataConv,
              type: 'line',
              smooth: true,
              symbol: 'circle',
              symbolSize: 10,
              lineStyle: {
                width: 3, // Ajustez la largeur de la ligne selon vos besoins
              },
              itemStyle: {
                color: '#5878E5',
              }
            },
            {
                name: 'Robuste',
                data: DataRobuste,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width: 3, // Ajustez la largeur de la ligne selon vos besoins
                  },
                  itemStyle: {
                    color: '#03FE62',
                  }
              },
              {
                name: 'Zéro insecticide',
                data: DataZI,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                  width: 3, // Ajustez la largeur de la ligne selon vos besoins
                },
                itemStyle: {
                  color: '#8B0000',
                }
              },
              {
                name: 'Robuste et Zéro insecticide',
                data: DataZIetRob,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                  width: 3, // Ajustez la largeur de la ligne selon vos besoins
                },
                itemStyle: {
                  color: '#E3E300',
                }
              },
          ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom: "20px", width:  '100%', height: '500px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GrapheColzaZI;
  