import React, {useEffect} from 'react'
import {useLocation, Navigate} from 'react-router-dom'
import apiLink from '../../config'

import Entete from '../MEP/entete' 
import OADMenu from '../MEP/oadMenu'
import ButtonQuestions from '../MEP/button question'
import GraphAleas2 from './Graphiques/graphAleas_2'
import GraphAleasNBjours from './Graphiques/graphAleasNbjours'

import podium from '../../assets/podium.png'
import BleT from '../../assets/blé-tendre.png'
import BleD from '../../assets/blé-dur.png'
import Orge from '../../assets/orge.png'
import Mais from '../../assets/maïs.png'
import Colza from '../../assets/colza.png'
import Viti from '../../assets/viti.png'
import CultureNone from '../../assets/cultureNONE.png'

import loading_Data from '../../assets/loading-Data.gif'
import SH_ac from '../../assets/navig2050_SH_ac.png'
import EE_ac from '../../assets/navig2050_exceseau_ac.png'
import GEL_ac from '../../assets/navig2050_gel_ac.png'
import CDC_ac from '../../assets/navig2050_cdcl_ac.png'
import MR_ac from '../../assets/navig2050_manque_rayonnementl_ac.png'
import Podium_ac from '../../assets/navig2050_podium_ac.png'

import SH_inac from '../../assets/navig2050_SH_inac.png'
import EE_inac from '../../assets/navig2050_exceseau_inac.png'
import GEL_inac from '../../assets/navig2050_gel_inac.png'
import CDC_inac from '../../assets/navig2050_cdcl_inac.png'
import MR_inac from '../../assets/navig2050_manque_rayonnementl_inac.png'
import Podium_inac from '../../assets/navig2050_podium_inac.png'

function Aleas() {
  const { state } = useLocation();
  const { idExploit } = state;
  const [CityName, setCityName] = React.useState("")
  const [libellPRA, setLibellePRA] = React.useState("")
  const [BSH, setBSH] = React.useState(false)
  const [BEE, setBEE] = React.useState(false)
  const [BGEL, setBGEL] = React.useState(false)
  const [BCDC, setBCDC] = React.useState(false)
  const [BPodium, setBPodium] = React.useState(true)
  const [BMR, setBMR] = React.useState(false)
  const [BCou, setBCou]= React.useState('UnChecked')
  const [BExc, setBExc]= React.useState('Checked')
  const [BCat, setBCat]= React.useState('UnChecked')
  const [B2d, setB2d]= React.useState('Checked')
  const [B4d, setB4d]= React.useState('UnChecked')
  

  const [dataMoy_SH_2D, setDataMoy_SH_2D] =  React.useState()
  const [dataMoy_SH_4D, setDataMoy_SH_4D] =  React.useState()
  const [dataMin_SH_2D, setDataMin_SH_2D] =  React.useState()
  const [dataMin_SH_4D, setDataMin_SH_4D] =  React.useState()
  const [dataMax_SH_2D, setDataMax_SH_2D] =  React.useState()
  const [dataMax_SH_4D, setDataMax_SH_4D] =  React.useState()
  const [dataQ1_SH_2D, setDataQ1_SH_2D] =  React.useState()
  const [dataQ1_SH_4D, setDataQ1_SH_4D] =  React.useState()
  const [dataQ3_SH_2D, setDataQ3_SH_2D] =  React.useState()
  const [dataQ3_SH_4D, setDataQ3_SH_4D] =  React.useState()
  const [dataMoy_EE_2D, setDataMoy_EE_2D] =  React.useState()
  const [dataMoy_EE_4D, setDataMoy_EE_4D] =  React.useState()
  const [dataMin_EE_2D, setDataMin_EE_2D] =  React.useState()
  const [dataMin_EE_4D, setDataMin_EE_4D] =  React.useState()
  const [dataMax_EE_2D, setDataMax_EE_2D] =  React.useState()
  const [dataMax_EE_4D, setDataMax_EE_4D] =  React.useState()
  const [dataQ1_EE_2D, setDataQ1_EE_2D] =  React.useState()
  const [dataQ1_EE_4D, setDataQ1_EE_4D] =  React.useState()
  const [dataQ3_EE_2D, setDataQ3_EE_2D] =  React.useState()
  const [dataQ3_EE_4D, setDataQ3_EE_4D] =  React.useState()
  const [dataMoy_GEL_2D, setDataMoy_GEL_2D] =  React.useState()
  const [dataMoy_GEL_4D, setDataMoy_GEL_4D] =  React.useState()
  const [dataMin_GEL_2D, setDataMin_GEL_2D] =  React.useState()
  const [dataMin_GEL_4D, setDataMin_GEL_4D] =  React.useState()
  const [dataMax_GEL_2D, setDataMax_GEL_2D] =  React.useState()
  const [dataMax_GEL_4D, setDataMax_GEL_4D] =  React.useState()
  const [dataQ1_GEL_2D, setDataQ1_GEL_2D] =  React.useState()
  const [dataQ1_GEL_4D, setDataQ1_GEL_4D] =  React.useState()
  const [dataQ3_GEL_2D, setDataQ3_GEL_2D] =  React.useState()
  const [dataQ3_GEL_4D, setDataQ3_GEL_4D] =  React.useState()
  const [dataMoy_CDC_2D, setDataMoy_CDC_2D] =  React.useState()
  const [dataMoy_CDC_4D, setDataMoy_CDC_4D] =  React.useState()
  const [dataMin_CDC_2D, setDataMin_CDC_2D] =  React.useState()
  const [dataMin_CDC_4D, setDataMin_CDC_4D] =  React.useState()
  const [dataMax_CDC_2D, setDataMax_CDC_2D] =  React.useState()
  const [dataMax_CDC_4D, setDataMax_CDC_4D] =  React.useState()
  const [dataQ1_CDC_2D, setDataQ1_CDC_2D] =  React.useState()
  const [dataQ1_CDC_4D, setDataQ1_CDC_4D] =  React.useState()
  const [dataQ3_CDC_2D, setDataQ3_CDC_2D] =  React.useState()
  const [dataQ3_CDC_4D, setDataQ3_CDC_4D] =  React.useState()

  const [dataNbjoursMoy_SH_2D, setdataNbjoursMoy_SH_2D] =  React.useState()
  const [dataNbjoursMoy_SH_4D, setdataNbjoursMoy_SH_4D] =  React.useState()
  const [dataNbjoursSansRisque_SH_2D, setdataNbjoursSansRisque_SH_2D] =  React.useState()
  const [dataNbjoursSansRisque_SH_4D, setdataNbjoursSansRisque_SH_4D] =  React.useState()
  const [dataNbjoursMoy_EE_2D, setdataNbjoursMoy_EE_2D] =  React.useState()
  const [dataNbjoursMoy_EE_4D, setdataNbjoursMoy_EE_4D] =  React.useState()
  const [dataNbjoursSansRisque_EE_2D, setdataNbjoursSansRisque_EE_2D] =  React.useState()
  const [dataNbjoursSansRisque_EE_4D, setdataNbjoursSansRisque_EE_4D] =  React.useState()
  const [dataNbjoursMoy_GEL_2D, setdataNbjoursMoy_GEL_2D] =  React.useState()
  const [dataNbjoursMoy_GEL_4D, setdataNbjoursMoy_GEL_4D] =  React.useState()
  const [dataNbjoursSansRisque_GEL_2D, setdataNbjoursSansRisque_GEL_2D] =  React.useState()
  const [dataNbjoursSansRisque_GEL_4D, setdataNbjoursSansRisque_GEL_4D] =  React.useState()
  const [dataNbjoursMoy_CDC_2D, setdataNbjoursMoy_CDC_2D] =  React.useState()
  const [dataNbjoursMoy_CDC_4D, setdataNbjoursMoy_CDC_4D] =  React.useState()
  const [dataNbjoursSansRisque_CDC_2D, setdataNbjoursSansRisque_CDC_2D] =  React.useState()
  const [dataNbjoursSansRisque_CDC_4D, setdataNbjoursSansRisque_CDC_4D] =  React.useState()

  const [aleas, setaleas] = React.useState('SH')
  const [dataMin, setDataMin] = React.useState()
  const [dataQ1, setDataQ1] = React.useState()
  const [dataMoy, setDataMoy] = React.useState()
  const [dataQ3, setDataQ3] = React.useState()
  const [dataMax, setDataMax] = React.useState()
  const [dataNbjoursMoy, setDataNbjoursMoy] = React.useState()
  const [dataNbjoursSansRisque, setDataNbjoursSansRisque] = React.useState()

  const [ANNEE, setANNEE]= React.useState('2035');
  const [RCP, setRCP]= React.useState('4.5');
  const [RISQUE, setRISQUE]= React.useState('Exc');
  const [dataPodiumC, setDataPodiumC]= React.useState()
  const [dataPodiumA, setDataPodiumA]= React.useState();
  const [dataPodiumCA, setDataPodiumCA]= React.useState();

  function fetchData() {
    fetch('https://api2050.diagorisk.com/pageAleas/?idExploitation=' + idExploit)
      .then((response) => response.json())
      .then((response) => {
        if ('error' in response) {
          // Si la réponse contient une erreur, relancer la requête après 60 secondes
          setTimeout(fetchData, 60000);
        } else {
          // Si la réponse est réussie, mettre à jour les données
          setDataMoy_SH_2D(response.dataMoy_SH_2D)
          setDataMoy_SH_2D(prev => {
            setDataMoy(prev)
            return prev;
          })
          setDataMoy_SH_4D(response.dataMoy_SH_4D)
          setDataMoy_SH_4D(prev => {
            return prev;
          })
          setDataMin_SH_2D(response.dataMin_SH_2D)
          setDataMin_SH_2D(prev => {
            setDataMin(prev)
            return prev;
          })
          setDataMin_SH_4D(response.dataMin_SH_4D)
          setDataMin_SH_4D(prev => {
            return prev;
          })
          setDataMax_SH_2D(response.dataMax_SH_2D)
          setDataMax_SH_2D(prev => {
            setDataMax(prev)
            return prev;
          })
          setDataMax_SH_4D(response.dataMax_SH_4D)
          setDataMax_SH_4D(prev => {
            return prev;
          })
          setDataQ1_SH_2D(response.dataQ1_SH_2D)
          setDataQ1_SH_2D(prev => {
            setDataQ1(prev)
            return prev;
          })
          setDataQ1_SH_4D(response.dataQ1_SH_4D)
          setDataQ1_SH_4D(prev => {
            return prev;
          })
          setDataQ3_SH_2D(response.dataQ3_SH_2D)
          setDataQ3_SH_2D(prev => {
            setDataQ3(prev)
            return prev;
          })
          setDataQ3_SH_4D(response.dataQ3_SH_4D)
          setDataQ3_SH_4D(prev => {
            return prev;
          })
          setDataMoy_EE_2D(response.dataMoy_EE_2D)
          setDataMoy_EE_2D(prev => {
            return prev;
          })
          setDataMoy_EE_4D(response.dataMoy_EE_4D)
          setDataMoy_EE_4D(prev => {
            return prev;
          })
          setDataMin_EE_2D(response.dataMin_EE_2D)
          setDataMin_EE_2D(prev => {
            return prev;
          })
          setDataMin_EE_4D(response.dataMin_EE_4D)
          setDataMin_EE_4D(prev => {
            return prev;
          })
          setDataMax_EE_2D(response.dataMax_EE_2D)
          setDataMax_EE_2D(prev => {
            return prev;
          })
          setDataMax_EE_4D(response.dataMax_EE_4D)
          setDataMax_EE_4D(prev => {
            return prev;
          })
          setDataQ1_EE_2D(response.dataQ1_EE_2D)
          setDataQ1_EE_2D(prev => {
            return prev;
          })
          setDataQ1_EE_4D(response.dataQ1_EE_4D)
          setDataQ1_EE_4D(prev => {
            return prev;
          })
          setDataQ3_EE_2D(response.dataQ3_EE_2D)
          setDataQ3_EE_2D(prev => {
            return prev;
          })
          setDataQ3_EE_4D(response.dataQ3_EE_4D)
          setDataQ3_EE_4D(prev => {
            return prev;
          })
          setDataMoy_GEL_2D(response.dataMoy_GEL_2D)
          setDataMoy_GEL_2D(prev => {
            return prev;
          })
          setDataMoy_GEL_4D(response.dataMoy_GEL_4D)
          setDataMoy_GEL_4D(prev => {
            return prev;
          })
          setDataMin_GEL_2D(response.dataMin_GEL_2D)
          setDataMin_GEL_2D(prev => {
            return prev;
          })
          setDataMin_GEL_4D(response.dataMin_GEL_4D)
          setDataMin_GEL_4D(prev => {
            return prev;
          })
          setDataMax_GEL_2D(response.dataMax_GEL_2D)
          setDataMax_GEL_2D(prev => {
            return prev;
          })
          setDataMax_GEL_4D(response.dataMax_GEL_4D)
          setDataMax_GEL_4D(prev => {
            return prev;
          })
          setDataQ1_GEL_2D(response.dataQ1_GEL_2D)
          setDataQ1_GEL_2D(prev => {
            return prev;
          })
          setDataQ1_GEL_4D(response.dataQ1_GEL_4D)
          setDataQ1_GEL_4D(prev => {
            return prev;
          })
          setDataQ3_GEL_2D(response.dataQ3_GEL_2D)
          setDataQ3_GEL_2D(prev => {
            return prev;
          })
          setDataQ3_GEL_4D(response.dataQ3_GEL_4D)
          setDataQ3_GEL_4D(prev => {
            return prev;
          })
          setDataMoy_CDC_2D(response.dataMoy_CDC_2D)
          setDataMoy_CDC_2D(prev => {
            return prev;
          })
          setDataMoy_CDC_4D(response.dataMoy_CDC_4D)
          setDataMoy_CDC_4D(prev => {
            return prev;
          })
          setDataMin_CDC_2D(response.dataMin_CDC_2D)
          setDataMin_CDC_2D(prev => {
            return prev;
          })
          setDataMin_CDC_4D(response.dataMin_CDC_4D)
          setDataMin_CDC_4D(prev => {
            return prev;
          })
          setDataMax_CDC_2D(response.dataMax_CDC_2D)
          setDataMax_CDC_2D(prev => {
            return prev;
          })
          setDataMax_CDC_4D(response.dataMax_CDC_4D)
          setDataMax_CDC_4D(prev => {
            return prev;
          })
          setDataQ1_CDC_2D(response.dataQ1_CDC_2D)
          setDataQ1_CDC_2D(prev => {
            return prev;
          })
          setDataQ1_CDC_4D(response.dataQ1_CDC_4D)
          setDataQ1_CDC_4D(prev => {
            return prev;
          })
          setDataQ3_CDC_2D(response.dataQ3_CDC_2D)
          setDataQ3_CDC_2D(prev => {
            return prev;
          })
          setDataQ3_CDC_4D(response.dataQ3_CDC_4D)
          setDataQ3_CDC_4D(prev => {
            return prev;
          })
          setdataNbjoursMoy_SH_2D(response.dataNbjoursMoy_SH_2D)
          setdataNbjoursMoy_SH_2D(prev => {
            setDataNbjoursMoy(prev)
            return prev;
          })
          setdataNbjoursSansRisque_SH_2D(response.dataNbjoursSansRisque_SH_2D)
          setdataNbjoursSansRisque_SH_2D(prev => {
            setDataNbjoursSansRisque(prev)
            return prev;
          })
          setdataNbjoursMoy_SH_4D(response.dataNbjoursMoy_SH_4D)
          setdataNbjoursMoy_SH_4D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_SH_4D(response.dataNbjoursSansRisque_SH_4D)
          setdataNbjoursSansRisque_SH_4D(prev => {
            return prev;
          })
          setdataNbjoursMoy_EE_2D(response.dataNbjoursMoy_EE_2D)
          setdataNbjoursMoy_EE_2D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_EE_2D(response.dataNbjoursSansRisque_EE_2D)
          setdataNbjoursSansRisque_EE_2D(prev => {
            return prev;
          })
          setdataNbjoursMoy_EE_4D(response.dataNbjoursMoy_EE_4D)
          setdataNbjoursMoy_EE_4D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_EE_4D(response.dataNbjoursSansRisque_EE_4D)
          setdataNbjoursSansRisque_EE_4D(prev => {
            return prev;
          })
          setdataNbjoursMoy_GEL_2D(response.dataNbjoursMoy_GEL_2D)
          setdataNbjoursMoy_GEL_2D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_GEL_2D(response.dataNbjoursSansRisque_GEL_2D)
          setdataNbjoursSansRisque_GEL_2D(prev => {
            return prev;
          })
          setdataNbjoursMoy_GEL_4D(response.dataNbjoursMoy_GEL_4D)
          setdataNbjoursMoy_GEL_4D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_GEL_4D(response.dataNbjoursSansRisque_GEL_4D)
          setdataNbjoursSansRisque_GEL_4D(prev => {
            return prev;
          })
          setdataNbjoursMoy_CDC_2D(response.dataNbjoursMoy_CDC_2D)
          setdataNbjoursMoy_CDC_2D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_CDC_2D(response.dataNbjoursSansRisque_CDC_2D)
          setdataNbjoursSansRisque_CDC_2D(prev => {
            return prev;
          })
          setdataNbjoursMoy_CDC_4D(response.dataNbjoursMoy_CDC_4D)
          setdataNbjoursMoy_CDC_4D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_CDC_4D(response.dataNbjoursSansRisque_CDC_4D)
          setdataNbjoursSansRisque_CDC_4D(prev => {
            return prev;
          })
        }
      })
      .catch((error) => {
        // Gérer les erreurs de la requête
        console.error('Erreur lors de la requête API :', error);
      });
  }

  useEffect ( () => {
      fetch(apiLink + 'getLocalisation/' + idExploit)
      .then( (response) => {
        return response.json()
      })
      .then(response =>{
          var city =  response.idCommune
          fetch(apiLink + 'getCommune/' + city )
          .then( (response) => {
          return response.json()
          })
          .then(response =>{
          setLibellePRA(response.libellePetiteRegionAgricole)
          setLibellePRA(prev => {
              return prev;
          })
          var cp = response.codePostal
          var libellecity = response.libelleCommune
          setCityName(cp + ' - ' + libellecity)
          setCityName(prev => {
              return prev;
          })
          })
      }) 
      fetchData()
      fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2035&Rcp=4.5&Risque=Exc') 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    }, [idExploit])

  function handleClickSH(){
      setBSH(true)
      setBEE(false)
      setBGEL(false)
      setBCDC(false)
      setBMR(false)
      setBPodium(false)
      setaleas('SH')
      if (B2d === 'Checked'){
        setDataMoy(dataMoy_SH_2D)
        setDataMax(dataMax_SH_2D)
        setDataMin(dataMin_SH_2D)
        setDataQ1(dataQ1_SH_2D)
        setDataQ3(dataQ3_SH_2D)
        setDataNbjoursMoy(dataNbjoursMoy_SH_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_SH_2D)
      }
      else {
        setDataMoy(dataMoy_SH_4D)
        setDataMax(dataMax_SH_4D)
        setDataMin(dataMin_SH_4D)
        setDataQ1(dataQ1_SH_4D)
        setDataQ3(dataQ3_SH_4D)
        setDataNbjoursMoy(dataNbjoursMoy_SH_4D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_SH_4D)
      }
  }
  function handleClickEE(){
      setBSH(false)
      setBEE(true)
      setBGEL(false)
      setBCDC(false)
      setBMR(false)
      setBPodium(false)
      setaleas('EE')
      if (B2d === 'Checked'){
        setDataMoy(dataMoy_EE_2D)
        setDataMax(dataMax_EE_2D)
        setDataMin(dataMin_EE_2D)
        setDataQ1(dataQ1_EE_2D)
        setDataQ3(dataQ3_EE_2D)
        setDataNbjoursMoy(dataNbjoursMoy_EE_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_EE_2D)
      }
      else {
        setDataMoy(dataMoy_EE_4D)
        setDataMax(dataMax_EE_4D)
        setDataMin(dataMin_EE_4D)
        setDataQ1(dataQ1_EE_4D)
        setDataQ3(dataQ3_EE_4D)
        setDataNbjoursMoy(dataNbjoursMoy_EE_4D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_EE_4D)
      }
  }
  function handleClickGEL(){
      setBSH(false)
      setBEE(false)
      setBGEL(true)
      setBCDC(false)
      setBMR(false)
      setBPodium(false)
      setaleas('GEL')
      if (B2d === 'Checked'){
        setDataMoy(dataMoy_GEL_2D)
        setDataMax(dataMax_GEL_2D)
        setDataMin(dataMin_GEL_2D)
        setDataQ1(dataQ1_GEL_2D)
        setDataQ3(dataQ3_GEL_2D)
        setDataNbjoursMoy(dataNbjoursMoy_GEL_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_GEL_2D)
      }
      else {
        setDataMoy(dataMoy_GEL_4D)
        setDataMax(dataMax_GEL_4D)
        setDataMin(dataMin_GEL_4D)
        setDataQ1(dataQ1_GEL_4D)
        setDataQ3(dataQ3_GEL_4D)
        setDataNbjoursMoy(dataNbjoursMoy_GEL_4D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_GEL_4D)
      }
  }
  function handleClickCDC(){
      setBSH(false)
      setBEE(false)
      setBGEL(false)
      setBCDC(true)
      setBMR(false)
      setBPodium(false)
      setaleas('CDC')
      if (B2d === 'Checked'){
        setDataMoy(dataMoy_CDC_2D)
        setDataMax(dataMax_CDC_2D)
        setDataMin(dataMin_CDC_2D)
        setDataQ1(dataQ1_CDC_2D)
        setDataQ3(dataQ3_CDC_2D)
        setDataNbjoursMoy(dataNbjoursMoy_CDC_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_CDC_2D)
      }
      else {
        setDataMoy(dataMoy_CDC_4D)
        setDataMax(dataMax_CDC_4D)
        setDataMin(dataMin_CDC_4D)
        setDataQ1(dataQ1_CDC_4D)
        setDataQ3(dataQ3_CDC_4D)
        setDataNbjoursMoy(dataNbjoursMoy_CDC_4D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_CDC_4D)
      }
  }
  function handleClickMR(){
  }
  function handleClickPodium(){
    setBSH(false)
    setBEE(false)
    setBGEL(false)
    setBCDC(false)
    setBMR(false)
    setBPodium(true)
  }
  function handlePressCourant() {
    setBCou('Checked')
    setBExc('UnChecked')
    setBCat('UnChecked')
    setRISQUE('Co')
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=' + ANNEE +'&Rcp=' + RCP + '&Risque=Co') 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
  }
  function handlePressExceptionnel() {
    setBExc('Checked')
    setBCou('UnChecked')
    setBCat('UnChecked')
    setRISQUE('Exc')
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=' + ANNEE +'&Rcp=' + RCP + '&Risque=Exc') 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
  }
  function handlePressCatastrophique() {
    setBCat('Checked')
    setBCou('UnChecked')
    setBExc('UnChecked')
    setRISQUE('Ca')
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=' + ANNEE +'&Rcp=' + RCP + '&Risque=Ca') 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    
  }
  function handleB2D(){
    setB2d("Checked")
    setB4d("UnChecked")
    setRCP('4.5')
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=' + ANNEE +'&Rcp=4.5&Risque=' + RISQUE) 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
      if (aleas === 'SH'){
        setDataMoy(dataMoy_SH_2D)
        setDataMax(dataMax_SH_2D)
        setDataMin(dataMin_SH_2D)
        setDataQ1(dataQ1_SH_2D)
        setDataQ3(dataQ3_SH_2D)
        setDataNbjoursMoy(dataNbjoursMoy_SH_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_SH_2D)
      }
      else if (aleas === 'EE'){
        setDataMoy(dataMoy_EE_2D)
        setDataMax(dataMax_EE_2D)
        setDataMin(dataMin_EE_2D)
        setDataQ1(dataQ1_EE_2D)
        setDataQ3(dataQ3_EE_2D)
        setDataNbjoursMoy(dataNbjoursMoy_EE_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_EE_2D)
      }
      else if (aleas === 'GEL'){
        setDataMoy(dataMoy_GEL_2D)
        setDataMax(dataMax_GEL_2D)
        setDataMin(dataMin_GEL_2D)
        setDataQ1(dataQ1_GEL_2D)
        setDataQ3(dataQ3_GEL_2D)
        setDataNbjoursMoy(dataNbjoursMoy_GEL_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_GEL_2D)
      }
      else {
        setDataMoy(dataMoy_CDC_2D)
        setDataMax(dataMax_CDC_2D)
        setDataMin(dataMin_CDC_2D)
        setDataQ1(dataQ1_CDC_2D)
        setDataQ3(dataQ3_CDC_2D)
        setDataNbjoursMoy(dataNbjoursMoy_CDC_2D)
        setDataNbjoursSansRisque(dataNbjoursSansRisque_CDC_2D)
      }
  }
  function handleB4D(){
    setB2d("UnChecked")
    setB4d("Checked")
    setRCP('8.5')
    fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=' + ANNEE +'&Rcp=8.5&Risque=' + RISQUE) 
    .then((response) => response.json())
    .then((response) => {
      setDataPodiumA(response.dataPodiumA)
      setDataPodiumA(prev => {
        return prev;
      })
      setDataPodiumC(response.dataPodiumC)
      setDataPodiumC(prev => {
        return prev;
      })
      setDataPodiumCA(response.dataPodiumCA)
      setDataPodiumCA(prev => {
        return prev;
      })
    })
    if (aleas === 'SH'){
      setDataMoy(dataMoy_SH_4D)
      setDataMax(dataMax_SH_4D)
      setDataMin(dataMin_SH_4D)
      setDataQ1(dataQ1_SH_4D)
      setDataQ3(dataQ3_SH_4D)
      setDataNbjoursMoy(dataNbjoursMoy_SH_4D)
      setDataNbjoursSansRisque(dataNbjoursSansRisque_SH_4D)
    }
    else if (aleas === 'EE'){
      setDataMoy(dataMoy_EE_4D)
      setDataMax(dataMax_EE_4D)
      setDataMin(dataMin_EE_4D)
      setDataQ1(dataQ1_EE_4D)
      setDataQ3(dataQ3_EE_4D)
      setDataNbjoursMoy(dataNbjoursMoy_EE_4D)
      setDataNbjoursSansRisque(dataNbjoursSansRisque_EE_4D)
    }
    else if (aleas === 'GEL'){
      setDataMoy(dataMoy_GEL_4D)
      setDataMax(dataMax_GEL_4D)
      setDataMin(dataMin_GEL_4D)
      setDataQ1(dataQ1_GEL_4D)
      setDataQ3(dataQ3_GEL_4D)
      setDataNbjoursMoy(dataNbjoursMoy_GEL_4D)
      setDataNbjoursSansRisque(dataNbjoursSansRisque_GEL_4D)
    }
    else {
      setDataMoy(dataMoy_CDC_4D)
      setDataMax(dataMax_CDC_4D)
      setDataMin(dataMin_CDC_4D)
      setDataQ1(dataQ1_CDC_4D)
      setDataQ3(dataQ3_CDC_4D)
      setDataNbjoursMoy(dataNbjoursMoy_CDC_4D)
      setDataNbjoursSansRisque(dataNbjoursSansRisque_CDC_4D)
    }
  }
  function handleChangeAnneeComp(event){
    var annee = event.target.value
    setANNEE(annee)
    if (annee === '2025'){
      fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2025&Rcp=' + RCP + '&Risque=' + RISQUE) 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    }
    else if (annee === '2030'){
      fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2030&Rcp=' + RCP + '&Risque=' + RISQUE) 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    }
    else if (annee === '2035'){
      fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2035&Rcp=' + RCP + '&Risque=' + RISQUE) 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    }
    else if (annee === '2040'){
      fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2040&Rcp=' + RCP + '&Risque=' + RISQUE) 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    }
    else if (annee === '2045'){
      fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2045&Rcp=' + RCP + '&Risque=' + RISQUE) 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    }
    else if (annee === '2050'){
      fetch('https://api2050.diagorisk.com/podiumAleas/?idExploitation=' + idExploit + '&Annee=2050&Rcp=' + RCP + '&Risque=' + RISQUE) 
      .then((response) => response.json())
      .then((response) => {
        setDataPodiumA(response.dataPodiumA)
        setDataPodiumA(prev => {
          return prev;
        })
        setDataPodiumC(response.dataPodiumC)
        setDataPodiumC(prev => {
          return prev;
        })
        setDataPodiumCA(response.dataPodiumCA)
        setDataPodiumCA(prev => {
          return prev;
        })
      })
    }
  }

    return (
        <div>
            <Entete titre="Aléas"/>
            <div className='container'> 
                
                <div  className='containerAleas' style={{marginTop:'-10px'}}> 
                    <div className='titre' style={{textAlign:'center'}}> {BSH ? "Impact du stress hydrique  " : BEE ? " Impact d'un excès d'eau" : BGEL ? " Impact du gel" : BCDC ? "Impact des coups de chaleur ": BMR ? "Impact d'un manque de rayonnement": "Classement des impacts"} toutes productions confondues</div>
                    <div className='paragraphe' style={{textAlign:'center'}}> Commune : <span style={{fontWeight:'bold'}}>{CityName}</span></div>
                    <div className='paragraphe' style={{textAlign:'center'}}> Petite région agricole : <span style={{fontWeight:'bold'}}>{libellPRA}</span></div>
                </div>
                
                <div className='buttonsFixedAleas'> {/*mettre que c'est fixe */}
                    <div className={BPodium ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasPodium_AC': 'paragraphe ongletaléas_INAC OAléasPodium_INAC'} onClick={handleClickPodium}> 
                        <img src={BPodium ? Podium_ac: Podium_inac} alt='icone' className={BPodium ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BPodium ? (<div className='paragrapheAleas'>Classement impacts</div>) : ""}
                    </div>
                    <div className={BSH ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasSH_AC': 'paragraphe ongletaléas_INAC OAléasSH_INAC'} onClick={handleClickSH}> 
                        <img src={BSH ? SH_ac: SH_inac} alt='icone' className={BSH ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'} /> 
                        {BSH ? (<div className='paragrapheAleas'>Stress hydrique</div>) : ""}
                    </div>
                    <div className={BEE ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasEE_AC': 'paragraphe ongletaléas_INAC OAléasEE_INAC'} onClick={handleClickEE}> 
                        <img src={BEE ? EE_ac: EE_inac} alt='icone' className={BEE ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BEE ? (<div className='paragrapheAleas'>Excès d'eau</div>) : ""}
                    </div>
                    <div className={BGEL ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasGEL_AC': 'paragraphe ongletaléas_INAC OAléasGEL_INAC'} onClick={handleClickGEL}>   
                        <img src={BGEL ? GEL_ac: GEL_inac} alt='icone' className={BGEL ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BGEL ? (<div className='paragrapheAleas'>Gel</div>) : ""}
                    </div>
                    <div className={BCDC ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasCDC_AC': 'paragraphe ongletaléas_INAC OAléasCDC_INAC'} onClick={handleClickCDC}> 
                        <img src={BCDC ? CDC_ac: CDC_inac} alt='icone' className={BCDC ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BCDC ? (<div className='paragrapheAleas'>Coup de chaleur</div>) : ""}
                    </div>
                    {/* <div className={BMR ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasMR_AC': 'paragraphe ongletaléas_INAC OAléasMR_INAC'} onClick={handleClickMR}> 
                        <img src={BMR ? MR_ac: MR_inac} alt='icone'  className={BMR ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BMR ? (<div className='paragrapheAleas'>Manque de rayonnement</div>) : ""}
                    </div> */}
                    
                </div>

                {!BPodium ? dataMoy!== undefined && dataMax!== undefined && dataMin!== undefined && dataQ1!== undefined && dataQ3!== undefined  ? (
                  <div className='containerAleas'>
                    <div className='BlocRCPCartes row' style={{width:'100%'}}>
                      <button className={B2d==='UnChecked' ? 'buttonResi buttonEntite buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite button2050degreChecked rep buttonCarte'} style={{width:'10%'}} onClick={handleB2D} >
                        2°C
                      </button>
                      <button className={B4d==='UnChecked' ? 'buttonResi buttonEntite buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite button2050degreChecked rep buttonCarte'} style={{width:'10%'}} onClick={handleB4D} >
                        4°C
                      </button>
                    </div>
                    <div className='row ' style={{marginTop:'-20px'}}>
                      <GraphAleas2 myDataMoy={dataMoy} myDataMax={dataMax} myDataMin={dataMin} myDataQ1={dataQ1} myDataQ3={dataQ3}/>
                      <div className='titreMoins' style={{marginLeft:'30px'}}></div>
                    </div>
                    <div className='titreMoins' style={{textAlign:'center', marginTop:'-20px'}}>Fréquence des années favorables et défavorables </div>
                    <div className='row '>
                        <GraphAleasNBjours myDatasousMoy={dataNbjoursMoy} myDataSansRisque={dataNbjoursSansRisque} />
                        <div className='titreMoins' style={{marginLeft:'30px'}}></div>
                    </div>
                  </div>
                ) : (
                  <div className='containerAleas'>
                    <div className='containerAleas'>
                      <div className='row' style={{marginTop:'100px'}}>
                        <img src={loading_Data} alt='icone'  className=""/>
                      </div>
                      <div className='row' style={{marginTop:'100px'}}>
                        <img src={loading_Data} alt='icone'  className=""/>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='containerAleas'>
                    <div className='principal' style={{marginTop:'10px', marginBottom:'30px'}}>
                      <div className='row AleasPodium'>
                        <button className={BCou==='UnChecked' ? 'buttonResi buttonEntite buttonRep7Default rep buttonCarte' : 'buttonResi buttonEntite buttonRep7Checked rep buttonCarte'} style={{width:'33%'}}   onClick={handlePressCourant} >
                          Courant
                        </button>
                        <button className={BExc==='UnChecked' ? 'buttonResi buttonEntite  buttonRep20Default rep buttonCarte' : 'buttonResi buttonEntite  buttonRep20Checked rep buttonCarte'} style={{width:'33%'}}  onClick={handlePressExceptionnel}>
                          Exceptionnel
                        </button>
                        <button className={BCat==='UnChecked' ? 'buttonResi buttonEntite  buttonRep50Default rep buttonCarte' : 'buttonResi buttonEntite  buttonRep50Checked rep buttonCarte'} style={{width:'33%'}}  onClick={handlePressCatastrophique}>
                          Catastrophique
                        </button>
                      </div>
                      <div className='AnneePodium'>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                          <div className='paragraphe'>2025</div>
                          <div className='paragraphe'>2050</div>
                        </div>
                        <input style={{margin:'0px', height:'35px', width:'100%'}} type="range" id="cowbell" name="cowbell" min={2025} max={2050} value={ANNEE} step="5" onChange={handleChangeAnneeComp}/>
                        <div className='paragraphe' style={{marginLeft:ANNEE ==="2025" ? '0%' : ANNEE ==="2030" ? '15%' :  ANNEE ==="2035" ? "35%":  ANNEE ==="2040" ? "55%" :  ANNEE ==="2045" ? "75%" : "95%"}}> {ANNEE} </div>
                      </div>
                      <div className='row RCPPodium'>
                        <button className={B2d==='UnChecked' ? 'buttonResi buttonEntite buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite button2050degreChecked rep buttonCarte'}  onClick={handleB2D} >
                          2°C
                        </button>
                        <button className={B4d==='UnChecked' ? 'buttonResi buttonEntite buttonRCPCartesDefault rep buttonCarte' : 'buttonResi buttonEntite button2050degreChecked rep buttonCarte'}  onClick={handleB4D} >
                          4°C
                        </button>
                      </div>
                    </div>
                    {dataPodiumCA !== undefined ? (
                  <div className='row imagePodium PODIUMCA'> 
                    <div style={{display:'flex',justifyContent:'space-between', alignItems:'flex-start', width:'100%'}}>
                          {dataPodiumCA["2"] !== undefined ? ( 
                          <div className='elementspodium imagePodiumCA2'>
                            <div className='row'>
                              <img src={dataPodiumCA["2"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA["2"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA["2"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA["2"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodium" />
                              <img src={dataPodiumCA["2"][0]["culture"] === 1112 ||  dataPodiumCA["2"][0]["culture"] === 1113 ? BleT : dataPodiumCA["2"][0]["culture"] === 1122 ||  dataPodiumCA["2"][0]["culture"] === 1123 ? BleD : dataPodiumCA["2"][0]["culture"] === 114 ||  dataPodiumCA["2"][0]["culture"] === 115 ? Orge : dataPodiumCA["2"][0]["culture"] === 118 ? Mais : dataPodiumCA["2"][0]["culture"] === 222 ? Colza : (dataPodiumCA["2"][0]["culture"] === 630 || dataPodiumCA["2"][0]["culture"] === 670 || dataPodiumCA["2"][0]["culture"] === 675)? Viti : ''} alt='icone'  className="imageAleaPodium" />
                            </div>
                            <div className='paragraphe'> {dataPodiumCA["2"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA["2"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA["2"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA["2"][0]["alea"] === 'GEL' ? "Gel" : ''} / </div>
                            <div className='paragraphe'> {dataPodiumCA["2"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA["2"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA["2"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA["2"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA["2"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA["2"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA["2"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA["2"][0]["culture"] === 222 ? "Colza" : dataPodiumCA["2"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA["2"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA["2"][0]["culture"] === 675 ? "Viticulture VSIG" : ''} </div>
                          </div>
                          ) : (
                            <div className='elementspodium imageVidePodiumCA2'>
                              <img  src={CultureNone} alt='icone'  className="imageAleaPodium"/>
                              <div className='paragraphe'> </div>
                              <div className='paragraphe'> </div>
                            </div> 
                          )}
                          <div className='elementspodium imagePodiumCA1' >
                            <div className='row'>
                              <img src={dataPodiumCA["1"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA["1"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA["1"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA["1"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodium" />
                              <img src={dataPodiumCA["1"][0]["culture"] === 1112 ||  dataPodiumCA["1"][0]["culture"] === 1113 ? BleT : dataPodiumCA["1"][0]["culture"] === 1122 ||  dataPodiumCA["1"][0]["culture"] === 1123 ? BleD : dataPodiumCA["1"][0]["culture"] === 114 ||  dataPodiumCA["1"][0]["culture"] === 115 ? Orge : dataPodiumCA["1"][0]["culture"] === 118 ? Mais : dataPodiumCA["1"][0]["culture"] === 222 ? Colza : (dataPodiumCA["1"][0]["culture"] === 630 || dataPodiumCA["1"][0]["culture"] === 670 || dataPodiumCA["1"][0]["culture"] === 675) ? Viti : ''} alt='icone'  className="imageAleaPodium" />
                            </div>
                            <div className='paragraphe'> {dataPodiumCA["1"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA["1"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA["1"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA["1"][0]["alea"] === 'GEL' ? "Gel" : ''} / </div>
                            <div className='paragraphe'> {dataPodiumCA["1"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA["1"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA["1"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA["1"][0]["culture"] === '1123' ? "Blé dur d'hiver" : dataPodiumCA["1"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA["1"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA["1"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA["1"][0]["culture"] === 222 ? "Colza": dataPodiumCA["1"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA["1"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA["1"][0]["culture"] === 675 ? "Viticulture VSIG" : ''} </div>
                          </div>
                          {dataPodiumCA["3"] !== undefined ? ( 
                          <div className='elementspodium imagePodiumCA3' >
                            <div className='row'>
                              <img src={dataPodiumCA["3"][0]["alea"] === 'SH' ? SH_ac :dataPodiumCA["3"][0]["alea"] === 'EE' ? EE_ac :dataPodiumCA["3"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumCA["3"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodium" />
                              <img src={dataPodiumCA["3"][0]["culture"] === 1112 ||  dataPodiumCA["3"][0]["culture"] === 1113 ? BleT : dataPodiumCA["3"][0]["culture"] === 1122 ||  dataPodiumCA["3"][0]["culture"] === 1123 ? BleD : dataPodiumCA["3"][0]["culture"] === 114 ||  dataPodiumCA["3"][0]["culture"] === 115 ? Orge : dataPodiumCA["3"][0]["culture"] === 118 ? Mais : dataPodiumCA["3"][0]["culture"] === 222 ? Colza : (dataPodiumCA["3"][0]["culture"] === 630 || dataPodiumCA["3"][0]["culture"] === 670 || dataPodiumCA["3"][0]["culture"] === 675) ? Viti : ''} alt='icone'  className="imageAleaPodium" />
                            </div>
                            <div className='paragraphe'> {dataPodiumCA["3"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumCA["3"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumCA["3"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumCA["3"][0]["alea"] === 'GEL' ? "Gel" : ''} /</div>
                            <div className='paragraphe'> {dataPodiumCA["3"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumCA["3"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumCA["3"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumCA["3"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumCA["3"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumCA["3"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumCA["3"][0]["culture"] === 118 ? "Maïs" : dataPodiumCA["3"][0]["culture"] === 222 ? "Colza": dataPodiumCA["3"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumCA["3"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumCA["3"][0]["culture"] === 675 ? "Viticulture VSIG" : ''} </div>
                          </div> 
                          ) : (
                            <div className='elementspodium imageVidePodiumCA3'>
                              <img  src={CultureNone} alt='icone'  className="imageAleaPodium"/>
                              <div className='paragraphe'> </div>
                            </div> 
                          )}
                        </div>
                      <div className='row RDTPodiumCA' >
                      <div className='paragraphe' style={{width:'30%'}}> {dataPodiumCA['2']!== undefined ?dataPodiumCA["2"][0]["perte"]: "/"} % </div>
                      <div className='paragraphe' style={{width:'30%'}}> {dataPodiumCA["1"][0]["perte"]} % </div>
                      <div className='paragraphe' style={{width:'30%'}}> {dataPodiumCA["3"] !== undefined ? dataPodiumCA["3"][0]["perte"] : "/"} % </div>
                      </div>
                      <div className='titreMoins titrePodium'> Aléa et culture </div>
                    </div> 
                    ) : (
                      <div className='containerAleas'>
                        <div className='row' style={{marginTop:'100px'}}>
                          <img src={loading_Data} alt='icone'  className=""/>
                        </div>
                      </div>
                    )}
                    {dataPodiumC !== undefined && dataPodiumA !== undefined ? (
                    <div className='principal rowPODIUMCouA'>
                      <div className='row imagePodium PODIUMCouA'> 
                      <div style={{display:'flex',justifyContent:'space-between', alignItems:'flex-start', width:'100%'}}>
                        {dataPodiumC["2"] !== undefined ? ( 
                        <div className='elementspodium imagePodium_C_ou_A_2'>
                          <img  src={dataPodiumC["2"][0]["culture"] === 1112 ||  dataPodiumC["2"][0]["culture"] === 1113 ? BleT : dataPodiumC["2"][0]["culture"] === 1122 ||  dataPodiumC["2"][0]["culture"] === 1123 ? BleD : dataPodiumC["2"][0]["culture"] === 114 ||  dataPodiumC["2"][0]["culture"] === 115 ? Orge : dataPodiumC["2"][0]["culture"] === 118 ? Mais : dataPodiumC["2"][0]["culture"] === 222 ? Colza : (dataPodiumC["2"][0]["culture"] === 630 || dataPodiumC["2"][0]["culture"] === 670 || dataPodiumC["2"][0]["culture"] === 675) ? Viti : ''} alt='icone'  className="imageAleaPodium" />
                          <div className='paragraphe'> {dataPodiumC["2"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumC["2"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumC["2"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumC["2"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumC["2"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumC["2"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumC["2"][0]["culture"] === 118 ? "Maïs" : dataPodiumC["2"][0]["culture"] === 222 ? "Colza" : dataPodiumC["2"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumC["2"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumC["2"][0]["culture"] === 675 ? "Viticulture VSIG" : ''}</div>
                        </div>
                        ) : (
                          <div className='elementspodium imageVidePodium_C_ou_A_2'>
                            <img  src={CultureNone} alt='icone'  className="imageAleaPodium"/>
                            <div className='paragraphe'> </div>
                          </div> 
                        )}
                        <div className='elementspodium imagePodium_C_ou_A_1'> 
                          <img src={dataPodiumC["1"][0]["culture"] === 1112 ||  dataPodiumC["1"][0]["culture"] === 1113 ? BleT : dataPodiumC["1"][0]["culture"] === 1122 ||  dataPodiumC["1"][0]["culture"] === 1123 ? BleD : dataPodiumC["1"][0]["culture"] === 114 ||  dataPodiumC["1"][0]["culture"] === 115 ? Orge : dataPodiumC["1"][0]["culture"] === 118 ? Mais : dataPodiumC["1"][0]["culture"] === 222 ? Colza : (dataPodiumC["1"][0]["culture"] === 630 || dataPodiumC["1"][0]["culture"] === 670 || dataPodiumC["1"][0]["culture"] === 675) ? Viti : ''} alt='icone'  className="imageAleaPodium" />
                          <div className='paragraphe'> {dataPodiumC["1"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumC["1"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumC["1"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumC["1"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumC["1"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumC["1"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumC["1"][0]["culture"] === 118 ? "Maïs" : dataPodiumC["1"][0]["culture"] === 222 ? "Colza" : dataPodiumC["1"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumC["1"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumC["1"][0]["culture"] === 675 ? "Viticulture VSIG" : ''} </div>
                        </div>
                        {dataPodiumC["3"] !== undefined ? ( 
                        <div className='elementspodium imagePodium_C_ou_A_3'>
                          <img src={dataPodiumC["3"][0]["culture"] === 1112 ||  dataPodiumC["3"][0]["culture"] === 1113 ? BleT : dataPodiumC["3"][0]["culture"] === 1122 ||  dataPodiumC["3"][0]["culture"] === 1123 ? BleD : dataPodiumC["3"][0]["culture"] === 114 ||  dataPodiumC["3"][0]["culture"] === 115 ? Orge : dataPodiumC["3"][0]["culture"] === 118 ? Mais : dataPodiumC["3"][0]["culture"] === 222 ? Colza : (dataPodiumC["3"][0]["culture"] === 630 || dataPodiumC["3"][0]["culture"] === 670 || dataPodiumC["3"][0]["culture"] === 675) ? Viti : ''} alt='icone'  className="imageAleaPodium" />
                          <div className='paragraphe'> {dataPodiumC["3"][0]["culture"] === 1112 ? "Blé tendre de printemps" : dataPodiumC["3"][0]["culture"] === 1113 ? "Blé tendre d'hiver" : dataPodiumC["3"][0]["culture"] === 1122 ? "Blé dur de printemps" :  dataPodiumC["3"][0]["culture"] === 1123 ? "Blé dur d'hiver" : dataPodiumC["3"][0]["culture"] === 114 ? "Orge de printemps"  : dataPodiumC["3"][0]["culture"] === 115 ? "Orge d'hiver" : dataPodiumC["3"][0]["culture"] === 118 ? "Maïs" : dataPodiumC["3"][0]["culture"] === 222 ? "Colza" : dataPodiumC["3"][0]["culture"] === 630 ? "Viticulture AOC" : dataPodiumC["3"][0]["culture"] === 670 ? "Viticulture IGP" : dataPodiumC["3"][0]["culture"] === 675 ? "Viticulture VSIG" : ''} </div>
                        </div> 
                        ) : (
                          <div className='elementspodium imageVidePodium_C_ou_A_3'>
                            <img  src={CultureNone} alt='icone'  className="imageAleaPodium"/>
                            <div className='paragraphe'> </div>
                          </div> 
                        )}
                      </div>
                      <div className='row RDTPodium_C_ou_A'>
                        <div className='paragraphe' style={{width:'30%'}}> {dataPodiumC['2']!== undefined ?dataPodiumC["2"][0]["perte"]: "/"} % </div>
                        <div className='paragraphe' style={{width:'30%'}}> {dataPodiumC["1"][0]["perte"]} % </div>
                        <div className='paragraphe' style={{width:'30%'}}> {dataPodiumC["3"] !== undefined ? dataPodiumC["3"][0]["perte"] : "/"} % </div>
                      </div>
                      <div className='titreMoins titrePodium'> Cultures </div>
                    </div> 

                    <div className='row imagePodium PODIUMCouA'> 
                      <div style={{display:'flex',justifyContent:'space-between', alignItems:'flex-start', width:'100%'}}>
                        {dataPodiumA["2"] !== undefined ? ( 
                        <div className='elementspodium imagePodium_C_ou_A_2' >
                          <img src={dataPodiumA["2"][0]["alea"] === 'SH' ? SH_ac :dataPodiumA["2"][0]["alea"] === 'EE' ? EE_ac :dataPodiumA["2"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumA["2"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodium" />
                          <div className='paragraphe'> {dataPodiumA["2"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumA["2"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumA["2"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumA["2"][0]["alea"] === 'GEL' ? "Gel" : ''} </div>
                        </div>
                        ) : (
                          <div className='elementspodium imageVidePodium_C_ou_A_2'>
                            <img  src={CultureNone} alt='icone'  className="imageAleaPodium" />
                            <div className='paragraphe'> </div>
                          </div> 
                        )}
                        <div className='elementspodium imagePodium_C_ou_A_1'>
                          <img src={dataPodiumA["1"][0]["alea"] === 'SH' ? SH_ac :dataPodiumA["1"][0]["alea"] === 'EE' ? EE_ac :dataPodiumA["1"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumA["1"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodium" />
                          <div className='paragraphe'> {dataPodiumA["1"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumA["1"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumA["1"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumA["1"][0]["alea"] === 'GEL' ? "Gel" : ''} </div>
                        </div>
                        {dataPodiumA["3"] !== undefined ? ( 
                        <div className='elementspodium imagePodium_C_ou_A_3'>
                          <img src={dataPodiumA["3"][0]["alea"] === 'SH' ? SH_ac :dataPodiumA["3"][0]["alea"] === 'EE' ? EE_ac :dataPodiumA["3"][0]["alea"] === 'CDC' ? CDC_ac :dataPodiumA["3"][0]["alea"] === 'GEL' ? GEL_ac :''} alt='icone'  className="imageAleaPodium" />
                          <div className='paragraphe'> {dataPodiumA["3"][0]["alea"] === 'SH' ? "Stress hydrique" : dataPodiumA["3"][0]["alea"] === 'EE' ? "Excès d'eau" : dataPodiumA["3"][0]["alea"] === 'CDC' ? 'Coup de chaleur' : dataPodiumA["3"][0]["alea"] === 'GEL' ? "Gel" : ''} </div>
                        </div>  
                        ) : (
                          <div className='elementspodium imageVidePodium_C_ou_A_3'>
                            <img  src={CultureNone} alt='icone'  className="imageAleaPodium" />
                            <div className='paragraphe'> </div>
                          </div> 
                        )}
                        </div>
                        <div className='row RDTPodium_C_ou_A'>
                          <div className='paragraphe' style={{width:'30%'}}> {dataPodiumA['2']!== undefined ?dataPodiumA["2"][0]["perte"]: "/"} % </div>
                          <div className='paragraphe' style={{width:'30%'}}> {dataPodiumA["1"][0]["perte"]} % </div>
                          <div className='paragraphe' style={{width:'30%'}}> {dataPodiumA["3"] !== undefined ? dataPodiumA["3"][0]["perte"] : "/"} % </div>
                        </div>
                      <div className='titreMoins titrePodium'> Aléas </div>
                    </div> 
                  </div>
                  ) : (
                    <div className='containerAleas'>
                      <div className='row' style={{marginTop:'100px'}}>
                        <img src={loading_Data} alt='icone'  className=""/>
                      </div>
                      <div className='row' style={{marginTop:'100px'}}>
                        <img src={loading_Data} alt='icone'  className=""/>
                      </div>
                    </div>
                  )}
                  
                </div>
                )}
            </div>
            <ButtonQuestions/>
            <OADMenu idExploit={idExploit}/>
        </div>
    )
}

export default Aleas