import React from 'react';
import Nav from '../MEP/SWNav';
import {Navigate} from 'react-router-dom'
import FooterSW from '../MEP/footer_SW';
import viti from '../../assets/cartes-changement-climaique-viticulture.png'
import methodo from '../../assets/process-diagorisk.png'
import tablette from '../../assets/tablette_territorial2.png'
import pc from '../../assets/OAD-diagorisk-individuel.png'

function DiagnosticsClimatiques() {
  const [goToContact, setGoToContact] = React.useState(false)
  function handleContacter(event){
    event.preventDefault()
    setGoToContact(true)
  }
  if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
  }
  return (
    <div>
      <Nav/>
      <div className="containerSW" >
        <h1 className="propositionValeur titreSW">Territorial ou individuel, faites votre diagnostic climatique avec DiagoRisk</h1>
        <img src={viti} alt="cartes risques du vignoble français" style={{marginLeft:'15%', width:'70%'}}/>

        <div className='principal' style={{marginTop:'20px'}}>
            <div className='bottom bottom2' style={{marginTop:'0px'}} >
              <div className='paragraphe paragrapheLanding' style={{marginBottom:'10px'}}>Réaliser votre Diagnostic Climat avec l’ADEME à l’échelle de votre territoire ou de votre exploitation.  </div>
              <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>L’ADEME finance 80 % des diagnostics Climat et l’élaboration de votre stratégie collective d’adaptation au changement climatique.</div>
              <div className='paragraphe paragrapheLanding'>Nous vous accompagnons dans cette démarche selon vos besoins :</div>
              <ul className='paragraphe paragrapheLanding'>
                <li> <div className='paragraphe paragrapheLanding lila'>Réaliser un diagnostic territorial</div></li>
                <li> <div className='paragraphe paragrapheLanding lila'>Animer la conception de votre stratégie d’adaptation</div></li>
                <li> <div className='paragraphe paragrapheLanding lila'>Décliner cette stratégie chez chacun de vos partenaires agriculteurs, en tenant compte de leurs spécificités.</div></li>
              </ul>
              <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px'}}>Notre démarche s’appuie sur notre outil de modélisation, les recommandations du Guide ADEME (dont nous sommes coauteurs) et l’accompagnement de plusieurs filières.</div>
              <div className='row' style={{width:'100%'}}>
                <div className='paragraphe paragrapheLanding'>Pour en savoir plus : </div>
                {/* <a href='https://agirpourlatransition.ademe.fr/entreprises/aides-financieres/20240322/accompagnement-agriculteurs-face-changement-climatique-action-0?cible=79'  target='_blank' rel="noopener noreferrer" style={{width:'20%'}}><img src={ademe} alt='Logo ADEME' style={{width:'70%', marginLeft:'5px'}}/> </a> */}
                <button className='validation validationAccueil' onClick={handleContacter}>Contactez-nous</button>
              </div>
            </div>
            <div className='top topBBis' style={{display:'flex', justifyContent:'center'}}>
               <img src={methodo} alt='méthode de diagorisk'  className='method'/>
            </div>
          </div>

          
          <h2 className='sous-Titre-SW'> Diagnostic collectif et territorial</h2>
          <div className="principal" >
            <div className="bottom" style={{marginTop:'0px', marginBottom:'0px'}}>
              <img src={tablette} alt='OAD sur tablette tactile"'style={{width:'100%'}}/>
            </div>
            <div className='top top1' style={{marginTop:'0px'}}>
              <div style={{width:'90%', margin:'5%'}}>
                <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px', fontWeight:'bold'}}>Filières et territoires : </div>
                <div className='paragraphe paragrapheLanding'> Anticiper les évolutions climatiques et sanitaires :</div>
                <ol className='paragraphe paragrapheLanding' style={{marginTop:'0px'}}>
                  <li> <div className='paragraphe paragrapheLanding lila'>Objectiver les enjeux et les incertitudes majeures</div></li>
                  <li> <div className='paragraphe paragrapheLanding lila'>Localiser les impacts pour chaque maillon</div></li>
                  <li> <div className='paragraphe paragrapheLanding lila'>S’organiser pour pérenniser </div></li>
                </ol>
                <div className='paragraphe paragrapheLanding' style={{marginBottom:'15px', fontWeight:'bold'}}>IAA, Coop et négoces :</div>
                <div className='paragraphe paragrapheLanding'> Sécuriser son activité à court et moyen termes :</div>
                <ol className='paragraphe paragrapheLanding' style={{marginTop:'0px'}}>
                  <li> <div className='paragraphe paragrapheLanding lila'>Objectiver les variabilités et le coût de l’inaction</div></li>
                  <li> <div className='paragraphe paragrapheLanding lila'>Sécuriser ses approvisionnements et sa collecte</div></li>
                  <li> <div className='paragraphe paragrapheLanding lila'>Investir au bon moment, au bon endroit</div></li>
                  <li> <div className='paragraphe paragrapheLanding lila'>Mobiliser ses partenaires, adhérents, fournisseurs par un juste partage des risque</div></li>
                </ol>
              </div>
            </div>
          </div>

          <h2 className='sous-Titre-SW'> Diagnostic individuel</h2>
          <div className="principal" >
            <div className="bottom" style={{marginTop:'0px'}}>
              <img src={pc} alt='OAD sur PC portable' style={{width:'100%'}}/>
            </div>
            <div className='top top1' style={{marginTop:'0px'}}>
              <div style={{width:'90%', margin:'5%'}}>
                <div className='paragraphe paragrapheLanding'> Faire face, saisir les opportunités :</div>
                <ol className='paragraphe paragrapheLanding' style={{marginTop:'0px'}}>
                  <li> <div className='paragraphe paragrapheLanding lila' style={{marginTop:'20px'}}>Optimiser son assolement et sa gestion des risques</div></li>
                  <li> <div className='paragraphe paragrapheLanding lila' style={{marginTop:'20px'}}>Choisir les solutions les plus efficaces face au changement climatique et aux transitions </div></li>
                  <li> <div className='paragraphe paragrapheLanding lila' style={{marginTop:'20px'}}>Avoir une stratégie Climat–Carbone-Sol cohérente </div></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      <FooterSW/>
    </div>
  );
}

export default DiagnosticsClimatiques;

