import React from 'react';
import Nav from '../MEP/SWNav';
import FooterSW from '../MEP/footer_SW';

function ProtectionsDesDonnes() {
  return (
    <div className='paragraphe'>
      <Nav/>
      <div className="containerSW" >
      <h1 className="propositionValeur titreSW">Protection des données</h1>        
        <div className='titre'> Vos données sont sacrées ! </div>
        <div className='paragraphe' > Pour DIAGORISK, la sécurité et la confidentialité de vos données personnelles sont l’objet d’un soin constant. Vous trouverez plus bas dans cette page les détails sur la façon dont nous gérons les données que vous nous confiez, mais on peut résumer notre philosophie en quelques points simples : </div>
        <ul>
          <li> <div className='paragraphe lila'>  Nous ne recueillons que les données strictement nécessaires, </div> </li>
          <li> <div className='paragraphe lila'>  Leur transmission est totalement sécurisée, </div> </li>
          <li> <div className='paragraphe lila'>  Nous vous demandons votre consentement explicite pour leur traitement chaque fois que c’est nécessaire, </div> </li>
          <li> <div className='paragraphe lila'>  Vos données sensibles ne sont exploitées que par du personnel spécialement formé </div> </li>
        </ul>
        <div className='paragraphe' >En bref, la gestion des données par DIAGORISK respecte le Règlement Général sur la Protection des Données (RGPD). Voyons tout cela dans le détail !</div>

        <div className='titre'>Qu’est-ce que le RGPD</div>
        <div className='paragraphe' >Le règlement (UE) 2016 /679 du Parlement Européen et du Conseil du 27 avril 2016 (RGPD) est applicable depuis le 25 mai 2018. Sa finalité est d’assurer une protection des données personnelles des personnes physiques. Il remplace la directive européenne 95/46/CE sur la protection des données personnelles.</div>
        <div className='paragraphe' >On entend par donnée personnelle, toute information se rapportant directement ou indirectement à une personne physique. Exemples : vos nom, prénom, date de naissance, numéro de téléphone, adresse mail ou postale, identifiant numérique, etc.</div>
        <div className='paragraphe' >Par ailleurs, on parle de traitement des données personnelles à partir du moment où une donnée personnelle est utilisée informatiquement ou manuellement par le biais d’opérations telle que la collecte, l’enregistrement, la conservation, la modification, la consultation, la diffusion ou l’effacement de données personnelles.</div>

        <div className='titre'>Qui traite vos données ?</div>
        <div className='paragraphe' >C’est DIAGORISK qui collecte et qui opère le traitement de vos données personnelles. C’est donc auprès de DIAGORISK que vous pourrez exercer vos droits – évoqués plus bas - d’accès, de correction, de portabilité et de suppression de ces données ou de limitation de leurs traitements.</div>

        <div className='titre'>La finalité de la collecte de vos données personnelles</div>
        <div className='paragraphe' >Nous recueillons vos données personnelles dans plusieurs buts :</div>
        <ul>
          <li> <div className='paragraphe lila'>Pour vous procurer des propositions d’assurance emprunteur et des services adaptés.</div> </li>
          <li> <div className='paragraphe lila'>Puis, si vous en êtes d’accord, pour vous permettre la souscription d’un contrat d’assurance emprunteur et pour nous permettre de le gérer et d’exécuter les garanties de vos contrats.</div> </li>
          <li> <div className='paragraphe lila'>Pour permettre l’exercice des recours, la gestion des réclamations, des contentieux dans le cadre de votre contrat.</div> </li>
          <li> <div className='paragraphe lila'>Pour suivre la commercialisation de nos contrats. </div> </li>
          <li> <div className='paragraphe lila'>Pour suivre les risques liés à ces contrats et élaborer des études marketing et actuarielles.</div> </li>
          <li> <div className='paragraphe lila'>Pour les opérations de lutte contre la fraude. </div> </li>
          <li> <div className='paragraphe lila'>Pour respecter nos obligations légales et réglementaires ainsi que celles de l’assureur.</div> </li>
        </ul>
        <div className='titre'>Les destinataires de vos données personnelles</div>
        <div className='paragraphe' >Vos données personnelles sont susceptibles d’être transmises uniquement aux parties prenantes de la distribution et de la gestion de votre contrat et uniquement pour la partie des données qui les concernent (distributeur, assureur, réassureur, et prestataires de service du contrat), exclusivement sur le territoire de l’Union Européenne.</div>

        <div className='titre'> Votre consentement </div>
        <div className='paragraphe' >Toutes les données que nous collectons auprès de vous sont absolument nécessaires à l’exécution des tâches décrites ci-dessus. De même, tous les traitements de données que nous effectuons sont eux-aussi nécessaires à l’exécution de ces tâches.</div>
        <div className='paragraphe' >Ainsi, à chaque fois que cela sera nécessaire et au moment de la collecte de vos données, nous vous demanderons explicitement votre consentement, sous la forme d’une case à cocher. Si vous oubliez de cocher cette case, un message du type « Les données que nous souhaitons collecter sont indispensables au traitement de votre demande depuis ce site » s’affichera, et vous choisirez de poursuivre ou non vos démarches sur notre site.</div>

        <div className='titre'>Vos droits</div>
        <div className='paragraphe' >Le RGPD prévoit pour vous, au regard de ces données personnelles, des droits d’accès, de rectification, d’effacement, de limitation, de portabilité et d’opposition.</div>
        <div className='paragraphe' >Dans le cas où votre consentement était requis au traitement de vos données personnelles, vous disposez du droit de retirer votre consentement à tout moment.</div>
        <div className='paragraphe' >Pour en savoir plus, rendez-vous sur le site <a className='a_corps_SW' href='www.cnil.fr'> www.cnil.fr </a></div>
        <div className='paragraphe' >Par ailleurs, nous vous informons que vous pouvez vous inscrire gratuitement sur la liste d’opposition au démarchage téléphonique en vous inscrivant sur le site internet <a className='a_corps_SW' href='www.bloctel.gouv.fr'>www.bloctel.gouv.fr</a>.</div>

        <div className='titre'>L’exercice de vos droits chez DIAGORISK</div>
        <div className='paragraphe' >Vous pouvez exercer ces droits, sur simple demande de votre part, auprès de DIAGORISK à remi.piguet@diagorisk.com ou en écrivant à DIAGORISK – A l’attention du DPO – 25 rue de Pétrelle 75009 Paris. N’oubliez pas d’accompagner votre demande d’un justificatif d’identité (copie de votre Carte Nationale d’Identité ou de votre passeport).</div>
        <div className='paragraphe' >DIAGORISK s’engage à vous répondre dans un délai d’un mois à compter de la réception de votre demande, sachant que ce délai peut être prorogé de deux mois en cas de demandes trop nombreuses ou complexes (article 12 RGPD).</div>
        <div className='paragraphe' >Lorsque vous effectuez une demande, les informations peuvent également vous être données oralement à condition que votre identité soit démontrée par d’autres moyens.</div>
        <div className='paragraphe' > <b>ATTENTION :</b> Comme évoqué dans le paragraphe « Votre consentement », l’exercice de ces droits est susceptible de rendre impossible l’exécution du service ou du contrat, dès lors que ces données et ces traitements participent du consentement des parties à la contractualisation.</div>

        <div className='titre'>Les données que nous collectons</div>
        <div className='paragraphe' >Nous collectons uniquement les données que vous nous confiez au travers de nos échanges (téléphone, rendez-vous chez votre conseiller, depuis notre site), et nous sommes susceptibles de les croiser avec celles d’organismes chargés du respect de la réglementation (par exemple les fichiers relatifs à la lutte contre la fraude).</div>
        <div className='paragraphe' >Ces données sont de plusieurs types :</div>
        <ul>
          <li> <div className='paragraphe lila'>Données relatives à votre identité et vos coordonnées de contact.</div> </li>
          <li> <div className='paragraphe lila'>Données relatives à vos prêts à couvrir.</div> </li>
          <li> <div className='paragraphe lila'>Données relatives à vos risques (profession, conditions d’exercice, caractère fumeur ou non).</div> </li>
          <li> <div className='paragraphe lila'>Données de paiement de vos cotisations.</div> </li>
          <li> <div className='paragraphe lila'>Données particulières ou sensibles, notamment vos données de santé nécessaires à la souscription du risque ou à l’instruction d’un sinistre, mais également des données liées à vos habitudes de vie si elles nécessitent une couverture spécifique (activités sportives, déplacements en pays à risque).</div> </li>
        </ul>

        <div className='titre'>La sécurité de vos données</div>
        <div className='paragraphe' >DIAGORISK est très attaché à la sécurisation de vos données et met tout en œuvre pour en éviter la perte, l’utilisation abusive, la falsification et la destruction et ce, dès la conception des traitements.</div>
        <ul>
          <li> <div className='paragraphe lila'>Toutes les transactions sur notre site sont cryptées (technologie SSL).</div> </li>
          <li> <div className='paragraphe lila'>L'accès à votre espace personnel est sécurisé, ce qui garantit la confidentialité des informations communiquées pour réaliser certains actes en ligne.</div> </li>
          <li> <div className='paragraphe lila'>Les données échangées via le site sont sécurisées par des enregistrements séparés des différents types d'information dans des bases.</div> </li>
        </ul>

        <div className='titre'>La durée de conservation de vos données</div>
        <div className='paragraphe' >Nous ne conservons vos données que pendant le temps nécessaire aux opérations pour lesquelles elles ont été collectées, dans le respect de la réglementation en vigueur. Cette conservation nous permet, en outre, d’établir la preuve d’une signature, d’un droit ou d’un contrat.</div>
        <div className='paragraphe' >Si vous souscrivez un contrat, nous conserverons les données concernant votre contrat d’assurance durant le temps nécessaire à son exécution, puis, pendant les délais légaux de prescription.</div>
        <div className='paragraphe' >Au-delà de ces délais, vos données seront détruites ou archivées.</div>

        <div className='titre'>L’utilisation de cookies</div>
        <div className='paragraphe' >Notre utilisation de cookies et le recueil de votre consentement sont traités dans les mentions légales.</div>

        <div className='titre'>Des questions sur le traitement de vos données personnelles ?</div>
        <div className='paragraphe' >Aucun problème ! En toute transparence, nous restons à votre disposition pour y répondre :</div>
        <ul>
          <li> <div className='paragraphe lila'>Par email : <a className='a_corps_SW' href='mailto:remi.piguet@gmail.com'>remi.piguet@gmail.com</a> </div> </li>
          <li> <div className='paragraphe lila'>Par courrier : DIAGORISK– A l’attention du DPO – 25 rue de Pétrelle 75009 Paris.</div> </li>
        </ul>
      </div>
      <FooterSW/>
    </div>
  );
}

export default ProtectionsDesDonnes;