import React from 'react'
import rond from '../../assets/logo-rond.png'

function  EnteteSW ({titre}) {
    return (
        <div className='entete enteteSW row'>
            <div className='RAS' style={{color:'transparent'}}>-------------</div>
            <h1>{titre}</h1>
            <img src={rond} className="LogoRond" alt='logo'/>

        </div>
    )
    
  }
  
  
  export default EnteteSW;