import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphSolEsquive({data, libelleData}) { 
    const getOptions = () => {
      let options = {
        xAxis: {
            type: 'category',
            data: [2020, 2025, 2030, 2035, 2040, 2045, 2050],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 14, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          yAxis: {
            type: 'value',
            name: 'Pertes en %',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          legend: {
            data: libelleData,
            // Position de la légende
            top: 'bottom', // Vous pouvez utiliser 'top', 'bottom', 'left', 'right'
            // Ajustements de la légende
            textStyle: {
              fontSize: 14 // Ajustez la taille de la police de la légende selon vos besoins
            }
          },
          series: [
            {
              name: libelleData[0],
              data: data[0],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#32CAFE',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#32CAFE',
                color: 'white',
              },
              
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#32CAFE',
                fontSize: 14,
                formatter: function (params) {
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[1],
              data: data[1],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#CD5C5C',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#CD5C5C',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#CD5C5C',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[2],
              data: data[2],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: 'blue',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: 'blue',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'blue',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[3],
              data: data[3],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#64E02E',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#64E02E',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#64E02E',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[4],
              data: data[4],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#E9967A',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#E9967A',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#E9967A',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[5],
              data: data[5],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: 'green',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: 'green',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'green',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[6],
              data: data[6],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#8E44AD',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#8E44AD',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#8E44AD',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[7],
              data: data[7],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: 'gray',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: 'gray',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'gray',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[8],
              data: data[8],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: 'black',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: 'black',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'black',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[9],
              data: data[9],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#2ECC71',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#2ECC71',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#2ECC71',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[10],
              data: data[10],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#2C82FF',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#2C82FF',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#2C82FF',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[11],
              data: data[11],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#C0392B',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#C0392B',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#C0392B',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[12],
              data: data[12],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#EC407A',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#EC407A',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#EC407A',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[13],
              data: data[13],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#5C6BC0' ,
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#5C6BC0 ',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#5C6BC0 ',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[14],
              data: data[14],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#FDD835',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#FDD835',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#FDD835',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            },
            {
              name: libelleData[15],
              data: data[15],
              type: 'line',
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              symbol: 'circle',
              symbolSize: 15,
              lineStyle: {
                width: 2, // Ajustez la largeur de la ligne selon vos besoins
                color: '#330099',
              },
              itemStyle: {
                borderType: 'solid',
                borderWidth: 2, // Ajustez l'épaisseur de la bordure selon vos besoins
                borderColor: '#330099',
                color: 'white',
              },
              label: {
                show: true,
                position: 'right', // Positionnez la légende à droite du dernier point
                color:'#330099',
                fontSize: 14,
                formatter: function (params) {
                  
                  // Vérifier si l'index correspond au dernier point de la série
                  if (params.dataIndex === 6){
                    return params.seriesName;
                  } else {
                    return ''; // Ne rien afficher pour les autres points
                  }
                }
              },
            }
          ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom:"20px", width: '90%', height: '500px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphSolEsquive;
  