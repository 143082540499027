import React, {useState} from 'react';
import {Navigate} from 'react-router-dom'

import logo from '../../assets/DiagoRisk-logoRVB.png'
import mans from '../../assets/logo-universite-du-mans.png'
import perqo from '../../assets/logo-du-perqo.jpg'
import idf from '../../assets/Logo-region-ile-de-france.png'
import inrae from '../../assets/logo-inrae.png'
import TI from '../../assets/terreInnovia_logo.png'
import ARTB from '../../assets/artb.png'
import ifv from '../../assets/LOGO-IFV-blanc.png'
import sunagri from '../../assets/Logo-Sun-agri-2021-LD.png'
import ecophyto from '../../assets/Logo-ecophyto.png'
import ofb from '../../assets/logo-ofb-actualite.jpg'
import expl from '../../assets/chemin-agricole.png'
import champs from '../../assets/champs.png'
import img_ente from '../../assets/Vigne-grandes-culture-accueil2.png'
import RA from '../../assets/cartes-regions-agricoles.png'

import Nav from '../MEP/SWNav'
import FooterSW from '../MEP/footer_SW';


function Accueil() {

  const [goToLocalisation, setGoToLocalisation] = useState(false)
  const [goToProjets, setgoToProjets] = useState(false)
  const [goToContact, setGoToContact] = useState(false)
  const [goToDiag, setgoToDiag] = useState(false)


  function handleContacter(event){
    event.preventDefault()
    setGoToContact(true)
  }
  function handleSubmitLoca(event) {
    event.preventDefault()
    setGoToLocalisation(true)
  }
  function handleClickProjets(event) {
    event.preventDefault()
    setgoToProjets(true)
}
function handleClickDC(event) {
  event.preventDefault()
  setgoToDiag(true)
}

  if(goToLocalisation) {
      return <Navigate push to={`/ma_localisation`} />
  }
  if(goToProjets) {
    return <Navigate push to={`/projets`} />
  }
  if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
  }
  if(goToDiag) {
    return <Navigate push to={`/diagnostics_climatiques`} />
  }

  return (
    <div>
      <Nav/>
      <div className="accueil" >
          
          <div className='PremierBlocAccueil'>
            <img src={img_ente} alt="Image décorative" className="imageEnteteAccueil"/>
            <div style={{width:'40%', display:'flex', flexDirection:'column',justifyContent:'center', alignItems:"center"}}>
              <img src={logo} alt="Logo DiagoRisk"  style={{width:"55%"}}/>
              <h1 className="propositionValeur" style={{marginTop:'15%'}}> Dérisquer les transitions agricoles  </h1>
              <h1 className="propositionValeur" style={{marginTop:'0px'}}> pour les accélérer </h1>
            </div>
          </div>
      </div>

      <div className='column'>
        <div className='peintureDR'>
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>DiagoRisk chiffre les impacts des transitions climatiques et agroécologiques,</div>
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>vous aide à identifier les solutions les plus efficaces  </div>
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>aujourd’hui et 2035 – 2050 </div>  
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>sur votre exploitation et votre territoire.</div>
          <div className='paragrapheAccueil' style={{ marginTop:'3%'}}>Un code postal et votre assolement/encépagement suffisent pour avoir des 1ers résultats.</div>
        </div>

        <div className='cardAccueil' style={{marginTop:'5px'}}>
          <div className='cardAccueil2' >
            <img src={expl} alt="chemin agricole" className="imgcardaccueil2"/>
            <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center', marginTop:'1%'}}> Nouvelles Pratiques, </div>
            <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center'}}> Impacts des bioagresseurs </div>
            <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', marginBottom:'1%', textAlign:'center'}}>Dérisquons l’avenir ensemble</div>
            <div className='paragraphe paragrapheLanding imgcardaccueil2' style={{marginBottom:'1%'}}>Colza Zéro Insecticide, Vigne à Moindres traitements, Plan Protéines, Betteraves sans néonicotinoïde, …</div>
            <div className='paragraphe paragrapheLanding imgcardaccueil2'>Nous aidons à mieux évaluer les risques pour définir des stratégies plus performantes.</div>
            <button className='validation validationAccueil' onClick={handleClickProjets}>En savoir plus</button>
          </div>
          <div className='cardAccueil2' >
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
              <img src={champs} alt="champs de cultures céréalières" className="imgcardaccueil2"/>
              <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center', marginTop:'1%'}}>Réalisez vos diagnostics Climat facilement </div>
              <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center', marginBottom:'1%'}}>grâce à DiagoRisk</div>
              <div className='paragraphe paragrapheLanding imgcardaccueil2'>Les projets déposés avant le 11 septembre 2024 peuvent être subventionnés à 80% par l’ADEME</div>
            </div>
            
            <button className='validation validationAccueil' onClick={handleContacter}>Contactez-nous</button>
          </div>
        </div>

        <div style={{width:'90%', backgroundColor:'#E5FFC9', padding:'5%', textAlign:'center', marginTop:'20px'}}>
          <div className='paragrapheAccueil' style={{fontWeight:'bold', textAlign:'center'}}> DiagoRisk pour qui, pour quoi ?</div>
          <div className='paragraphe paragrapheLanding ' style={{textAlign:'center', marginTop:'15px'}}>DiagoRisk est un OAD (Outil d’Aide à la Décision) créé pour aider les acteurs agri/agro à sécuriser leur stratégie en optimisant les solutions techniques, agronomiques ou financières. Le diagnostic et la stratégie collective peuvent être déclinés à l’échelle de chaque exploitation.</div>
          <button className='validation validationAccueil' onClick={handleSubmitLoca}>Découvrir DiagoRisk</button>
        </div>

        

        <div className='cardAccueil3' style={{marginTop:'2%', marginBottom:'2%'}}>
          <img src={RA} alt="Carte des région agricole" className="imageAccueil"/>
          <div className='textCardAccueil textCardAccueil2' style={{marginLeft:'5%'}}>
            <div className='paragraphe paragrapheLanding' style={{marginBottom:'2%'}}> Notre ambition est de dérisquer les nouvelles pratiques agricoles pour accélérer leur adoption à grande échelle. </div>
            <div className='paragraphe paragrapheLanding'> Cette solution s’appuie sur une modélisation puissante des risques rendement, des projections du GIEC et des bioagresseurs, développée par une équipe de spécialistes du risque agricole, du changement climatique et de l’assurance, en partenariat avec l’Institut du Risque et de l’Assurance de l’Université du Mans. </div>
            <button className='validation validationAccueil2' onClick={handleClickDC} style={{width:'30%'}}>En savoir plus</button>
          </div>
        </div>


        <div className='partenaireDemicercle'>
          <div className='paragrapheAccueil' style={{fontWeight:'bold', marginTop:'1%',marginBottom:'2%', textAlign:'center'}}>
            Ils nous font confiance
          </div>
          <div className='row'>
            <img src={perqo} alt="Logo du PERQO" className="imgPartenaire"/>
            <img src={idf} alt="Logo région Ile-de-France" className="imgPartenaire"/>
            <img src={mans} alt="Logo de l'université du Mans" className="imgPartenaire"/>
            <img src={sunagri} alt="Logo de SunAgri" className="imgPartenaire"/>
          </div>
          <div className='row'>
            <img src={ARTB} alt="Logo de l'ARTB" className="imgPartenaire"/>
            <img src={ifv} alt="Logo de l'IFV" className="imgPartenaire"/>
            <img src={inrae} alt="Logo de l'INRAE" className="imgPartenaire"/>
            <img src={TI} alt="Logo de TerreInnovia" className="imgPartenaire"/>
          </div>
          <div className='row'>
            <img src={ecophyto} alt="Logo EcopPhyto" className="imgPartenaire"/>
            <img src={ofb} alt="Logo de l'OFB" className="imgPartenaire"/>
          </div>
        </div>

      </div>
      <FooterSW/>
    </div>
  );
}

export default Accueil;

